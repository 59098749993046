import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Row, Container } from 'reactstrap'
import { getDoctorProfile, handleDoctorFetchError } from './DoctorProfileMethods';
import DetailsTile from './ProfileTiles/DetailsTile';
import constants from '../../../../../Utils/Constants/Constants';
import DoctorProfileTile from './ProfileTiles/DoctorProfileTile';
import { useNavigate } from 'react-router-dom';

const DoctorProfile = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { data: doctorProfile, isLoading: doctorProfileLoading, refetch }: any = useQuery(`doctorProfile${id}`, () => getDoctorProfile(id),
        {
            keepPreviousData: true,
            onError: (error: any) => handleDoctorFetchError(error, navigate)
        }
    )

    return (
        <>
            <section className="doc-profile">
                <div className="doc-profile-outer-wrap">
                    <Container>
                        <DoctorProfileTile
                            doctorProfile={doctorProfile}
                            doctorProfileLoading={doctorProfileLoading}
                            refetch={refetch}
                        />
                    </Container>
                </div>
            </section>

            <section className="doctor-details-more">
                <div className="doctor-detail-cards-wrap">
                    <Row className='gy-4'>
                        <DetailsTile
                            data={doctorProfile?.educations}
                            type={constants?.DOCTOR_PROFILE_TILE_TYPES?.EDUCATION}
                            title='Education'
                            isLoading={doctorProfileLoading}
                            doctor_id={id}
                            refetch={refetch}
                        />
                        <DetailsTile
                            data={doctorProfile?.skills}
                            type={constants?.DOCTOR_PROFILE_TILE_TYPES?.SKILL}
                            title='Skill'
                            isLoading={doctorProfileLoading}
                            doctor_id={id}
                            refetch={refetch}
                        />
                        <DetailsTile
                            data={doctorProfile?.experiences}
                            type={constants?.DOCTOR_PROFILE_TILE_TYPES?.EXPERIENCE}
                            title='Experience'
                            isLoading={doctorProfileLoading}
                            doctor_id={id}
                            refetch={refetch}
                        />
                    </Row>
                </div>
            </section>
        </>
    )
}

export default DoctorProfile