// eslint-disable-next-line no-sparse-arrays
const gender_data: any = [
  {
    value: "male",
    label: "Male",
  },
  ,
  {
    value: "female",
    label: "Female",
  },
  {
    value: "other",
    label: "Other",
  },
];
const medicine_data: any = [
  { value: "Acetaminophen", label: "Acetaminophen" },
  { value: "Naproxen", label: "Naproxen" },
  { value: "Ibuprofen", label: "Ibuprofen" },
];
const dosage_data: any = [
  { value: "5 ml", label: "5 ml" },
  { value: "6 ml", label: "6 ml" },
  { value: "1", label: "1" },
];
const duration_data: any = [
  { value: "Mng", label: "Mng" },
  { value: "Evg", label: "Evg" },
  { value: "Night", label: "Night" },
];

const WEEK_DAYS = [
  {
    value: 1,
    label: "Monday"
  },
  {
    value: 2,
    label: "Tuesday"
  },
  {
    value: 3,
    label: "Wednesday"
  },
  {
    value: 4,
    label: "Thursday"
  },
  {
    value: 5,
    label: "Friday"
  },
  {
    value: 6,
    label: "Saturday"
  },
  {
    value: 7,
    label: "Sunday"
  }
];

const MORNING_SLOTS = [
  { time: '09:00', label: '09:00 AM' },
  { time: '09:05', label: '09:05 AM' },
  { time: '09:10', label: '09:10 AM' },
  { time: '09:15', label: '09:15 AM' },
  { time: '09:20', label: '09:20 AM' },
  { time: '09:25', label: '09:25 AM' },
  { time: '09:30', label: '09:30 AM' },
  { time: '09:35', label: '09:35 AM' },
  { time: '09:40', label: '09:40 AM' },
  { time: '09:45', label: '09:45 AM' },
  { time: '09:50', label: '09:50 AM' },
  { time: '09:55', label: '09:55 AM' },
  { time: '10:00', label: '10:00 AM' },
  { time: '10:05', label: '10:05 AM' },
  { time: '10:10', label: '10:10 AM' },
  { time: '10:15', label: '10:15 AM' },
  { time: '10:20', label: '10:20 AM' },
  { time: '10:25', label: '10:25 AM' },
  { time: '10:30', label: '10:30 AM' },
  { time: '10:35', label: '10:35 AM' },
  { time: '10:40', label: '10:40 AM' },
  { time: '10:45', label: '10:45 AM' },
  { time: '10:50', label: '10:50 AM' },
  { time: '10:55', label: '10:55 AM' },
  { time: '11:00', label: '11:00 AM' },
  { time: '11:05', label: '11:05 AM' },
  { time: '11:10', label: '11:10 AM' },
  { time: '11:15', label: '11:15 AM' },
  { time: '11:20', label: '11:20 AM' },
  { time: '11:25', label: '11:25 AM' },
  { time: '11:30', label: '11:30 AM' },
  { time: '11:35', label: '11:35 AM' },
  { time: '11:40', label: '11:40 AM' },
  { time: '11:45', label: '11:45 AM' },
  { time: '11:50', label: '11:50 AM' },
  { time: '11:55', label: '11:55 AM' },
  { time: '12:00', label: '12:00 PM' },
  { time: '12:05', label: '12:05 PM' },
  { time: '12:10', label: '12:10 PM' },
  { time: '12:15', label: '12:15 PM' },
  { time: '12:20', label: '12:20 PM' },
  { time: '12:25', label: '12:25 PM' },
  { time: '12:30', label: '12:30 PM' },
  { time: '12:35', label: '12:35 PM' },
  { time: '12:40', label: '12:40 PM' },
  { time: '12:45', label: '12:45 PM' },
  { time: '12:50', label: '12:50 PM' },
  { time: '12:55', label: '12:55 PM' },
  { time: '13:00', label: '01:00 PM' },
  { time: '13:05', label: '01:05 PM' },
  { time: '13:10', label: '01:10 PM' },
  { time: '13:15', label: '01:15 PM' },
  { time: '13:20', label: '01:20 PM' },
  { time: '13:25', label: '01:25 PM' },
  { time: '13:30', label: '01:30 PM' },
  { time: '13:35', label: '01:35 PM' },
  { time: '13:40', label: '01:40 PM' },
  { time: '13:45', label: '01:45 PM' },
  { time: '13:50', label: '01:50 PM' },
  { time: '13:55', label: '01:55 PM' },
  { time: '14:00', label: '02:00 PM' },
  { time: '14:05', label: '02:05 PM' },
  { time: '14:10', label: '02:10 PM' },
  { time: '14:15', label: '02:15 PM' },
  { time: '14:20', label: '02:20 PM' },
  { time: '14:25', label: '02:25 PM' },
  { time: '14:30', label: '02:30 PM' },
  { time: '14:35', label: '02:35 PM' },
  { time: '14:40', label: '02:40 PM' },
  { time: '14:45', label: '02:45 PM' },
  { time: '14:50', label: '02:50 PM' },
  { time: '14:55', label: '02:55 PM' },
  { time: '15:00', label: '03:00 PM' },
  { time: '15:05', label: '03:05 PM' },
  { time: '15:10', label: '03:10 PM' },
  { time: '15:15', label: '03:15 PM' },
  { time: '15:20', label: '03:20 PM' },
  { time: '15:25', label: '03:25 PM' },
  { time: '15:30', label: '03:30 PM' },
  { time: '15:35', label: '03:35 PM' },
  { time: '15:40', label: '03:40 PM' },
  { time: '15:45', label: '03:45 PM' },
  { time: '15:50', label: '03:50 PM' },
  { time: '15:55', label: '03:55 PM' },
  { time: '16:00', label: '04:00 PM' },
  { time: '16:05', label: '04:05 PM' },
  { time: '16:10', label: '04:10 PM' },
  { time: '16:15', label: '04:15 PM' },
  { time: '16:20', label: '04:20 PM' },
  { time: '16:25', label: '04:25 PM' },
  { time: '16:30', label: '04:30 PM' },
  { time: '16:35', label: '04:35 PM' },
  { time: '16:40', label: '04:40 PM' },
  { time: '16:45', label: '04:45 PM' },
  { time: '16:50', label: '04:50 PM' },
  { time: '16:55', label: '04:55 PM' },
  { time: '17:00', label: '05:00 PM' },
  { time: '17:05', label: '05:05 PM' },
  { time: '17:10', label: '05:10 PM' },
  { time: '17:15', label: '05:15 PM' },
  { time: '17:20', label: '05:20 PM' },
  { time: '17:25', label: '05:25 PM' },
  { time: '17:30', label: '05:30 PM' },
  { time: '17:35', label: '05:35 PM' },
  { time: '17:40', label: '05:40 PM' },
  { time: '17:45', label: '05:45 PM' },
  { time: '17:50', label: '05:50 PM' },
  { time: '17:55', label: '05:55 PM' },
  { time: '18:00', label: '06:00 PM' }
]



export { gender_data, medicine_data, dosage_data, duration_data, WEEK_DAYS, MORNING_SLOTS };
