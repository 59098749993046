import { toast } from "react-toastify";
import { api } from "../../../../../../Api/Api";
import axios from "axios";
import { BaseUrl } from "../../../../../../Api/BaseUrl";
import moment from "moment";

let doctor_note_id = 0;

export const submitClickHandler = (
  values: any,
  actions: any,
  data: any,
  isEditMode: boolean,
  noteReftech: any,
  id: any
) => {
  const endpoint = isEditMode || id ? `/notes/${doctor_note_id}` : `/notes`;

  const method = isEditMode ? "put" : "post"; // Use PUT in edit mode, POST otherwise
  // Create a requestData object with default values
  let requestData = {
    patient_id: parseInt(data?.patientID),
    notes: values?.note,
    appointment_id: parseInt(data?.appoinemnetID),
  };

  api[method](endpoint, requestData, true)
    .then(async function ([success, response]: any) {
      if (success) {
        doctor_note_id = response.doctor_note_id; // Store the note message
        toast.success(isEditMode ? "Note updated" : "Note added");
        noteReftech();
      } else {
        toast.error("Failed to update/add note");
      }
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const submitPrescriptionClickHandler = (
  values: any,
  actions: any,
  appoinemnetID: any
) => {
  api
    .post(
      `/prescription`,

      {
        appointments_id: parseInt(appoinemnetID?.appoinemnetID),
        prescriptions: values?.prescription,
      },

      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Prescription added");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const submitDocumentClickHandler = async (
  values: any,
  actions: any,
  patient_id: any
) => {
  try {
    const formData = new FormData();
    formData.append("file_path", values?.document);
    formData.append("file_name", values?.file_name);
    formData.append("patient_id", patient_id);
    const res = await axios.post(`${BaseUrl}/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
    });
    if (res) {
      toast.success("Document uploaded");
    }
  } catch (error: any) {
    actions?.setSubmitting(false);

    if (error?.response?.data?.message) {
      actions?.setFieldError("document", error?.response?.data?.message);
    } else {
      toast.error("Unexpected Error Occurred");
    }
  }
};

export const submitEventDropClickHandler = (values: any, refetch: any) => {
  api
    .put(
      `/appointments/${values?.appointment_id}`,
      { date_time: moment(values?.start).format("YYYY-MM-DD HH:MM") },
      true
    )
    .then(async function ([success, response]: any) {
      refetch();
    })
    .catch((err) => {
      refetch();
      if (err?.response?.data?.message)
        toast.error(err?.response?.data?.message, { toastId: "previous_date" });
    });
};
