import {
  capitalizeFirst,
  convertISODateToDateTime,
} from "../../../../../Utils/helpers/helpers";

export const paymentsData = [
  {
    name: "Archana",
    age: 23,
    gender: "Female",
    paid: "320.0",
    payment_method: "Cash",
  },
  {
    name: "anjali",
    age: 23,
    gender: "Female",
    paid: "320.0",
    payment_method: "Cash",
  },
  {
    name: "Archana",
    age: 23,
    gender: "Female",
    paid: "320.0",
    payment_method: "Cash",
  },
  {
    name: "anjali",
    age: 23,
    gender: "Female",
    paid: "320.0",
    payment_method: "Cash",
  },
  {
    name: "Archana",
    age: 23,
    gender: "Female",
    paid: "320.0",
    payment_method: "Cash",
  },
  {
    name: "anjali",
    age: 23,
    gender: "Female",
    paid: "320.0",
    payment_method: "Cash",
  },
];

export const doctorsData = [
  {
    img: "/images/doctor1.png",
    name: "Archana",
    department: "Ophthalmology",
  },
  {
    img: "/images/doctor1.png",
    name: "anjali",
    department: "Ophthalmology",
  },
  {
    img: "/images/doctor1.png",
    name: "Archana",
    department: "Ophthalmology",
  },
  {
    img: "/images/doctor1.png",
    name: "anjali",
    department: "Ophthalmology",
  },
  {
    img: "/images/doctor1.png",
    name: "Archana",
    department: "Ophthalmology",
  },
];

export const Appoinments = [
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
];

export const surgeries = [
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
];

export const Appoinments_Staff = (data: any) => {

  let convertdarray: any = [];
  data?.map((item: any) => {
    convertdarray?.push({
      date: convertISODateToDateTime(item?.date_time),
      name: capitalizeFirst(item?.patient_name),
      mobile: item?.patient_mobile,
      gender:capitalizeFirst(item?.patient_gender)
      ,
    });
  });
  
  return convertdarray;
};

export const staffupcomingappointmentsTableHeaders: string[] = [
  "Date & Time",
  "Name",
  "Mobile",
  "Gender"
];