import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import { PatientProfileAppoinmentDetails } from "../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { convertISODateToDateTime } from "../../../../Utils/helpers/helpers";

export default function ConsultaionTimeLine({ patientID }:any) {
  //Api for Appoinment and patient Data
  const { data: patientlist }: any = useQuery(
    [`PatientProfileAppoinment${patientID}`, patientID],
    () => PatientProfileAppoinmentDetails(patientID),
    {
      keepPreviousData: true,
    }
  );
  
  return (
    <>
      {" "}
      <Col lg={3} md={3} sm={12}>
        <Card className="widget-card">
          <CardBody>
            <div className="widget-head-outer">
              <div className="widget-heading">
                <small>Consultation</small>
                <h6>Timeline</h6>
              </div>
              <div className="icon-container">
                <a href="#">
                  <i className="icon xl icon-options"></i>
                </a>
              </div>
            </div>

            <Row className="gy-4">
              {patientlist?.data?.map((item: any, index: any) => (
                <Col lg={12}>
                  <Card className="timeline-card active">
                    <span>
                      <CardBody className="timeline-flex-wrap">
                        <div className="timeline-card-details">
                          <small>
                            {convertISODateToDateTime(
                              item?.appointment_date_time
                            )}
                          </small>
                          <small>{item?.doctor_name}</small>
                        </div>
                        <div className="see-more-btn">
                          <i className="icon xl icon-next-arrow"></i>
                        </div>
                      </CardBody>
                    </span>
                  </Card>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
