import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import CustomTable from "../../Lists/CustomTable";
import { CardsWithTableType } from "../../../Type";
import CustomSpinner from "../../CustomElements/Spinner/SpinnerCustom";
import CustomPagination from "../../Pagination/Pagination";

const CardsWithTable = ({
  data,
  paginationData,
  setCurrentPage,
  viewType,
  toURL,
  tableTitle,
  tableFields,
  actions,
  Arraykeys,
  isLoading,
  error,
  onEditClick,
  onDeleteClick,
  meta,
  Refetch,
  ActionsLabel
}: CardsWithTableType) => {
  return (
    <>
      <Container>
        <Row className="gy-4">
          {viewType === "card" ? (
            <>
              {/* cards  */}
              {isLoading ? (
                <div>
                  <CustomSpinner />
                </div>
              ) : error ? (
                <div>Error Occurred</div>
              ) : data?.length === 0 ? (
                <div>No Data Available</div>
              ) : (
                data?.map((itm: any, i: number) => {
                  return (
                    <Col sm={12} md={3} lg={3} key={i}>
                      <Link to={`${toURL}/${1}`}>
                        <Card className="specialists-card">
                          <a href="/doctor-profile">
                            <div className="thumbnail-wrap">
                              <img
                                src={itm?.image}
                                className="thumbnail-md-cover"
                                alt="doctor"
                              />
                            </div>
                            <CardBody>
                              <CardTitle className="name-wrap">
                                <h6 className="name">Dr. {itm?.name} </h6>
                              </CardTitle>
                            </CardBody>
                          </a>
                        </Card>
                      </Link>
                    </Col>
                  );
                })
              )}
            </>
          ) : (
            <div className="table-outer-wrap">
              <div className="table-head-outer">
                <div className="table-heading">
                  <h6>{tableTitle || ""}</h6>
                </div>
                {/* <div className="icon-container">
                  <a href="#">
                    <i className="icon icon-options xl"></i>
                  </a>
                </div> */}
              </div>
              <CustomTable
                data={data || []}
                tableFields={tableFields}
                Arraykeys={Arraykeys}
                actions={actions}
                isLoading={isLoading}
                error={error}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                url={toURL}
                meta={meta}
                Refetch={Refetch}
                ActionsLabel={ActionsLabel}
              />
            </div>
          )}
        </Row>
      </Container>
      {paginationData?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={paginationData?.total}
            itemsPerPage={paginationData?.per_page}
            activePage={paginationData?.current_page}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
};

export default CardsWithTable;
