import { ErrorMessage, Formik } from 'formik';
import {
    Row,
    Col,
    Form,
    Input
} from 'reactstrap';
import TextError from '../../../Components/FormikError/TextError';
import CustomButton from '../../../Components/CustomElements/FormElements/CustomButton';
import { ForgotPasswordEmailValidaionSchema, handleEmailSubmit } from './ForgotPasswordMethods';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPasswordIndex = () => {
    const navigate = useNavigate()

    return (
        <div className="login-wrapper">
            <div className="login-outer-wrap">
                <div className="login-inner-wrap">
                    <Row className='login-inner-row'>
                        <Col lg={4} md={6} className='login-form-outer-wrap'>
                            <Link to="/">
                                <div className="logo-wrap">
                                    <img src="images/logo-blue.svg" alt="logo" />
                                </div>
                            </Link>
                            <div className="login-form-wrap">
                                <div className="heading">
                                    <h1>Reset Your Password</h1>
                                    <p>Please Enter Email for Password Reset Code.</p>
                                </div>
                                <Formik
                                    validationSchema={ForgotPasswordEmailValidaionSchema}
                                    initialValues={{ email: '' }}
                                    onSubmit={(values: any, actions: any) => {
                                        handleEmailSubmit(values, actions, navigate)
                                    }}
                                >
                                    {({
                                        values,
                                        isSubmitting,
                                        handleChange,
                                        handleSubmit,

                                    }: any) => {
                                        return (
                                            <Form onSubmit={handleSubmit}>
                                                <div className="form-inner-wrap">
                                                    <Row className='gy-4'>
                                                        <Col lg={12}>
                                                            <Input type="text" name='email' value={values?.email} onChange={handleChange} placeholder="Email" />
                                                            <ErrorMessage name="email" component={TextError} />
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="submit-btn-wrap end">
                                                                <CustomButton color={'secondary'} className="sm" label='Continue' isLoading={isSubmitting} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </Col>
                        <Col lg={8} md={6} className='login-banner-column'>
                            <div className="login-banner-wrap">
                                <img src="images/reset-bg.webp" alt="img" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    )
}

export default ForgotPasswordIndex