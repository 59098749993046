import * as Yup from "yup";

export const ServicesValidationSchema = Yup.object().shape({
  image: Yup.string().required("Service file is required"),

  description: Yup.string()
    .required("Description is required")
    .max(250, "The Description must not exceed 100 characters."),
  title: Yup.string()
    .required("Title is required")
    .max(100, "The Title must not exceed 100 characters."),
  color: Yup.string().required("Color is required"),
});

export const AddServicesInitialValues = {
  image: "",
  description: "",
  title: "",
  color: "",
};
