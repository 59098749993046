import { useState, useEffect } from 'react';

const useHamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem('menuOpen') === 'true');

    const toggleMenu = () => {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);
        localStorage.setItem('menuOpen', newIsOpen?'true':'false');
    };

    useEffect(() => {
        const storedMenuState = localStorage.getItem('menuOpen');
        if (storedMenuState) {
            setIsOpen(storedMenuState === 'true');
        }
    }, []);

    return { isOpen, toggleMenu };
};

export default useHamburgerMenu;
