import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import { PatientBasicDataProps } from "../../../../Type";
import {
  calculate_age,
  capitalizeFirst,
} from "../../../../Utils/helpers/helpers";
import constants from "../../../../Utils/Constants/Constants";

export default function PatientBasicData({ data }: PatientBasicDataProps) {
  return (
    <>
      <Col xxl={12}>
        <Card className="widget-card patient-details-full-wrap">
          <CardBody>
            <div className="patient-details-wrap">
              <div className="thumb-group">
                <div className="patient-image">
                  <img
                    src={constants.DEFAULT_IMAGE.IMAGE}
                    className="thumbnail-sm-contain"
                    alt="img"
                  />
                </div>
                <div className="patient-name">
                  <h6>{data?.name}</h6>
                </div>
              </div>
              <div className="patient-details-inner-wrap">
                <Row className="gy-4">
                  <Col lg={6}>
                    <div className="item">
                      <small className="head">Patient ID</small>
                      <small className="details">#{data?.id}</small>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="item">
                      <small className="head">Age</small>
                      <small className="details">
                        {calculate_age(data?.dob)}
                      </small>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="item">
                      <small className="head">Gender</small>
                      <small className="details">
                        {capitalizeFirst(data?.gender)}
                      </small>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="item">
                      <small className="head">Mobile</small>
                      <small className="details">{data?.mobile}</small>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="item">
                      <small className="head">Address</small>
                      <small className="details">{data?.address}</small>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
