import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Table, Form } from 'reactstrap'
import { TimeSlotType } from '../../../../../Type'
import { WEEK_DAYS } from '../../../../../Utils/Constants/selection_options'
import { getTimeSlotList, handleSlotDelete } from './AddDoctorStepsMethods'
import TimeSlotForm from './TimeSlotForm'

const TimeSlot: React.FC<TimeSlotType> = ({ addedDocId, refetch, setIsOpen }) => {

    const [isAddOpen, setIsAddOpen] = useState(false)
    const { data: timeSlot, refetch: timeSlotRefetch }: any = useQuery(['timeSlot', addedDocId], () => getTimeSlotList(addedDocId), {
        enabled: addedDocId !== undefined
    })

    return (
        <div>
            <div>
            <div className="time-slot-wrap">
            <Form className="form-wrap">
                <div className="form-wrap-scroll">
                    <div className="day-time-wrap">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Time</th>
                                    <th></th>
                                    <th className="add-date-time-slot"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    timeSlot && timeSlot?.data?.map((ts: any, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td> <div className="input-wrap">{WEEK_DAYS?.filter((it: any) => it?.value === ts?.week_day)?.[0]?.label}</div></td>
                                                <td> <div className="input-wrap">{ts?.time_start} </div>   </td>
                                                <td> <div className="input-wrap">{ts?.time_end}</div></td>
                                                <td onClick={() => handleSlotDelete(ts?.id, timeSlotRefetch)}><i className='icon icon-delete-red xl'></i></td>
                                            </tr>
                                        )
                                    })
                                }
                                {!isAddOpen && <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'>
                                        <span className="add-btn end">
                                            <Link to="" onClick={() => setIsAddOpen(true)}><i className="icon icon-plus-gray xl"></i></Link>
                                        </span>
                                    </td>
                                </tr>}
                            </tbody>
                        </Table>
                        {isAddOpen &&
                                <TimeSlotForm
                                    addedDocId={addedDocId}
                                    refetch={timeSlotRefetch}
                                    setIsOpen={setIsAddOpen}
                                />}
                    </div>
                </div>
                <Row className="gy-3">
                    <Col lg={12}>
                        <div className="submit-btn-wrap">
                            <Button onClick={() => setIsOpen(false)} className="sm submit-btn" >Finish</Button>
                        </div>
                    </Col>
                </Row>
                </Form>
                </div>
            </div>

           

        </div>
    )
}

export default TimeSlot
