import * as Yup from "yup";

export const AddNoteValidationSchema = Yup.object().shape({
  note: Yup.string().required("Note is required"),
});
export const AddNoteinitialValues = {
  note: "",
};
export const AddDocumentinitialValues = {
  document: "",
  file_name: "",
};
export const AddDocumentValidationSchema = Yup.object().shape({
  document: Yup.string().required("Document is required"),
  file_name: Yup.string().required("File name is required"),
});
export const AddPrescriptionvalidationSchema = Yup.object().shape({
  prescription: Yup.array().of(
    Yup.object().shape({
      medicine: Yup.string().required("Medicine is required"),
      dosage: Yup.string().required("Dosage is required"),
      duration: Yup.string().required("Duration is required"),
    })
  ),
});
