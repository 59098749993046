import { useEffect, useState } from 'react'
import CardContainer from '../../../../../Components/Containers/Dashboard/CardContainer'
import DashboardStatusCard from '../../../../../Components/CustomElements/Cards/DashboardStatuscard'
import { handleDashboardDataInitializer } from './CardMethods'

const StatusCards = ({ dashboardData }: any) => {
    const [statistics, setStatistics] = useState<any>([])

    useEffect(() => {
        setStatistics(handleDashboardDataInitializer(dashboardData))
    }, [dashboardData])

    return (
        <CardContainer>
            {statistics?.map((el: any, i: number) =>
                <DashboardStatusCard
                    key={i}
                    title={el?.title}
                    count={el?.count}
                    unit={el?.unit}
                    icon={el?.icon}
                    className={el?.className}
                />
            )}
        </CardContainer>
    )
}

export default StatusCards