import { ErrorMessage, Formik } from 'formik'
import { Button, Form, Input, Label, Table } from 'reactstrap'
import { MORNING_SLOTS, WEEK_DAYS } from '../../../../../Utils/Constants/selection_options'
import TextError from '../../../../../Components/FormikError/TextError'
import { AddSlotsValidationSchema, addDoctorSlotHandler } from './AddDoctorStepsMethods'

const TimeSlotForm = ({ addedDocId, refetch, setIsOpen }: any) => {
    return (
        <div className="time-slot-wrap">
            <Formik
                validationSchema={AddSlotsValidationSchema}
                initialValues={{ doctor_id: addedDocId, week_day: '', time_start: '', time_end: '' }}
                enableReinitialize
                onSubmit={(values, actions: any) => {
                    addDoctorSlotHandler(values, actions, refetch, setIsOpen)
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    values
                }) => {

                    return (
                        <Form className="form-wrap" onSubmit={handleSubmit}>
                            <div className="form-wrap-scroll">
                                <div className="day-time-wrap">
                                    <Table>
                                        {/* <thead>
                                            <tr>
                                                <th>Day</th>
                                                <th>Time</th>
                                                <th></th>
                                                <th className="add-date-time-slot"> */}

                                                    {/* if added ,then put edit and delete icon here */}
                                                    {/* <div className="action-flex-wrap">
                                                <Link to=""><i className="icon icon-edit-blue xl"></i></Link>
                                                <Link to=""><i className="icon icon-delete-red xl"></i></Link>
                                            </div> */}
                                                {/* </th>
                                            </tr>
                                        </thead> */}
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="input-wrap">
                                                        {/* <Label>Day</Label> */}
                                                        <Input type="select" value={values?.week_day} name='week_day' onChange={handleChange}>
                                                            <option value="">Day</option>
                                                            {WEEK_DAYS?.map((day: any, i: number) => <option key={i} value={day?.value}>{day?.label}</option>)}
                                                        </Input>
                                                        <ErrorMessage name="week_day" component={TextError} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-wrap">
                                                        {/* first one */}
                                                        <div className="items-wrap">
                                                            {/* start and end  flex*/}
                                                            <div className="item">
                                                                {/* start */}
                                                                {/* <Label>Time start</Label> */}
                                                                <span>
                                                                <Input type="select" onChange={handleChange} name='time_start' value={values?.time_start} >
                                                                    <option value="">Start</option>
                                                                    {MORNING_SLOTS?.map((tm: any, i: number) => <option key={i} value={tm?.time}>{tm?.label}</option>)}
                                                                </Input>
                                                                <ErrorMessage name="time_start" component={TextError} />
                                                                </span>
                                                                {/* end */}
                                                                {/* <Label>Time end</Label> */}
                                                                <span>
                                                                <Input type="select" onChange={handleChange} name='time_end' value={values?.time_end}>
                                                                    <option value="" >End</option>
                                                                    {MORNING_SLOTS?.map((tm: any, i: number) => {
                                                                        return (tm?.time > values?.time_start ? <option key={i} value={tm?.time}>{tm?.label}</option> : null)
                                                                    })}
                                                                </Input>
                                                                <ErrorMessage name="time_end" component={TextError} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <i className="icon xl icon-close-red" onClick={() => setIsOpen(false)}></i>
                                                </td>
                                                <td>
                                                    <i className="icon xl icon-check-green" onClick={() => handleSubmit()}></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            <div className="add-more-outer">
                                <Button color="none"  className="sm add-more-button"><i className="icon icon-plus-gray xl"></i></Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default TimeSlotForm
