import { FormikHelpers } from "formik";
import { api } from "../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { toast } from "react-toastify";
import { AddDepartmentInitialValues } from "./FormikValidation";

type AddInitialValues = {
  name: string;
};

// Submit Add
export const AddDepartmentSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function
) => {
  api
    .post("/departments", values, true)
    .then(async function ([success, response]: any) {
      toast.success("Department Added ");
      setIsOpen(false);
      refetch();
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
  actions?.setSubmitting(false);
};
// Submit edit
export const EditDepartmentSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  id: string | number
) => {
  api
    .put(`/departments/${id}`, values, true)
    .then(async function ([success, response]: any) {
      toast.success("Department Details Updated ");
      setIsOpen(false);
      refetch();
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
        setValidationErrors(err?.response?.data?.errors, actions);
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
  actions?.setSubmitting(false);
};

// extract Departmentdata for table
export const extractDepartmentData = (setDoctData: Function, data: any) => {
  setDoctData(
    data?.data?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.name,
      };
    })
  );
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      name: modalEditData?.[0]?.name,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddDepartmentInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );
  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/departments/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};
