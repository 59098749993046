import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";
import { setValidationErrors } from "../../../../../Utils/helpers/helpers";

export const extractDoctorData = (data: any) => {
    let dataProcessedResponse = data?.map((obj: any) => {
        return {
            value: obj?.id,
            label: obj?.user?.name
        }
    });
    return dataProcessedResponse
}

export const AddAppointmentInitializer = (selectedDate: any) => {
    let selTime = ''
    let selDate = ''
    if (selectedDate) {
        selTime = DateFormatter(selectedDate)?.substring(11, 16)
        selDate = DateFormatter(selectedDate)?.substring(0, 10)
    }
    const x = {
        patient_name: "",
        patient_id: "",
        patient_mobile: "",
        patient_email: "",
        department_id: "",
        doctor_id: "",
        patient_gender: "",
        patient_address: "",
        booking_time: selTime || "",
        booking_date: selDate || "",
        payment_confirm: "",
        patient_dob: "",
    }
    return x
}

const DateFormatter = (inputDateString: string) => {
    const inputDate = new Date(inputDateString);
    const year = inputDate?.getFullYear();
    const month = String(inputDate?.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-based
    const day = String(inputDate?.getDate()).padStart(2, '0');
    const hours = String(inputDate?.getHours()).padStart(2, '0');
    const minutes = String(inputDate?.getMinutes()).padStart(2, '0');
    const seconds = String(inputDate?.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const appointmentSubmitHandler = (values: any, actions: any, setIsModalOpen: Function, refetch: Function) => {
    values = { ...values, date_time: values?.booking_date + " " + values?.booking_time, }
    delete values.booking_time;
    delete values.booking_date;

    api.post("/appointments/confirm", values, true)
        .then(async function ([success, response]: any) {
            toast?.success('Appointment created')
            refetch()
            setIsModalOpen(false)
        })
        .catch((err) => {
            actions?.setSubmitting(false)
            if (err?.response?.data?.errors) {
                setValidationErrors(err?.response?.data?.errors, actions)
                if (err?.response?.data?.errors?.['date_time']) {
                    actions?.setFieldError('booking_date', err?.response?.data?.errors?.date_time?.[0])
                    actions?.setFieldError('booking_time', err?.response?.data?.errors?.date_time?.[0])
                }
            }
            else {
                toast.error('Unexpected Error Occurred')
            }
        });
}

export const handlePhoneChange = (
    e: any,
    handleChange: Function,
    setPhoneNumber: Function,
    setFieldTouched: Function,
    resetForm: Function,
    setShowPatientNameInput: Function
) => {
    setShowPatientNameInput(false)
    resetForm()
    setFieldTouched('patient_mobile', true, true)
    setPhoneNumber(e?.target?.value);
    handleChange(e);
};

export const handleShowPatientNameInput = (setMethod: Function) => {
    setMethod(true)
} 

export const handlePatientExistSelect = (setFieldValue: Function, e: any, setValues: Function, patientList: any) => {
    const filteredValues = patientList?.fullData?.filter((itm: any) => itm?.id === e?.value)?.[0]
    setValues((prev: any) => ({
        ...prev,
        patient_id: e?.value,
        patient_email: filteredValues?.email || "",
        department_id: filteredValues?.department_id || "",
        doctor_id: filteredValues?.doctor_id || "",
        patient_gender: filteredValues?.gender || "",
        patient_address: filteredValues?.address || "",
        patient_dob: filteredValues?.dob || "",
    }))
}