import { SidebarMenuItemType } from "../../../Type";

const DoctorSidebarMenuItems: SidebarMenuItemType[] = [
  {
    icon: <i className='icon svg-icon icon-dashboard'></i>,
    label: 'Dashboard',
    path: '/doctor/dashboard'
  },
  {
    icon: <i className='icon svg-icon icon-appointment'></i>,
    label: 'Appointment',
    path: '/doctor/appointment'
  },
  {
    icon: <i className='icon svg-icon icon-patient'></i>,
    label: 'Patients',
    path: '/doctor/patients',
    AllPaths: ['/doctor/patient-profile','/doctor/patient-prescription']
  },
  {
    icon: <i className='icon svg-icon icon-settings'></i>,
    label: 'Settings',
    path: '/doctor/settings'
  },
];

export { DoctorSidebarMenuItems }
