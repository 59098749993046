import * as Yup from "yup";

export const MetaValidationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required").nullable(),
  title: Yup.string().required("Title is required").nullable(),
});

export const AddMetaInitialValues = {
  description: "",
  title: "",
  status: "",
};
