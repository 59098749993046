import { SidebarMenuItemType } from "../../../Type";

const StaffSidebarMenuItems: SidebarMenuItemType[] = [
  {
    icon: <i className="icon svg-icon icon-dashboard"></i>,
    label: "Dashboard",
    path: "/staff/dashboard",
  },
  {
    icon: <i className="icon xl icon-bookings"></i>,
    label: "Bookings",
    path: "/staff/booking",
  },
  {
    icon: <i className="icon svg-icon icon-appointment"></i>,
    label: "Appointment",
    path: "/staff/appointment",
  },
  {
    icon: <i className="icon svg-icon icon-patient"></i>,
    label: "Patients",
    path: "/staff/patients",
    AllPaths: ['/staff/patient-profile']
  },
  {
    icon: <i className="icon svg-icon icon-settings"></i>,
    label: "Settings",
    path: "/staff/settings/1",
  },
];

export { StaffSidebarMenuItems };
