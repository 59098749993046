import { useState } from 'react'
import { awardDeleteHandler } from '../ProfileTiles/DoctorProfileTileMethods'
import CustomSpinner from '../../../../../../Components/CustomElements/Spinner/SpinnerCustom'
import { AwardCardType } from '../../../../../../Type'

const Awards = ({ doctorProfile, refetch, setIsModalOpen, obj }: AwardCardType) => {
    const [isDeleting, setIsDeleting] = useState<boolean>(true)
console.log(obj,"award")
    return (
        <div className='thumb'>
            <img src={obj?.image_url || "/images/awardsimg1.webp"} alt={`Certificate${obj?.id}`} />
            <p>{obj?.title}</p>
            <div className='delete-award'>
                <span onClick={() => awardDeleteHandler(obj?.id, refetch, setIsDeleting)}>
                    {isDeleting ? <i className="icon icon-delete-red xl"></i> : <CustomSpinner />}
                </span>
            </div>
        </div>
    )
}

export default Awards
