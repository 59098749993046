import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Form, Input, InputGroup, Label, Row } from "reactstrap";
import CustomButton from "../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../Components/FormikError/TextError";
import useFocusOnLoad from "../../../Hooks/useFocusOnLoad";
import {
  initializeLoginInitialValues,
  LoginValidation,
} from "./LoginPageMethods/FormikMethods";
import { LoginSubmitHandler } from "./LoginPageMethods/LoginHandlers";

const Login = () => {
  const emailInput: any = useFocusOnLoad()
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [LoginInitialValues] = useState(()=>initializeLoginInitialValues())
 
  return (
    <div className="login-wrapper">
      <div className="login-outer-wrap">
        <div className="login-inner-wrap">
          <Row className="login-inner-row">
            <Col lg={4} md={6} className="login-form-outer-wrap">
              <div className="logo-wrap">
                <img src="images/logo-blue.svg" alt="logo" />
              </div>
              <div className="login-form-wrap">
                <div className="heading">
                  <h1>Sign In</h1>
                  <p>Welcome back, Please login to your account.</p>
                </div>

                <Formik
                  validationSchema={LoginValidation}
                  initialValues={LoginInitialValues}
                  enableReinitialize
                  onSubmit={(values: any, actions: any) =>
                    LoginSubmitHandler(values, actions, navigate)
                  }
                >
                  {({
                    values,
                    isSubmitting,
                    handleChange,
                    touched,
                    handleSubmit,
                  }: any) => {                    
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="form-inner-wrap">
                          <Row className="gy-4">
                            <Col lg={12}>
                              <Input
                                innerRef={emailInput}
                                type="text"
                                name="email"
                                value={values?.email}
                                onChange={handleChange}
                                placeholder="Username"
                              />
                              <ErrorMessage
                                name="email"
                                component={TextError}
                              />
                            </Col>
                            <Col lg={12}>
                              <InputGroup>
                                <Input
                                  name="password"
                                  type={passwordVisible ? 'text' : 'password'}
                                  placeholder="Password"
                                  value={values?.password}
                                  aria-describedby="newpass"
                                  onChange={handleChange}
                                />
                                <span className="input-icon" id="newpass">
                                  <i
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    className={`icon xl ${passwordVisible ? "icon-eye-open" : "icon-eye-close"
                                      }`}
                                  />
                                </span>
                              </InputGroup>
                              <ErrorMessage
                                name="password"
                                component={TextError}
                              />
                            </Col>
                            <Col lg={12}>
                              <div className="remember-password-wrap">
                                <div className="left-side check-box-wrap">
                                  <Input type='checkbox' id="remember-me" name="remember_me" onChange={handleChange} checked={values?.remember_me} />
                                  <Label htmlFor="remember-me">Remember me</Label>
                                </div>
                                <div className="right-side">
                                  <Link to="/forgot-password">Forgot Password?</Link>
                                </div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="submit-btn-wrap end">
                                <CustomButton
                                  isLoading={isSubmitting}
                                  label="Login"
                                  className="sm"
                                  color="secondary"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Col>
            <Col lg={8} md={6} className="login-banner-column">
              <div className="login-banner-wrap">
                <img src="images/login-bg.webp" alt="img" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Login;
