import { toast } from "react-toastify";
import { setValidationErrors } from "../../../../../../Utils/helpers/helpers";
import { api } from "../../../../../../Api/Api";

export const EducationSubmitHandler = async (values: any, actions: any, setIsOpen: Function, refetch: Function, editMode: boolean, id: string) => {

    if (editMode) {
        // Edit 
        api?.put(`/education/${id}`, values, true)
            .then(async function ([success, response]: any) {
                toast.success('Education Updated ')
                setIsOpen(false)
                refetch()
            })
            .catch((err) => {
                actions?.setSubmitting(false)
                if (err?.response?.data?.errors) {
                    setValidationErrors(err?.response?.data?.errors, actions)
                }
                else {
                    toast.error('Unexpected Error Occurred')
                }
            });
    }
    else {
        // Add
        try {
            const [success] = await api.post("/education", values, true);
            if (success) {
                toast.success('Education Added');
                setIsOpen(false);
                refetch();
            }
            else {
                toast.error('Failed to Add Education');
            }
        } catch (err: any) {
            actions?.setSubmitting(false);

            if (err?.response?.data?.errors) {
                setValidationErrors(err?.response?.data?.errors, actions);
            } else {
                toast.error('Unexpected Error Occurred');
            }
        }
    }
}


export const deleteClickHandler = (type: string, id: string, refetch: Function) => {

    const DELETE_API: any = {
        EDUCATION: 'education',
        SKILL: 'skill',
        EXPERIENCE: 'experience'
    }

    api.delete(`/${DELETE_API[type]}/${id}`, {}, true)
        .then(async function ([success, response]: any) {
            refetch()
            toast.success('Deleted')
        })
        .catch((err) => {
            toast.error('Unexpected Error Occurred')
        });
}


export const educationModalInitialValuesHandler = (data: any, setEditModalInitialValues: Function, setEditMode: Function, doctor_id: string | undefined) => {
    if (data?.length !== 0) {
        setEditMode(true)
        setEditModalInitialValues({
            doctor_id: doctor_id || '',
            qualification: data?.qualification || '',
            institution: data?.institution || ''
        })
    }
    else {
        setEditMode(false)
        setEditModalInitialValues({
            doctor_id: doctor_id,
            qualification: '',
            institution: ''
        })
    }
}