import { useState } from 'react'
import { Button, List } from 'reactstrap'
import DeleteAlertModal from '../../../../../../Components/Containers/AlertModal/DeleteAlertModal'
import ModalContainer from '../../../../../../Components/Containers/ModalContainer/ModalContainer'
import { AddEducationTabType } from '../../../../../../Type'
import constants from '../../../../../../Utils/Constants/Constants'
import { deleteClickHandler } from '../../DoctorProfile/Education/EducationMethods'
import { deleteConfirm } from '../../DoctorProfile/ProfileTiles/DetailsTimeMethods'
import { handleAddEdu, handleEdit } from '../AddDoctorMethods/AddDoctorModalMethods'
import AddEducationForm from './AddEducationForm'

const AddEducationTab: React.FC<AddEducationTabType> = ({ doctor_id, refetch, data, setActiveTab }) => {

    const [openAddEdu, setOpenAddEdu] = useState<boolean>(false)
    const [openDeleteModal, setDeleteModal] = useState<boolean>(false)
    const [editData, setEditData] = useState<any>([])
    const [deleteId, setDeleteId] = useState();

    return (
        <div className='add-doctor-form-wrap'>
            <div className='scroller'>
                <div>

                    <List className="doc-list">
                        {data?.educations?.map((ed: any, i: number) => {
                            return (
                                <>
                                    <li>
                                        <span>
                                            <h6 title={ed?.qualification}>{ed?.qualification}</h6>
                                            <p title={ed?.institution}>{ed?.institution}</p>
                                        </span>
                                       
                                        <div className="action-flex-wrap right-absolute">
                                            <span onClick={() => handleEdit(ed, setEditData, setOpenAddEdu)}><i className='icon icon-edit-blue xl'></i></span>
                                            <span onClick={() => deleteConfirm(ed?.id, setDeleteModal, setDeleteId)}><i className='icon icon-delete-red xl'></i></span>
                                        </div>
                                    </li>
                                </>
                            )
                        })}
                         {openAddEdu &&
                                            <AddEducationForm
                                                doctor_id={doctor_id}
                                                refetch={refetch}
                                                setIsOpen={() => setOpenAddEdu(false)}
                                                data={editData}
                                            />}
                    </List>

                    <div className='add-more-outer'>
                        {!openAddEdu && <Button tag='a' color='none' className='sm add-more-button' onClick={() => handleAddEdu(setEditData, setOpenAddEdu)}><i className='icon icon-plus-gray xl'></i></Button>}
                    </div>
                </div>


            </div>
            <div>
                <Button color='secondary w-100' className='sm next-btn' onClick={() => setActiveTab((prev: number) => prev + 1)}>Next</Button>
            </div>

            <ModalContainer isOpen={openDeleteModal} setIsopen={setDeleteModal} title="">
                <DeleteAlertModal
                    setModal={setDeleteModal}
                    onDeleteClick={(id: string) => deleteClickHandler(constants?.DOCTOR_PROFILE_TILE_TYPES?.EDUCATION, id, refetch)}
                    delete_id={deleteId}
                />
            </ModalContainer>
        </div>
    )
}

export default AddEducationTab
