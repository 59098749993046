import React from 'react'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { calculate_age, capitalizeWord } from '../../../../../../Utils/helpers/helpers'
import constants from '../../../../../../Utils/Constants/Constants'

const ProfileCard = ({ data }: any) => {

    return (
        <Card className="widget-card patient-details-full-wrap">
            <CardBody>
                <div className="patient-details-wrap">
                    <div className="thumb-group">
                        <div className='patient-image'>
                            <img src={constants.DEFAULT_IMAGE.IMAGE} className='thumbnail-sm-contain' alt="img" />
                        </div>
                        <div className="patient-name">
                            <h6>{data?.name}</h6>
                        </div>
                    </div>
                    <div className="patient-details-inner-wrap">
                        <Row className='gy-4'>
                            <Col lg={6}>
                                <div className="item">
                                    <small className="head">Patient ID</small>
                                    <small className="details">{data?.id}</small>
                                </div>
                            </Col>
                            <Col lg={6}>
                                {}
                                <div className="item">
                                    <small className="head">Age</small>
                                    <small className="details">{calculate_age(data?.dob)}</small>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="item">
                                    <small className="head">Gender</small>
                                    <small className="details">{capitalizeWord(data?.gender)}</small>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="item">
                                    <small className="head">Mobile</small>
                                    <small className="details">{data?.mobile}</small>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="item">
                                    <small className="head">Address</small>
                                    <small className="details">{data?.address}</small>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default ProfileCard