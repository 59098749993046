const TextError = (props: any) => {
    return (
        <div className="error-outer">
            <div className="errors validation-danger">
                <i className="icon icon-danger-validation"></i>&nbsp;&nbsp;
                {props?.children}
            </div>
        </div>
    );
};

export default TextError;