import { FormikHelpers } from "formik";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeFirst,
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import { toast } from "react-toastify";
import { AddStaffInitialValues } from "./FormikValidation";

type AddInitialValues = {
  name: string;
  mobile: string;
  email: string;
  gender: string;
  dob: string;
  joinedAt: string;
  departmentId: string;
  designationId: string;
};

export const StaffSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (editMode) {
    api
      .put(`/staff/${id}`, values, true)
      .then(async function ([success, response]: any) {
        toast.success("Staff Details Updated ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
  // Edit
  else {
    api
      .post("/staff", values, true)
      .then(async function ([success, response]: any) {
        toast.success("Staff Added ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.name,
        mobile: item?.mobile,
        email: item?.email,
        gender: capitalizeWord(item?.gender),
        department: item?.department?.name,
        designation: item?.designation?.name,
        dob: item?.dob,
        joinedAt: item?.joinedAt,
        departmentId: item?.department?.name,
        designationId: item?.designation?.name,
        type: item?.type
      };
  })
  return res
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (modalEditData: any, setAddModalInitialValues: Function, setEditMode: Function) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      name: modalEditData?.[0]?.name,
      mobile: modalEditData?.[0]?.mobile,
      email: modalEditData?.[0]?.email,
      dob: modalEditData?.[0]?.dob,
      joinedAt: modalEditData?.[0]?.joinedAt,
      departmentId: modalEditData?.[0]?.department?.id,
      designationId: modalEditData?.[0]?.designation?.id,
      gender: modalEditData?.[0]?.gender,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddStaffInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/staff/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  console.log(`/admin/staff/${pageNo}`);
  
  navigate(`/admin/staff/${pageNo}`)
}