import React from 'react'
import { Col } from 'reactstrap'
import Header from '../Header/Header'
import DoctorSidebar from './DoctorSIdebar'
import useHamburgerMenu from '../../../Hooks/useHamburgerMenu'

const DoctorLayout = ({ children, ROLE }: any) => {

    const { isOpen, toggleMenu } = useHamburgerMenu();

    return (
        <>
            <DoctorSidebar isOpen={isOpen}/>
            <Col className={` ${isOpen ? 'full-width-col' : ' minimise offset-md-3 offset-lg-2 offset-md-sm-3'}`}>
                <Header ROLE={ROLE} toggleMenu={toggleMenu} />
                <div className="content-wrapper">
                {children}
                </div>
            </Col>
        </>
    )
}

export default DoctorLayout