import moment from "moment";
import { api } from "../../../../Api/Api";

export const getDashboardData = async () => {
    const [, data] = await api.get(`/admindashboard`, true);
    return data
}

export const adminWidgetDataInitializer = (data: any) => {
    
    const appointmentsTableData = data?.confirmed_appointments?.map((itm: any) => ({
        Time: moment(itm?.date_time).format("hh:mm A"),
        Name: itm?.patient_name,
        date_of_birth: itm?.dob,
        Mobile: itm?.patient_mobile,
        Department: itm?.department_name,
        Doctor: itm?.doctor_name

    }));

    const upcoming_appointments = data?.upcoming_appointments?.map((itm: any) => ({
        Date_Time: itm?.date_time || '',
        Name: itm?.patient_name || '',
        Mobile: itm?.patient_mobile || '',
        Department: itm?.department_name || '',
        Doctor: itm?.doctor_name || ''
    }));

    return {
        appointmentsTableData: appointmentsTableData,
        upcoming_appointments: upcoming_appointments,
        pie_chart: data?.pie_chart,
        bar_graph:data?.bar_graph
    }
}