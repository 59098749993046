import React, { useEffect, useState } from "react";
import { ModalDataType } from "../../Type";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { GetPropertyValue } from "../../Utils/helpers/helpers";
import { api } from "../../Api/Api";
import { Link } from "react-router-dom";
import ModalContainer from "../Containers/ModalContainer/ModalContainer";


const DataList = ({
  api_name,
  table_headers,
  page_from,
  edit,
  delete_data,
  title,
  viewType,
}: ModalDataType) => {
  const [data, setData] = useState<any[]>([]); // Initialize data as an empty array
  const [isOpen, setIsOpen] = useState(false);
  const [data_items, setDataItems] = useState({
    name: "",
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api_name]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchData() {
    api
      .get(api_name, true)
      .then(async function ([success, response]: any) {
        setData(response?.data);
        return response;
      })
      .catch((_err) => { });
  }
  return (
    <>
      <Container>
        <Row className="gy-4">
          {viewType === "card" ? (
            <>
              {/* cards  */}
              {data?.map((itm: any, i: number) => {
                return (
                  <Col sm={12} md={3} lg={3} key={i}>
                    <Link to={`#`}>
                      <Card className="specialists-card">
                        <a href="/doctor-profile">
                          <div className="thumbnail-wrap">
                            <img
                              src={
                                itm?.image ||
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKPLj32Z1-scMQo5H2zkZRk42qe8V1rktimA&usqp=CAU"
                              }
                              className="thumbnail-md-cover"
                              alt="doctor"
                            />
                          </div>
                          <CardBody>
                            <CardTitle className="name-wrap">
                              <h6 className="name">
                                {itm?.name?.length >= 10
                                  ? itm?.name?.substring(0, 10) + "..."
                                  : itm?.name}{" "}
                              </h6>
                            </CardTitle>
                          </CardBody>
                        </a>
                      </Card>
                    </Link>
                  </Col>
                );
              })}
            </>
          ) : (
            <>
              {" "}
              <div className="table-outer-wrap">
                <div className="table-head-outer">
                  <div className="table-heading">
                    <h6></h6>
                  </div>
                </div>

                <div className="table-wrap">
                  <Table striped>
                    <thead>
                    
                      <tr>
                        {table_headers?.map((item: any, key: number) => (
                          <th key={key}>{item?.label}</th>
                        ))}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item: any, rowIndex: number) => (
                        <tr key={rowIndex}>
                          {table_headers?.map(
                            (head: any, columnIndex: number) => (
                              <td key={columnIndex}>
                                {GetPropertyValue(item, head.value)}
                              </td>
                            )
                          )}

                          <td>
                            {edit && (
                              <>
                                <Link
                                  to="/admin/patient-profile"
                                  onClick={() => {
                                    setIsOpen(true);
                                    setDataItems(item);
                                  }}
                                >
                                  Edit /
                                </Link>
                              </>
                            )}


                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          )}
        </Row>
      </Container>
      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title={`Edit ${title}`}
      >
        {page_from === "PatientList" && (
          <>
            {/* {edit && (
              <>
                <EditPatient
                  validationSchema={ForgotValidationSchema}
                  submitHandler={AddPatientEditSubmitHandler}
                  initialValues={AddPatientInitialValues}
                  value={data_items}
                />
              </>
            )} */}
          </>
        )}
        {page_from === "PatientList" && (
          <>
            {/* {edit && (
              <>
                <EditPatient
                  validationSchema={PatientValidationSchema}
                  submitHandler={AddPatientSubmitHandler}
                  initialValues={AddPatientInitialValues}
                  value={data_items}
                />
              </>
            )} */}
          </>
        )}
      </ModalContainer>
    </>
  );
};

export default DataList;
