/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useQuery } from "react-query";
import {
  InputGroup,
  Input,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { getFeedback } from "../../../../Api/AdminApis";
import DeleteAlertModal from "../../../../Components/Containers/AlertModal/DeleteAlertModal";
import {
  deleteClickHandler,
  handleAdminFeedbackSearch,
  submitClickHandler,
  submitRestoreClickHandler,
} from "./FeedbackMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { useNavigate, useParams } from "react-router-dom";
import AllFeedback from "./AllFeedback";
import PublishFeedback from "./PublishFeedback";
import UnPublishFeedback from "./UnPublishFeedback";

const FeedbackList = () => {
  const { pageNo, tab } = useParams()
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  const [openMoadl, setModal] = useState(false);
  const [delete_id, setDeleteId] = useState({
    id: "",
    status: "",
    type: "",
  });

  const {
    data: feedback,
    isLoading: feedbackLoading,
    refetch: feedbackListRefetch }: any = useQuery([`feedback-page${pageNo}-tab${tab + searchQuery}`, searchQuery], () => getFeedback(tab, pageNo, searchQuery),
      {
        keepPreviousData: true
      }
    );

  const setCurrentPage = (newPageNo: any) => {
    navigate(`/admin/feedback/${tab}/${newPageNo}`);
  };

  const delete_Confirm = (id: any, status: any, type_: any) => {
    setModal(true);
    setDeleteId({
      ...delete_id,
      id: id,
      status: status,
      type: type_,
    });
  };

  return (
    <>
      <div className="search-bar-full-wrap">
        <div className="top-widget-tabs">
          <Nav pills>
            <NavItem>
              <NavLink
                className={`tab-button ${tab === "3" ? "active" : ""}`}
                onClick={() => {
                  setSearchText('')
                  setSearchQuery('')
                  navigate(`/admin/feedback/3/${pageNo}`)
                }}
                style={{ cursor: "pointer" }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`tab-button ${tab === "1" ? "active" : ""}`}
                onClick={() => {
                  setSearchText('')
                  setSearchQuery('')
                  navigate(`/admin/feedback/1/${pageNo}`)
                }}
                style={{ cursor: "pointer" }}
              >
                Published
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`tab-button ${tab === "0" ? "active" : ""}`}
                onClick={() => {
                  setSearchText('')
                  setSearchQuery('')
                  navigate(`/admin/feedback/0/${pageNo}`)
                }}
                style={{ cursor: "pointer" }}
              >
                Deleted
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {/* <div className="search-bar">
          <InputGroup>
            <Input
              placeholder="Search here..."
              value={searchText}
              onChange={(e) => handleAdminFeedbackSearch(e, setSearchQuery, navigate, tab, setSearchText)}
            />
            <span className="icon-container">
              <i className="icon svg-icon icon-search"></i>
            </span>
          </InputGroup>
        </div> */}
      </div>

      {tab === "3" && (
        <>
          <AllFeedback
            setCurrentPage={setCurrentPage}
            delete_Confirm={delete_Confirm}
            feedbackLoading={feedbackLoading}
            feedback={feedback}
          />
        </>
      )}
      {tab === "1" && (
        <>
          <PublishFeedback
            setCurrentPage={setCurrentPage}
            delete_Confirm={delete_Confirm}
            feedbackLoading={feedbackLoading}
            feedback={feedback}
          />
        </>
      )}
      {tab === "0" && (
        <>
          <UnPublishFeedback
            setCurrentPage={setCurrentPage}
            delete_Confirm={delete_Confirm}
            feedbackLoading={feedbackLoading}
            feedback={feedback}
          />
        </>
      )}
      <ModalContainer isOpen={openMoadl} setIsopen={setModal} title="">
        <DeleteAlertModal
          setModal={setModal}
          onDeleteClick={() =>
            deleteClickHandler(delete_id, feedbackListRefetch)
          }
          onSubmitClick={() =>
            submitClickHandler(delete_id, feedbackListRefetch)
          }
          onRestoreClick={() =>
            submitRestoreClickHandler(delete_id, feedbackListRefetch)
          }
          delete_id={delete_id}
          publish={true}
          Refetch={feedbackListRefetch}
        />
      </ModalContainer>
    </>
  );
};

export default FeedbackList;
