import * as Yup from "yup";

export const AddExperienceInitialValues = {
    fromdate: '',
    todate: '',
    institution: ''
}

export const AddExperienceValidationSchema = Yup.object().shape({
    doctor_id: Yup.string().required('Skill is required'),
    institution: Yup.string().required('Skill is required'),
    fromdate: Yup.date()
        .required("From date is required")
        .max(new Date(), "From date must be before today"),
    todate: Yup.date()
        .required("To date is required")
        .min(Yup.ref("fromdate"), "To date must be after From date"),
});