import { FormikHelpers } from "formik";
import { api } from "../../../../../../Api/Api";
import {
  capitalizeWord,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import { toast } from "react-toastify";
import { AddMetaInitialValues } from "./FormikValidation";

type AddInitialValues = {
  description: string;
  title: string;
  status: any;
};

export const MetaSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number
) => {
  // Add
  if (editMode) {
    api
      .put(`/meta/${id}`, values, true)
      .then(async function ([success, response]: any) {
        toast.success("Staff Details Updated ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
  // Edit
  else {
    api
      .post("/meta", values, true)
      .then(async function ([success, response]: any) {
        toast.success("Meta Added");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};
export const MetaSatusSubmitHandler = (
  id: any,
  status: any,
  refetch: Function,
  setIsOpen: Function
) => {
  // Add

  api
    .put(
      `/meta/${id}`,
      {
        status: status === "1" ? "2" : "1",
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Meta Status Updated");
      setIsOpen(false);
      refetch();
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      description: item?.description,
      title: item?.title,
      status: item?.status,
      type:item?.type
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      description: modalEditData?.[0]?.description,
      title: modalEditData?.[0]?.title,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddMetaInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  doctorData: any
) => {
  setModalEditData([]);
  setModalEditData(
    doctorData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};

// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/meta/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const setCurrentPageStaff = (navigate: Function, pageNo: any) => {
  console.log(`/admin/staff/${pageNo}`);

  navigate(`/admin/staff/${pageNo}`);
};
