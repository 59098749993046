import * as Yup from "yup";
import {
  emailSchema,
  phoneSchema,
} from "../../../../../../Utils/Formik/FormikCommonValidationSchemas";

export const AppointmentValidationSchema = Yup.object().shape({
  patient_name: Yup.string().required("Patient name is required"),
  patient_mobile: phoneSchema.required("Patient mobile number is required"),
  doctor: Yup.string().required("Doctor is required").nullable(),
  department_name: Yup.string().required("Department is required"),
  address: Yup.string().required("Patient address is required"),
  patient_email: Yup.string().email().required("Email is invalid"),
  booking_time: Yup.string().required("Booking Time is required"),
  booking_date: Yup.string().required("Booking Date is required"),
  payment_confirm: Yup.string().required("Payment completed is required"),
  patient_dob: Yup.string().required("Patient date of birth is required"),
});

export const ForgotValidationSchema = Yup.object().shape({
  patient_name: Yup.string().required("Full name is required"),
  patient_mobile: phoneSchema.required("Phone number is required"),
  doctor: Yup.string().required("Doctor is required").nullable(),
  department_name: Yup.string().required("Department is required"),
  address: Yup.string().required("Address is required"),
  patient_email: Yup.string().email().required("Email is invalid"),
  booking_time: Yup.string().required("Booking Time is required"),
  booking_date: Yup.string().required("Booking Date is required"),
  payment_confirm: Yup.string().required("feild is required"),
  patient_dob: Yup.string().required("feild is required"),
});

export const AddappointmentInitialValues = {
  patient_name: "",
  patient_mobile: "",
  patient_email: "",
  department_name: "",
  doctor: "",
  patient_gender: "",
  address: "",
  booking_time: "",
  booking_date: "",
  payment_confirm: "",
  patient_dob: "",
};
