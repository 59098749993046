import React from "react";
import { Card, CardBody } from "reactstrap";

const DocumentsTile = ({ name, file_url }: any) => {
    console.log(name,"file_urlfile_url")
  return (
    <Card className="disease-card no-bg">
      <CardBody className="disease-card-flex-wrap">
        <div className="icon-wrap">
          <i className="icon xl icon-documents"></i>
        </div>
        <div className="disease-card-details">
          <p className="doc-name">{name}</p>
        </div>
        <a
          href={file_url}
          target="_blank"
          download
          style={{ cursor: "pointer" }}
        >
          <i className="icon xl icon-download"></i>
        </a>
      </CardBody>
    </Card>
  );
};

export default DocumentsTile;
