import { Button, Card, CardBody, Col, Row } from "reactstrap";
import CustomPagination from "../../../../Components/Pagination/Pagination";
import {
  capitalizeFirst,
  convertISODateToDateTime,
} from "../../../../Utils/helpers/helpers";
import { FeedbackListProps } from "../../../../Type";
import CustomSpinner from "../../../../Components/CustomElements/Spinner/SpinnerCustom";

export default function PublishFeedback({
  setCurrentPage,
  delete_Confirm,
  feedbackLoading,
  feedback
  
}: FeedbackListProps) {
  return (
    <>
      {feedbackLoading ? (
        <div className="spinner-wrap">
          <CustomSpinner />
        </div>
      ) : (
        <>

        {feedback?.data?.length ===0 ? <div className="feedback-outer-wrap mt-2 ju">
              <span className="empty-text">No Data</span>
            </div>:<> {feedback?.data?.map((item: any) => {
            return (
              <>
                <div className="feedback-outer-wrap mt-2">
                  <Row className="gy-4">
                    <Col xxl={12}>
                      <Card
                        className={`feedback-card ${
                          item?.status === "2"
                            ? "publish"
                            : item?.status === "0"
                            ? "restore"
                            : item?.status === "1"
                            ? "unpublish"
                            : ""
                        }`}
                      >
                        <CardBody>
                          <Row className="gy-4 align-items-center">
                            <Col md={8} className="feedback-details-wrap">
                              <div className="feedback-head-wrap">
                                <h4
                                  className={`name ${
                                    item?.deleted_at !== null
                                      ? "warning"
                                      : "success"
                                  }`}
                                >
                                  {capitalizeFirst(item?.name)}
                                </h4>
                                <h6 className="date">
                                  {convertISODateToDateTime(item?.created_at)}
                                </h6>
                              </div>
                              <div className="feedback-description">
                                <p>{item?.message}</p>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="publish-btn-wrap">
                                {item?.status === "2" ? (
                                  <Button
                                    color="secondary"
                                    outline
                                    className="default"
                                    onClick={() =>
                                      delete_Confirm(item?.id, "1", "Publish")
                                    }
                                  >
                                    Publish
                                  </Button>
                                ) : item?.status === "4" ? (
                                  <Button
                                    color="secondary"
                                    outline
                                    className="default"
                                    onClick={() =>
                                      delete_Confirm(item?.id, "2", "Restore")
                                    }
                                  >
                                    Restore
                                  </Button>
                                ) : item?.status === "0" ? (
                                  <Button
                                    color="secondary"
                                    outline
                                    className="default"
                                    onClick={() =>
                                      delete_Confirm(item?.id, "2", "Restore")
                                    }
                                  >
                                    Restore
                                  </Button>
                                ) : item?.status === "1" ? (
                                  <Button
                                    color="secondary"
                                    outline
                                    className="default"
                                    onClick={() =>
                                      delete_Confirm(item?.id, "2", "UnPublish")
                                    }
                                  >
                                    Unpublish
                                  </Button>
                                ) : null}
                                {item?.status === "2" && (
                                  <a
                                    href="#"
                                    className="delete-button"
                                    title="Delete"
                                    onClick={() =>
                                      delete_Confirm(item?.id, "0", "delete")
                                    }
                                  >
                                    <i className="icon icon-delete xl"></i>
                                  </a>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </>
            );
          })}</>}
         
        </>
      )}
      {feedback?.meta?.total !== 0 && (
        <div className="pagination">
          <CustomPagination
            totalItems={feedback?.meta?.total}
            itemsPerPage={feedback?.meta?.per_page}
            activePage={feedback?.meta?.current_page}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
}
