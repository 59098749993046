import * as Yup from "yup";
import { dobSchema, emailSchema, phoneSchema } from "../../../../../../Utils/Formik/FormikCommonValidationSchemas";

// General Info start
export const DoctorGeneralInfoValidationSchema = Yup.object().shape({
    name: Yup.string().required('Full name is required'),
    experience_years: Yup.string().required('Years of experience is required '),
    mobile: phoneSchema.required('Phone number is required'),
    email: emailSchema.required('Email is required'),
    gender: Yup.string().required('Gender is required'),
    joined_at: Yup.date().required('Joining date is required'),
    department_id: Yup.string().required('Department is required'),
    designation_id: Yup.string().required('Designation is required'),
    description: Yup.string().required('Description is required'),
    image_name: Yup.string().required('The image field is required'),
    dob: dobSchema,
});

export const GeneralInfoInitialValues = {
    name: '',
    experience_years: '',
    mobile: '',
    email: '',
    gender: '',
    joined_at: '',
    department_id: '',
    designation_id: '',
    description: '',
    image_name: '',
    dob: ''
}
// General Info end 