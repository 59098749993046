import { Route } from "react-router-dom";
import BrowserRouterProvider from "./BrowserRouterWrap";
import PublicAuthProvider from "./Auth/PublicAuthProvider";
import PrivateAuthProvider from "./Auth/PrivateAuth";
import Login from "../Public/LoginPage/Login";
import RoleProvider from "./Auth/RoleProvider";
import { USER_ROLES } from "../../Utils/AuthenticationConstants";
import AdminDashboard from "../Private/Admin/Dashboard";
import PatientList from "../Private/Admin/Patient";
import PatientProfile from "../Private/Admin/Patient/PatientProfile";
import DoctorsIndex from "../Private/Admin/Doctor";
import DepartmentList from "../Private/Admin/Department";
import DesigantionList from "../Private/Admin/Designation";
import StaffList from "../Private/Admin/Staff";
import PermissionList from "../Private/Permissions/PermissionList";
import Settings from "../Private/Settings/Settings";
import AdminProfile from "../Private/Admin/Adminprofile/AdminProfile";
import StaffDashboard from "../Private/Staff/Dashboard";
import AppointmentIndex from "../Private/Admin/Appointment";
import DoctorProfile from "../Private/Admin/Doctor/DoctorProfile";
import DoctorAppointment from "../Private/Doctor/Appoinment/Appointment";
import DoctorSettings from "../Private/Settings/Settings";
import FeedbackList from "../Private/Admin/Feedback/FeebackList";
import StaffPatientList from "../Private/Staff/Patient";
import DoctorPatientList from "../Private/Doctor/Patient";
import PatientPrescription from "../Private/Doctor/Appoinment/PatientPrescription";
import BookingIndex from "../Private/Admin/Bookings";
import WebsiteSettings from "../Private/Admin/WebsiteSettings";
import StaffAppointmentIndex from "../Private/Staff/Appoinments";
import DoctorDashboard from "../Private/Doctor/Dashboard";
import PatientProfileStaff from "../Private/Staff/Patient/PatientProfile";
import PatientProfileDoctor from "../Private/Doctor/Patient/PatientProfile";
import AppoinmentTab from "../Private/Admin/Appointment/AppointmentTab";
import DashboardAppoinmentTab from "../Private/CommonAppoinments/Appoinment";
import MetaIndex from "../Private/Admin/Meta";
import ForgotPasswordIndex from "../Public/ForgotPassword";
import ResetPassword from "../Public/ChangePassword";

const RoutesHandler = () => {
  return (
    <BrowserRouterProvider>
      <Route element={<PublicAuthProvider loginStatus={true} />}>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPasswordIndex />} />
        <Route path="/password/reset/:token" element={<ResetPassword />} />
      </Route>

      <Route element={<PrivateAuthProvider loginStatus={true} />}>
        {/* Admin  */}
        <Route
          element={<RoleProvider Role={USER_ROLES?.ADMIN} loginStatus={true} />}
        >
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          {/* <Route path="/admin/appointment" element={<AppointmentIndex />} /> */}
          <Route path="/admin/appointment/:tab?/:page_no?" element={<AppoinmentTab />} />
          <Route path="/admin/patients" element={<PatientList />} />
          <Route path="/admin/patient-profile" element={<PatientProfile />} />
          <Route
            path="/admin/doctors/:viewType/:currentPage?"
            element={<DoctorsIndex />}
          />
          <Route
            path="/admin/doctors-profile/:id"
            element={<DoctorProfile />}
          />
          <Route
            path="/admin/patient-profile/:id"
            element={<PatientProfile />}
          />
          <Route path="/admin/payments" element={<h1>payments</h1>} />
          <Route path="/admin/department" element={<DepartmentList />} />
          <Route path="/admin/designation" element={<DesigantionList />} />
          <Route path="/admin/staff/:currentPage?" element={<StaffList />} />
          <Route path="/admin/settings/:activeTab?" element={<Settings ROLE='admin' />} />
          <Route path="/admin/permission" element={<PermissionList />} />
          <Route path="/admin/profile" element={<AdminProfile />} />
          <Route
            path="/admin/feedback/:tab/:pageNo"
            element={<FeedbackList />}
          />
          <Route path="/admin/booking" element={<BookingIndex />} />
          <Route path="/admin/websitesettings" element={<WebsiteSettings />} />
          <Route
            path="/admin/dashboard/appointment"
            element={<DashboardAppoinmentTab />}
          />
          <Route path="/admin/meta" element={<MetaIndex />} />
        </Route>

        {/* Doctor  */}
        <Route element={<RoleProvider Role={USER_ROLES?.DOCTOR} />}>
          <Route path="/doctor/dashboard" element={<DoctorDashboard />} />
          <Route path="/doctor/appointment" element={<DoctorAppointment />} />
          <Route path="/doctor/patients/:pageNo?" element={<DoctorPatientList />} />
          <Route path="/doctor/settings/:activeTab?" element={<DoctorSettings ROLE='doctor' />} />
          <Route
            path="/doctor/patient-profile/:id"
            element={<PatientProfileDoctor />}
          />
          <Route
            path="/doctor/patient-prescription"
            element={<PatientPrescription />}
          />
          <Route
            path="/doctor/dashboard/appointment"
            element={<DashboardAppoinmentTab />}
          />
        </Route>

        {/* Staff  */}
        <Route element={<RoleProvider Role={USER_ROLES?.STAFF} />}>
          <Route path="/staff/dashboard" element={<StaffDashboard />} />
          <Route path="/staff/patients" element={<StaffPatientList />} />
          <Route
            path="/staff/patient-profile/:id"
            element={<PatientProfileStaff />}
          />
          <Route
            path="/staff/appointment"
            element={<StaffAppointmentIndex />}
          />
          <Route path="/staff/settings/:activeTab?" element={<Settings ROLE='staff' />} />
          <Route
            path="/staff/dashboard/appointment"
            element={<DashboardAppoinmentTab />}
          />
          <Route path="/staff/booking" element={<BookingIndex />} />
          <Route path="/staff/settings/:activeTab" element={<Settings />} />
        </Route>
      </Route>
    </BrowserRouterProvider>
  );
};

export default RoutesHandler;
