import React from "react";
import { Modal, ModalBody, ModalFooter, Button, Row, Col } from "reactstrap";

const DeleteConfirmation = ({ isOpen, setIsOpen, onDelete }: any) => {
  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} className="modal-dialog-centered custom-modal">
      <ModalBody>
        <div className="form-wrap">
        <Row className="gy-4">
        <Col lg={12}>
        <div className="modal-warning-text-wrap">
          <div className="warning-img-wrap">
            <img src="/images/delete-warning.svg" alt="warning-icon" />
          </div>
          <h5 className="modal-warning-title">Are you sure?</h5>
          <small className="sub-text">Do you want to delete ?</small>
        </div>
        </Col>
        <Col lg={12}>
          <div className="submit-btn-wrap centered">
            <Button color="danger" className="sm" onClick={onDelete}>
              Delete
            </Button>
            <Button color="secondary" className="sm" outline onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </div>
        </Col>
        </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteConfirmation;
