import { toast } from "react-toastify";
import { api } from "../../../../../../Api/Api";

export const handleAwardAdd = (setIsModalOpen: Function) => {
    setIsModalOpen(true)
}

export const awardDeleteHandler = (id: string, refetch: Function, setIsDeleting: Function) => {
    setIsDeleting(true)
    api.delete(`/awards/${id}`, {}, true)
        .then(async function ([success, response]: any) {
            refetch()
            toast.success('Deleted')
            setIsDeleting(false)
        })
        .catch((err) => {
            setIsDeleting(false)
            toast.error('Unexpected Error Occurred')
        });
}