import axios from "axios";
import { api } from "../../../../../../Api/Api";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../../../../Api/BaseUrl";

export function handleDiseaseHistoryClick(
  id: any,
  name: any,
  refetchDiseaseData: any
) {
  api
    .post(
      "/diseasehistory",
      {
        patient_id: id,
        name: name,
      },
      true
    )
    .then(async function ([success, response]: any) {
      if (success) {
        toast.success("Disease has been added.");
        refetchDiseaseData();
      } else {
        toast.error("Failed to add disease.");
      }
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.data.errors) {
        toast.error("An error occurred while adding disease: " + err.response.data.errors);
      } else {
        toast.error("An unexpected error occurred while adding disease.");
      }
    });
}

export async function handleDocumentsClick(
  id: any,
  file_name: any,
  file_path: string,
  refetchDocumentData: any
) {
  try {
    // Create a FormData object to send in the request
    const formData = new FormData();
    formData.append("patient_id", id);
    formData.append("file_name", file_name);
    formData.append("file_path", file_path);

    const res = await axios.post(`${BaseUrl}/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
    });

    if (res) {
      toast.success("Document uploaded");
      refetchDocumentData();
    }
  } catch (error: any) {
    toast.error("Unexpected Error Occurred");
  }
}

export function handleDiseaseDeleteClick(id:any, refetchDiseaseData:any) {
  api
    .delete(`/diseasehistory/${id}`, {}, true)
    .then(async function ([success, response]) {
      if (success) {
        toast.success("Disease has been deleted.");
        refetchDiseaseData();
      } else {
        toast.error("Failed to delete disease.");
      }
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.data.errors) {
        toast.error("An error occurred while deleting disease: " + err.response.data.errors);
      } else {
        toast.error("An unexpected error occurred while deleting disease.");
      }
    });
}


export function handleDiseaseUpdateClick(id: any, updatename: any, refetchDiseaseData: any) {
  const name = updatename;
  api
    .put(`/diseasehistory/${id}`, { name: name }, true)
    .then(async function ([success, response]) {
      if (success) {
        toast.success("Disease has been updated.");
        refetchDiseaseData();
      } else {
        toast.error("Failed to update disease.");
      }
    })
    .catch((err) => {
      if (err.response && err.response.data && err.response.data.errors) {
        toast.error("An error occurred while updating disease: " + err.response.data.errors);
      } else {
        toast.error("An unexpected error occurred while updating disease.");
      }
    });
}

