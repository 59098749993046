
export const handleSettingsDesignationDepartmentSearch = (e: any, setSearchQuery: Function, setCurrentPage: Function) => {
    if (e?.target?.value?.length > 2) {
        setCurrentPage('1')
        setSearchQuery(e?.target?.value)
    }
    else {
        setCurrentPage((prev: string) => prev)
        setSearchQuery('')
    }
}