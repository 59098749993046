import { FormikHelpers } from "formik";
import { api } from "../../../../../../Api/Api";
import { toast } from "react-toastify";
import {
  convertISODateToDate,
  convertISODateToDateTime,
  setValidationErrors,
} from "../../../../../../Utils/helpers/helpers";
import moment from "moment";

type AddInitialValues = {
  patient_name: string;
  patient_mobile: string;
  patient_email: string;
  department_name: string;
  doctor: string;
  patient_gender: string;
  address: string;
  booking_time: string;
  booking_date: string;
  payment_confirm: string;
  patient_dob: string;
};

export const AppointmentSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: any,
  data: any
) => {
  if (editMode) {
    api
      .post(
        `/appointments/${id}/confirm`,
        {
          patient_id: data?.patient_id,
          patient_name: values?.patient_name,
          patient_mobile: values?.patient_mobile,
          patient_gender: values?.patient_gender,
          patient_email: values?.patient_email,
          patient_address: values?.address,
          patient_dob: values?.patient_dob,
          department_id: values.department_name,
          doctor_id: values?.doctor,
          date_time: values?.booking_date + " " + values?.booking_time,
        },
        true
      )
      .then(async function ([success, response]: any) {
        toast.success(" Details Updated ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  } else {
    api
      .post(`/appointments`, {}, true)
      .then(async function ([success, response]: any) {
        toast.success(" Details added ");
        setIsOpen(false);
        refetch();
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          setValidationErrors(err?.response?.data?.errors, actions);
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  }
};

// initializing add patient form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);

    const slit_date = modalEditData?.[0]?.date_time?.split(" ");
    setAddModalInitialValues({
      patient_name: modalEditData?.[0]?.patient_name,
      patient_mobile: modalEditData?.[0]?.patient_mobile,
      patient_gender: modalEditData?.[0]?.patient_gender,
      patient_email: modalEditData?.[0]?.patient_email,
      doctor: modalEditData?.[0]?.doctor?.id,
      department_name: modalEditData?.[0]?.department?.id,
      booking_time: slit_date?.[1],
      booking_date: slit_date?.[0],
      patient_dob: modalEditData?.[0]?.patient_dob,
      address: modalEditData?.[0]?.patient_addres,
      payment_confirm: "",
    });
  }
};

// extract patient data for table
export const extractAppoinmentData = (
  setappointmentData: Function,
  data: any
) => {
  setappointmentData(
    data?.data?.map((item: any) => {
      return {
        id: item?.id,
        name: item?.patient_name,
        mobile: item?.patient_mobile,
        gender: item?.patient_gender,
        email: item?.patient_email,
        message: item?.message,
        address: item?.address,
        doctor_name: item?.doctor?.user?.name || "Unassigned",
        department_name: item?.department?.name,
        date_time: convertISODateToDateTime(item?.date_time),
        status: item?.status?.value,
      };
    })
  );
};

// appointment table edit onclick trigger
export const onEditappoinmentClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  appointment: any
) => {
  setModalEditData([]);
  setModalEditData(
    appointment?.data?.filter((itm: any) => itm?.id == selectedId)
  );
  setIsOpen(true);
};

// add patient open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};
