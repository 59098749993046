import * as Yup from "yup";

export const AwardValidationSchema = Yup.object().shape({
  image: Yup.string().required("Award file is required"),
  description: Yup.string()
    .required("Description is required")
    .max(250, "The name must not exceed 250 characters."),
  title: Yup.string()
    .required("Title is required")
    .max(100, "The name must not exceed 100 characters."),
});

export const AddAwardInitialValues = {
  image: "",
  description: "",
  title: "",
};
