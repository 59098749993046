import * as Yup from "yup";

export const TestimonialValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "The name must not exceed 100 characters."),
  image: Yup.string().required("Testimonials file is required"),
  designation: Yup.string()
    .required("Designation is required")
    .max(250, "The Designation must not exceed 250 characters."),
  description: Yup.string()
    .required("Description is required")
    .max(250, "The Description must not exceed 250 characters."),
});

export const AddTestimonialInitialValues = {
  name: "",
  image: "",
  description: "",
  designation: "",
};
