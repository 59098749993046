import { ErrorMessage, Formik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Input, InputGroup, Row } from 'reactstrap';
import CustomButton from '../../../Components/CustomElements/FormElements/CustomButton';
import TextError from '../../../Components/FormikError/TextError';
import { ResetPasswordValidationSchema, handlePasswordReset, togglePasswordVisibility, tokenValidator } from './ChangePasswordMethods';
import { useQuery } from 'react-query';
import CustomSpinner from '../../../Components/CustomElements/Spinner/SpinnerCustom';

const ResetPassword = () => {
    const { token } = useParams()
    const navigate = useNavigate()
    const [passwordVisible, setPasswordVisible] = useState({ password: false, confirmpassword: false })

    const { isError, isLoading: tokenValidating }: any = useQuery(`tokenValidator${token}`, () => tokenValidator(token),
        {
            keepPreviousData: true,
            enabled: token !== undefined,
            retry: 0,
        }
    );

    return (
        <div className="login-wrapper">
            <div className="login-outer-wrap">
                <div className="login-inner-wrap">
                    <Row className='login-inner-row'>
                        <Col lg={4} md={6} className='login-form-outer-wrap'>
                            <Link to='/'>
                                <div className="logo-wrap">
                                    <img src="/images/logo-blue.svg" alt="logo" />
                                </div>
                            </Link>
                            {tokenValidating ? <div>
                                <span>
                                    Validating token
                                    <CustomSpinner />
                                </span>
                            </div> :
                                !isError ?
                                    <div className="login-form-wrap">
                                        <div className="heading">
                                            <h1>Reset Your Password</h1>
                                        </div>

                                        <Formik
                                            validationSchema={ResetPasswordValidationSchema}
                                            initialValues={{ password: '', confirmpassword: '', token: token }}
                                            onSubmit={(values, actions) => handlePasswordReset(values, actions, navigate)}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                isSubmitting,
                                                values
                                            }) => {
                                                return (
                                                    <Form onSubmit={handleSubmit}>
                                                        <div className="form-inner-wrap">
                                                            <Row className='gy-4'>
                                                                <Col lg={12}>
                                                                    <InputGroup>
                                                                        <Input
                                                                            type={passwordVisible?.password ? 'text' : 'password'}
                                                                            placeholder="New Passowrd"
                                                                            name='password'
                                                                            onChange={handleChange}
                                                                            value={values?.password}
                                                                        />
                                                                        <span
                                                                            className='input-icon'
                                                                            onClick={() => togglePasswordVisibility('password', setPasswordVisible)}
                                                                        >
                                                                            <i className='icon icon-eye-close xl'></i>
                                                                        </span>
                                                                    </InputGroup>
                                                                    <ErrorMessage name="password" component={TextError} />
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <InputGroup>
                                                                        <Input
                                                                            type={passwordVisible?.confirmpassword ? 'text' : 'password'}
                                                                            placeholder="Confirm Password"
                                                                            name='confirmpassword'
                                                                            onChange={handleChange}
                                                                            value={values?.confirmpassword}
                                                                        />
                                                                        <span
                                                                            className='input-icon'
                                                                            onClick={() => togglePasswordVisibility('confirmpassword', setPasswordVisible)}>
                                                                            <i className='icon icon-eye-close xl'></i>
                                                                        </span>
                                                                    </InputGroup>
                                                                    <ErrorMessage name="confirmpassword" component={TextError} />
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <div className="submit-btn-wrap end">
                                                                        <CustomButton label='Continue' color={'secondary'} className="sm" isLoading={isSubmitting} />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </div>
                                    :
                                    <div>
                                        <span>
                                            <h5>Invalid Token</h5>
                                            <span>The token you provided is not valid or has expired. Please make sure you're using the latest password reset link that we sent to your email. If you continue to experience issues, please request a new password reset link</span>
                                            <Link to="/forgot-password">
                                                <div>Reset password</div>
                                            </Link>
                                        </span>
                                    </div>}
                        </Col>
                        <Col lg={8} md={6} className='login-banner-column'>
                            <div className="login-banner-wrap">
                                <img src="/images/reset-bg.webp" alt="img" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword