import * as Yup from "yup";
import { api } from "../../../../../Api/Api";
import { setValidationErrors } from "../../../../../Utils/helpers/helpers";
import { toast } from "react-toastify";

export const generalInfoEditInitializer = (setValue: Function, data: any, value: any) => {
    const val = {
        name: data?.user?.name || '',
        experience_years: data?.experience_years || '',
        mobile: data?.user?.mobile || '',
        email: data?.user?.email || '',
        gender: data?.user?.gender || '',
        joined_at: data?.joined_at || '',
        department_id: data?.department?.id || '',
        designation_id: data?.designation?.id || '',
        description: data?.description || '',
        image_name: data?.image_name || '',
        dob: data?.dob || ''
    }
    setValue(val)
}

export const AddSocialMediaValidationSchema = Yup.object().shape({
    facebook: Yup.string().url('Invalid URL format').required('Facebook URL is required'),
    linkedin: Yup.string().url('Invalid URL format').required('LinkedIn URL is required'),
    twitter: Yup.string().url('Invalid URL format').required('Twitter URL is required'),
});

export const AddSlotsValidationSchema = Yup.object().shape({
    week_day: Yup.string().required('Day is required'),
    time_start: Yup.string().required('Starting time is required'),
    time_end: Yup.string().required('Ending time is required')
});

export const addDoctorSlotHandler = async (values: any, actions: any, refetch: Function, setIsOpen: Function) => {
    try {
        const [success] = await api.post("/doctorslots", values, true);
        if (success) {
            toast?.success('Slot Added')
            refetch()
            setIsOpen(false)
        }
        else {
            toast.error('Failed to Add Education');
        }
    } catch (err: any) {
        actions?.setSubmitting(false);
        if (err?.response.data.message) {
            toast.error(err?.response.data.message)
        }
        else if (err?.response?.data?.errors) {
            setValidationErrors(err?.response?.data?.errors, actions);
        } else {
            toast.error('Unexpected Error Occurred');
        }
    }
}

export const getTimeSlotList = async (addedDocId: string | undefined) => {
    const [, data] = await api.get(
        `/doctorslots?doctor_id=${addedDocId}`,
        true
    );
    return data
}

export const handleImageChange = (e: any, setFieldValue: Function, setFile: Function) => {
    setFile(e.target.files?.[0])
    setFieldValue('image_name', 'name')
}

export const handleSlotDelete = async (id: string | undefined, timeRefetch: Function) => {
    api.delete(`/doctorslots/${id}`, {}, true)
        .then(async function ([success, response]: any) {
            timeRefetch()
            toast.success('Deleted')
        })
        .catch((err) => {
            toast.error('Unexpected Error Occurred')
        });
}