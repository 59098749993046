import React, { useMemo, useState } from "react";
import { InputGroup, Input } from "reactstrap";
import {
  extractPatientData,
  onEditClickHandler,
} from "./Addpatient/AddPatientMethods/AddPatientModalMethods";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import AddPatient from "./Addpatient";
import { useQuery } from "react-query";
import {
  getDepartmentFullList,
  getDoctorFullList,
  getPatientList,
} from "../../../../Api/AdminApis";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import { handlePatientSearch } from "./PatientListMethods";

const PatientList: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState("1");
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal

  //Api for Data
  const {
    data: patient,
    error: patientError,
    isLoading: patientLoading,
    refetch: patientListRefetch,
  }: any = useQuery(
    [`Patient${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getPatientList(currentPage, searchQuery),
    {
      keepPreviousData: true,
    }
  );

  const patientData = useMemo(() => extractPatientData(patient), [patient]);

  // Department List
  const {
    data: departmentList,
    error: departmentError,
    isLoading: departmentLoading,
  }: any = useQuery("department", () => getDepartmentFullList(), {
    staleTime: 15000,
  });

  // Doctor List
  const {
    data: doctorList,
    error: doctorError,
    isLoading: doctorLoading,
  }: any = useQuery("doctor", () => getDoctorFullList(), { staleTime: 15000 });

  const onDeleteClick = () => {};

  return (
    <>
      <div className="search-bar-full-wrap nowrap">
        <div className="search-bar">
          <InputGroup>
            <Input
              placeholder="Search here..."
              onChange={(e) =>
                handlePatientSearch(e, setSearchQuery, setCurrentPage)
              }
            />
            <span className="icon-container">
              <i className="icon svg-icon icon-search"></i>
            </span>
          </InputGroup>
        </div>
      </div>

          <CardsWithTable
            data={patientData}
            tableTitle="Patient Details"
            tableFields={[
              "OP ID",
              "Name",
              "Mobile",
              "Date Of Birth",
              "Gender",
              "Address",
              "Email",
              "Department",
              "Doctor",
            ]}
            Arraykeys={[
              "id",
              "name",
              "mobile",
              "dob",
              "gender",
              "address",
              "email",
              "department_name",
              "doctor_name",
            ]}
            viewType="table"
            toURL={"/admin/patient-profile"}
            actions={["edit"]}
            isLoading={patientLoading}
            error={patientError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(
                selectedId,
                setModalEditData,
                setIsOpen,
                patient
              )
            }
            onDeleteClick={onDeleteClick}
            paginationData={patient?.meta}
            setCurrentPage={setCurrentPage}
          />

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Add Patients"
      >
        <AddPatient
          setIsOpen={setIsOpen}
          patientLoading={patientLoading}
          patientError={patientError}
          modalEditData={modalEditData}
          refetch={patientListRefetch}
          patientList={undefined}
          departmentList={departmentList}
          doctorList={doctorList}
          doctorLoading={doctorLoading}
          doctorError={doctorError}
          departmentLoading={departmentLoading}
          departmentError={departmentError}
        />
      </ModalContainer>
    </>
  );
};

export default PatientList;
