const getData = ({ props = null }) => {
    if (typeof window !== "undefined") {
        let token = localStorage.getItem("AUTH_TOKEN");
        return token;
    }

}
const clearData = () => {
    if (typeof window !== "undefined") {
        let token = localStorage.removeItem("AUTH_TOKEN");
        localStorage.removeItem("ROLE");
        return token;
    }

}
export { getData, clearData };