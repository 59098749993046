import { useMemo, useState } from "react";
import { Row, Col, InputGroup, Input, Card, CardBody } from "reactstrap";
import StatusCards from "./StatusCards";
import DashboardWidgetCard from "../../../../Components/CustomElements/Cards/DashboardWidgetCard";
import {
  appointmentsTableHeaders,
  bookingsHeaderData,
} from "./Widgets/WidgetCardMethods";
import { useQuery } from "react-query";
import {
  adminWidgetDataInitializer,
  getDashboardData,
} from "./DashboardMethods";
import RenderPieChart from "../../../../Components/Charts/PieChart";

const AdminDashboard = () => {
  const [tabFilter, setTabFilter] = useState("week");
  const { data: dashboardData }: any = useQuery(
    ["dashboardData"],
    () => getDashboardData(),
    { staleTime: 20000, keepPreviousData: true }
  );
  const widgetsData = useMemo(
    () => adminWidgetDataInitializer(dashboardData),
    [dashboardData]
  );

  return (
    <div className="dashboard-wrapper">
      {/* welcome wrap */}
      <section className="mb-4">
        <Row className="gy-4 welcome-wrap">
          <Col lg={9} md={8} sm={12}>
            <h5>Welcome</h5>
            <small>Sabitha Eye Hospital Admin Dashboard</small>
          </Col>
          {/* search column */}
        </Row>
      </section>

      {/* cards */}
      <StatusCards dashboardData={dashboardData} />

      {/* widgets */}
      <section>
        <Row className="gy-4">
          {/* today's appointments */}
          <DashboardWidgetCard
            subtitle="Today's"
            title="Appointments"
            headerData={appointmentsTableHeaders}
            tableData={widgetsData?.appointmentsTableData}
            lg={12}
            md={12}
            sm={12}
            url={"/admin/appointment"}
          />

          {/* upcoming appointments */}
          <DashboardWidgetCard
            subtitle="Upcoming"
            title="Bookings"
            headerData={bookingsHeaderData}
            tableData={widgetsData?.upcoming_appointments}
            lg={8}
            md={12}
            sm={12}
            url={"/admin/booking"}
          />

          {/* Gender Analytics */}
          <Col lg={4} md={12} sm={12}>
            <Card className="widget-card pie-chart-card">
              <CardBody className="widget-inner-wrap">
                <div className="widget-head-outer">
                  <div className="widget-heading">
                    <h6>Gender Analytics</h6>
                  </div>
                </div>
                <div className="pie-chart">
                  <RenderPieChart dashboardData={widgetsData?.pie_chart} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default AdminDashboard;
