import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import { AddPrescriptionvalidationSchema } from "./Prescription/PrescriptionMethods/FormikValidation";
import { submitPrescriptionClickHandler } from "./Prescription/PrescriptionMethods/AddPrescriptionMethods";
import TextError from "../../../../Components/FormikError/TextError";

export default function Prescriptionadd(appoinemnetID: any) {
  return (
    <>
      <Col xxl={12}>
        <Card className="widget-card text-editor-card">
          <CardBody>
            <div className="widget-head-outer">
              <div className="widget-heading">
                <small>Add</small>
                <h6>Prescription</h6>
              </div>
              <div className="icon-container">
                <a href="#">
                  <i className="icon xl icon-options"></i>
                </a>
              </div>
            </div>

            <div className="prescription-form-wrap">
              <Formik
                initialValues={{
                  prescription: [{ medicine: "", dosage: "", duration: "" }],
                }}
                validationSchema={AddPrescriptionvalidationSchema}
                onSubmit={(values, actions) => {
                  // Handle form submission
                  submitPrescriptionClickHandler(
                    values,
                    actions,
                    appoinemnetID
                  );
                }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <FieldArray name="prescription">
                      {({ push, remove }) => (
                        <div>
                          {values?.prescription.map((prescription, index) => (
                            <div key={index} className="form-wrap">
                              <div className="input-wrap">
                                <Field
                                  name={`prescription.${index}.medicine`}
                                  type="text"
                                  placeholder="Medicine"
                                  as={Input}
                                />
                                <ErrorMessage
                                  name={`prescription.${index}.medicine`}
                                  component={TextError}
                                />
                              </div>
                              <div className="input-wrap">
                                <Field
                                  name={`prescription.${index}.dosage`}
                                  type="text"
                                  placeholder="Dosage"
                                  as={Input}
                                />
                                <ErrorMessage
                                  name={`prescription.${index}.dosage`}
                                  component={TextError}
                                />
                              </div>
                              <div className="input-wrap">
                                <Field
                                  name={`prescription.${index}.duration`}
                                  type="text"
                                  placeholder="Duration"
                                  as={Input}
                                />
                                <ErrorMessage
                                  name={`prescription.${index}.duration`}
                                  component={TextError}
                                />
                              </div>
                              <Button
                                type="button"
                                onClick={() => remove(index)}
                                color="danger"
                                outline
                                className="sm prescription-delete-btn"
                                title="Remove"
                              >
                                <i className="icon icon-close-red xl"></i>
                              </Button>

                              {/* <Button
                                color="gray-60"
                                className="md add-btn"
                                outline
                              >
                                <i className="icon icon-plus-gray xl"></i>
                              </Button> */}
                            </div>
                          ))}
                          <Button
                            type="button"
                            onClick={() =>
                              push({ medicine: "", dosage: "", duration: "" })
                            }
                            color="default"
                            outline
                            className="sm prescription-add-btn"
                            title="Add"
                          >
                            <i className="icon icon-plus-squared xl"></i>
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    <div className="button-flex-end-wrap">
                      <Button color="secondary" className="sm" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
