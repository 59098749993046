import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";

// Department delete
export const deleteClickHandler = (selectedId: any, refetch: Function) => {
  api
    .delete(`/feedback/${selectedId?.id}`, {}, true)
    .then(async function ([success, response]: any) {
      toast.success("Deleted");
      refetch();
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const submitClickHandler = (selectedId: any, refetch: Function) => {
  api
    .put(
      `/feedback/${selectedId?.id}`,
      {
        status: selectedId?.status,
      },
      true
    )
    .then(async function ([success, response]: any) {
      toast.success("Published");
      refetch();
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const submitRestoreClickHandler = (
  selectedId: any,
  refetch: Function
) => {
  api
    .put(`/feedbacks/${selectedId?.id}/restore`, {}, true)
    .then(async function ([success, response]: any) {
      toast.success("Restored");
      refetch();
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const handleAdminFeedbackSearch = (e: any, setSearchQuery: Function, navigate: Function, tab: string | undefined, setSearchText: Function) => {
  setSearchText(e?.target?.value)
  if (e?.target?.value?.length >= 1) {
    navigate(`/admin/feedback/${tab}/1`)
    setSearchQuery(e?.target?.value)
  }
  else {
    setSearchQuery('')
  }
}