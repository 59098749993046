import * as Yup from "yup";
import {
  emailSchema,
  phoneSchema,
} from "../../../../../../Utils/Formik/FormikCommonValidationSchemas";

export const StaffValidationSchema = Yup.object().shape({
  name: Yup.string().required("Full name is required"),
  mobile: phoneSchema.required("Phone number is required"),
  email: emailSchema.required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.date().required("Date of Birth is required"),
  joinedAt: Yup.date().required("Date of Joined is required"),
  departmentId: Yup.string().required("Department is required").nullable(),
  designationId: Yup.string().required("Designation is required").nullable(),
});

export const AddStaffInitialValues = {
  name: "",
  mobile: "",
  email: "",
  gender: "",
  dob: "",
  joinedAt: "",
  departmentId: "",
  designationId: "",
};
