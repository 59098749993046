import React, { useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import CustomSpinner from '../../../../../../Components/CustomElements/Spinner/SpinnerCustom';
import { DoctorProfileTileType } from '../../../../../../Type';
import ModalContainer from '../../../../../../Components/Containers/ModalContainer/ModalContainer';
import AddAwards from '../Awards/AddAwards';
import { handleAwardAdd } from './DoctorProfileTileMethods';
import Awards from '../Awards';
import AddDoctor from '../../AddDoctor';
import { useQuery } from 'react-query';
import { getDepartmentFullList, getDesignationFullList } from '../../../../../../Api/AdminApis';
import TimeCalender from './TimeCalender';

const DoctorProfileTile = ({ doctorProfile, doctorProfileLoading, refetch }: DoctorProfileTileType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditOpen] = useState<boolean>(false);

  // Department List
  const { data: departmentList, isLoading: departmentLoading }: any = useQuery('department', () =>
    getDepartmentFullList(),
    { staleTime: 15000 }
  );

  // Designation List
  const {
    data: designationList,
    error: designationError,
    isLoading: designationLoading,
  }: any = useQuery('designation', () => getDesignationFullList(), { staleTime: 15000 });

  return (
    <>
      {doctorProfileLoading ?
        <span>
          <CustomSpinner />
        </span>
        :
        <>
          <Row className='gy-4'>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <Row className='gy-3 align-items-center'>
                  <Col lg={4} md={4} sm={4}>
                    <Card className="doctor-profile-thumbnail-wrap">
                      <img src={doctorProfile?.image_url} className="thumbnail-sm-cover" alt="Doctor" />
                    </Card>
                  </Col>
                  <Col lg={8} md={8} sm={8}>
                    <div className="main-details-wrap">
                      <div className="name-designation-wrap">
                        {doctorProfile?.user?.name && <h4>Dr. {doctorProfile?.user?.name}</h4>}
                        <p>
                          {doctorProfile?.department?.name}
                          <span className="divider"></span>
                          {doctorProfile?.experienceYears && <> |{doctorProfile?.experienceYears} years of experience</>}
                        </p>
                      </div>
                      <div className="social-media-icons-wrap">
                        {doctorProfile?.facebook && (
                          <a href={doctorProfile?.facebook} target="__blank">
                            <i className="icon icon-facebook-2 xl"></i>
                          </a>
                        )}
                        {doctorProfile?.twitter && (
                          <a href={doctorProfile?.twitter} target="__blank">
                            <i className="icon icon-x xl"></i>
                          </a>
                        )}
                        {doctorProfile?.linkedin && (
                          <a href={doctorProfile?.linkedin} target="__blank">
                            <i className="icon icon-linkedin-2 xl"></i>
                          </a>
                        )}
                      </div>

                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12}>
                    <div className="description">
                      <p className="doctor-info">{doctorProfile?.description}</p>
                    </div>
                  </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                <div className="edit-btn right">
                        <span onClick={() => setIsEditOpen(true)}>
                          <i className="icon xl icon-edit"></i>
                        </span>
                      </div>
                  <TimeCalender doctor={doctorProfile?.id} />
                </Col>
              </Row>
            </Col>
            
            <Col lg={12}>
              <div className="awards-about-wrap">
                <h6>Awards & Achievements</h6>
                <div className="awards-grid">
                  {doctorProfile?.awards?.map((aw: any, i: number) => (
                    <Awards key={aw?.id} obj={aw} doctorProfile={doctorProfile} refetch={refetch} setIsModalOpen={setIsModalOpen} />
                  ))}
                  <div className="thumb add-awards-btn">
                    <span onClick={() => handleAwardAdd(setIsModalOpen)}>
                      <img src="/images/empty-img.webp" alt="Certificate" />
                      <p>Add Awards</p>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* Add awards */}
          <ModalContainer isOpen={isModalOpen} setIsopen={setIsModalOpen} title="Add Awards & Achievements">
            <AddAwards docId={doctorProfile?.id} refetch={refetch} setIsModalOpen={setIsModalOpen} />
          </ModalContainer>
          {/* Edit profile */}
          <ModalContainer isOpen={isEditModalOpen} setIsopen={setIsEditOpen} title="Edit Profile" className='add-doctor-modal'>
            <AddDoctor
              setIsOpen={setIsEditOpen}
              departmentList={departmentList}
              departmentLoading={departmentLoading}
              designationList={designationList}
              designationLoading={designationLoading}
              designationError={designationError}
              modalEditId={doctorProfile?.id}
              refetch={refetch}
            />
          </ModalContainer>
        </>
      }
    </>
  );
};

export default DoctorProfileTile;
