import React from 'react'
import CardContainer from '../../../../../Components/Containers/Dashboard/CardContainer'
import { statistics } from './CardMethods'
import DashboardStatusCard from '../../../../../Components/CustomElements/Cards/DashboardStatuscard'

const StatusCards = ({ statistics }: any) => {
    return (
      <CardContainer>
        {statistics?.map((el: any, i: number) => (
          <DashboardStatusCard
            key={i}
            title={el?.title}
            count={el?.count}
            unit={el?.unit}
            icon={el?.icon}
            className={el?.className}
          />
        ))}
      </CardContainer>
    );
  };
  
  export default StatusCards;