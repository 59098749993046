import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { useQuery } from "react-query";
import {
  getDepartmentFullList,
  getDesignationFullList,
  getStaff,
} from "../../../../Api/AdminApis";
import AddStaff from "./AddStaff";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddStaff/AddStaffMethods/AddStaffModalMethods";
import { useNavigate, useParams } from "react-router-dom";
import { handleAdminStaffSearch } from "./StaffMethods";

const StaffIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal
  const [searchQuery, setSearchQuery] = useState<string>('')

  const {
    data: staff,
    error: staffError,
    isLoading: staffLoading,
    refetch: staffListRefetch,
  }: any = useQuery(
    [`staff${currentPage + searchQuery}`, currentPage, searchQuery],
    () => getStaff(currentPage, searchQuery),
    { keepPreviousData: true }
  );

  const staffData = useMemo(() => extractStaffData(staff), [staff]);

  // Department List
  const {
    data: departmentList,
    error: departmentError,
    isLoading: departmentLoading,
  }: any = useQuery("department", () => getDepartmentFullList(), {
    staleTime: 20000,
  });

  // Designation List
  const {
    data: designationList,
    error: designationError,
    isLoading: designationLoading,
  }: any = useQuery("designation", () => getDesignationFullList(), {
    staleTime: 20000,
  });

  return (
    <section className="team">
      <div className="team-home-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input
                  placeholder="Search here..."
                  onChange={(e) => handleAdminStaffSearch(e, setSearchQuery, navigate)}
                />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="secondary"
                    className="sm"
                    outline
                  >
                    <i className="icon icon-plus xl"></i>Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="team-list-outer-wrap">
          <CardsWithTable
            data={staffData}
            tableTitle="Staff Details"
            tableFields={[
              "ID",
              "Name",
              "Mobile",
              "Gender",
              "Email",
              "Department",
              "Designation",
              "Date Of Birth",
              "Joining Date",
            ]}
            Arraykeys={["id", "name", "mobile", "gender", "email", 'department', 'designation', 'dob', "joinedAt"]}
            viewType="table"
            toURL={""}
            actions={["edit", "delete"]}
            isLoading={staffLoading}
            error={staffError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(selectedId, setModalEditData, setIsOpen, staff)
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, staffListRefetch)
            }
            paginationData={staff?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, pageNo)
            }
          />
        </div>
      </div>

      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="Add Staff">
        <AddStaff
          setIsOpen={setIsOpen}
          departmentList={departmentList}
          departmentLoading={departmentLoading}
          departmentError={departmentError}
          designationList={designationList}
          designationLoading={designationLoading}
          designationError={designationError}
          modalEditData={modalEditData}
          refetch={staffListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default StaffIndex;
