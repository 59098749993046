import React from 'react'
import { Container, Row } from 'reactstrap'
import { childrenType } from '../../../Type'

const CardContainer = ({ children }: childrenType) => {
    // Dashboard card container for showing data like total patients 

    return (
        <section className="mb-4">
            <Container>
                <Row className="cards-container gradient-card-wrap">
                    {children}
                </Row>
            </Container>
        </section>
    )
}

export default CardContainer