import { ErrorMessage, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { DoctorGeneralInfoValidationSchema, GeneralInfoInitialValues } from '../AddDoctor/AddDoctorMethods/FormikValidation'
import { DoctorSubmitHandler } from '../AddDoctor/AddDoctorMethods/AddDoctorModalMethods'
import { Row, Col, Input, Form, Label } from 'reactstrap'
import TextError from '../../../../../Components/FormikError/TextError'
import { gender_data } from '../../../../../Utils/Constants/selection_options'
import Select from "react-select"
import CustomButton from '../../../../../Components/CustomElements/FormElements/CustomButton'
import { generalInfoEditInitializer, handleImageChange } from './AddDoctorStepsMethods'
import { GeneralInfoType } from '../../../../../Type'

const GeneralInfo: React.FC<GeneralInfoType> = (
    {
        designationList,
        designationLoading,
        departmentList,
        departmentLoading,
        refetch,
        setAddedDocId,
        setActiveTab,
        addedDocId,
        modalEditData,
    }) => {

    const [addModalInitialValues, setAddModalInitialValues] = useState(GeneralInfoInitialValues)
    const [file, setFile] = useState<any>(null)

    useEffect(() => {
        generalInfoEditInitializer(setAddModalInitialValues, modalEditData, GeneralInfoInitialValues)
    }, [modalEditData])

    return (
        <Formik
            validationSchema={DoctorGeneralInfoValidationSchema}
            initialValues={addModalInitialValues}
            enableReinitialize
            onSubmit={(values, actions) => {
                DoctorSubmitHandler(values, actions, refetch, setAddedDocId, setActiveTab, addedDocId, file)
            }}
        >
            {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values,
                setFieldValue,
            }) => {

                return (
                    <>
                        <div className="general-info-wrap ">
                            <Form className="form-wrap" onSubmit={handleSubmit}>
                            <div className="scroller">
                            <div className="profile-image-wrap">
                                <div className="image-wrap sm">
                                    <img src={file ? URL?.createObjectURL?.(file) : modalEditData?.image_url || '/images/empty-avatar.webp'} alt="profile-img" />
                                </div>
                                <Input
                                    hidden
                                    type="file"
                                    name="file"
                                    accept="image/*"
                                    title="Upload the Image"
                                    multiple
                                    onChange={(e) => handleImageChange(e, setFieldValue, setFile)}
                                    id="fileInput"
                                >
                                </Input>
                                <label htmlFor="fileInput" className="update-btn">
                                    Choose Image
                                </label>

                                <ErrorMessage name="image_name" component={TextError} />
                            </div>
                                <div className="form-scroll-wrap">
                                    <Row className="gy-3">
                                        <Col lg={6}>
                                            <Label>Full name</Label>
                                            <Input type="text" name="name" value={values?.name} onChange={handleChange} placeholder="Full Name"></Input>
                                            <ErrorMessage name="name" component={TextError} />
                                        </Col>
                                        <Col lg={6}>
                                            <Label>Years of experience</Label>
                                            <Input type="text" value={values?.experience_years} name='experience_years' onChange={handleChange} placeholder="Years of Experience"></Input>
                                            <ErrorMessage name="experience_years" component={TextError} />

                                        </Col>
                                        <Col lg={6}>
                                            <Label>Phone number</Label>
                                            <Input type='tel' value={values?.mobile} onChange={handleChange} name='mobile' placeholder='Phone Number' />
                                            <ErrorMessage name="mobile" component={TextError} />
                                        </Col>
                                        <Col lg={6}>
                                            <Label>Email</Label>
                                            <Input type='text' value={values?.email} onChange={handleChange} name='email' placeholder='Email' />
                                            <ErrorMessage name="email" component={TextError} />
                                        </Col>
                                        <Col lg={6}>
                                            <Label>Gender</Label>
                                            <Input
                                                id="exampleSelect"
                                                name="gender"
                                                type="select"
                                                onChange={handleChange}
                                                value={values?.gender}
                                            >
                                                <option >Select Gender</option>
                                                {gender_data?.map((gen: any, i: number) => <option value={gen?.value} key={i}>{gen?.label}</option>)}
                                            </Input>
                                            <ErrorMessage name="gender" component={TextError} />
                                        </Col>
                                        <Col lg={6}>
                                            <Label>Date of joining</Label>
                                            <Input type='date' value={values?.joined_at} onChange={handleChange} title='Date of joining' name='joined_at' placeholder='Date of joining' />
                                            <ErrorMessage name="joined_at" component={TextError} />
                                        </Col>
                                        <Col lg={6}>
                                            <Label>Date of birth</Label>
                                            <Input type='date' value={values?.dob} onChange={handleChange} name='dob' title='Date of birth' placeholder='Date of birth' />
                                            <ErrorMessage name="dob" component={TextError} />
                                        </Col>
                                        <Col lg={6}>
                                            <Label>Department</Label>
                                            <Select
                                                placeholder="Select Department"
                                                name="department_id"
                                                options={departmentList}
                                                value={departmentList?.filter((itm: any) => itm?.value === values?.department_id) || ''}
                                                onChange={(e: any) => setFieldValue("department_id", e?.value)}
                                                isLoading={departmentLoading}
                                                loadingMessage={() => "Loading"}
                                                noOptionsMessage={() => "Departments appears here"}
                                                isSearchable
                                            />
                                            <ErrorMessage name="department_id" component={TextError} />
                                        </Col>
                                        <Col lg={12}>
                                            <Label>Designation</Label>
                                            <Select
                                                id="exampleSelect"
                                                name="designation_id"
                                                options={designationList}
                                                value={designationList?.filter((itm: any) => itm?.value === values?.designation_id) || ''}
                                                onChange={(e: any) => setFieldValue("designation_id", e?.value)}
                                                isLoading={designationLoading}
                                                loadingMessage={() => "Loading"}
                                                noOptionsMessage={() => "Designations appears here"}
                                                isSearchable
                                            />
                                            <ErrorMessage name="designation_id" component={TextError} />
                                        </Col>
                                        <Col lg={12}>
                                            <Label>Description</Label>
                                            <Input type="textarea" placeholder="Description" value={values?.description} name='description' onChange={handleChange}></Input>
                                            <ErrorMessage name="description" component={TextError} />
                                        </Col>
                                    </Row>
                                </div>
                                </div>
                                <Row >
                                    <Col lg={12}>
                                        <div className="submit-btn-wrap">
                                            <CustomButton color="secondary" isLoading={isSubmitting} className="sm submit-btn" label='Next' />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </>)
            }}
        </Formik>
    )
}

export default GeneralInfo