import { Button, Card, CardBody, Col, Row, Form, Input } from "reactstrap";
import { useState } from 'react';
import { handleChangePassword } from './ChangePasswordMethods'

export default function ChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [error, setError] = useState('');

  return (
    <>
      <Card className="widget-card settings-card">
        <CardBody className="widget-inner-wrap">
          <div className="widget-head-outer">
            <div className="widget-heading">
              <h6>Change Password</h6>
            </div>
          </div>
          <div className="content-wrap">
            <Row>
              <Col md={12}>
                <div className="form-wrap">
                  <Form>
                    <Row className="gy-3">
                    <Col xl={12}>
                        <Input
                          type="password"
                          placeholder="Old Password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </Col>
                      <Col xl={12}>
                        <Input
                          type="password"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Col>
                      
                      <Col xl={12}>
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmedPassword}
                          onChange={(e) => setConfirmedPassword(e.target.value)}
                        />
                      </Col>
                      <Col xl={12}>
                        <div className="button-wrap end">
                          <Button
                            color="secondary"
                            className="sm"
                            onClick={() => handleChangePassword(newPassword, oldPassword, confirmedPassword, setError)}
                          >
                            Change Password
                          </Button>

                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
                {error && <small className=" change-password-warning end"> <i className="icon icon-danger-validation xl"></i> {error}</small>}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
