/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useQuery } from "react-query";
import { InputGroup, Input, Nav, NavItem, NavLink } from "reactstrap";
import TableData from "../../../../Components/Containers/TableData";
import { setCurrentPageStaff } from "../../Admin/Staff/AddStaff/AddStaffMethods/AddStaffModalMethods";
import { useLocation, useNavigate } from "react-router-dom";
import { getDashboardAppoinment } from "../../../../Api/AdminApis";

export default function DashboardAppoinmentTab() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const appoinment: any = searchParams.get("appoinment");
  const [tab, setTab] = useState(appoinment === "today" ? 2 : 1);
  const [tab_name, setTabName] = useState("");

  const {
    data: adminAppointmentList,
    isLoading: isAdminAppointmentLoading,
    error: adminAppointmentError,
  }: any = useQuery(
    [`Appointment`, tab],
    () =>
      getDashboardAppoinment(
        tab === 1
          ? "upcoming"
          : tab === 2
          ? "today"
          : tab === 3
          ? "previous"
          : appoinment
      ),
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <section className="team">
        <div className="team-home-outer-wrap">
          <div className="search-bar-full-wrap">
            <div className="top-widget-tabs">
              <span>
                Dashboard / {tab_name ? tab_name : "Today Appointments"}{" "}
              </span>
              <Nav pills>
                <NavItem>
                  <NavLink
                    className={`tab-button ${tab === 1 ? "active" : ""}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTab(1);
                      setTabName(" Upcoming Appoinment");
                    }}
                  >
                    Upcoming Appointments
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`tab-button ${tab === 2 ? "active" : ""}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTab(2);
                      setTabName("Today Appointments");
                    }}
                  >
                    Today Appointments
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`tab-button ${tab === 3 ? "active" : ""}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTab(3);
                      setTabName("Previous Appointments");
                    }}
                  >
                    Previous Appointments
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="search-bar">
              <InputGroup>
                <Input placeholder="Search here..." />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
          </div>
          <div className="team-list-outer-wrap">
            <TableData
              data={adminAppointmentList?.data}
              tableTitle={`${tab_name}`}
              tableFields={["Date & Time", "Name", "Gender", "Age", "Mobile"]}
              Arraykeys={["date_time", "name", "gender", "dob", "mobile"]}
              actions={["edit", "delete"]}
              isLoading={isAdminAppointmentLoading}
              error={adminAppointmentError}
              paginationData={adminAppointmentList?.meta}
              setCurrentPage={(pageNo: string) =>
                setCurrentPageStaff(navigate, pageNo)
              }
              tab={tab}
            />
          </div>
        </div>
      </section>
    </>
  );
}
