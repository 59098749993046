import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";

export async function getDoctorProfile(id: string | undefined) {
    if (id) {
        const [, data] = await api.get(`/doctor/${id}`, true);
        return data?.data
    }
}

export const handleDoctorFetchError = (error: any, navigate: Function) => {
    toast?.error(error?.response.data.message)
    navigate('/admin/doctors/table')
}
