/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import { convertISODateToDateTime } from "../../../../Utils/helpers/helpers";

export default function DiseaseHitory({ diseaseData }: any) {
  return (
    <>
      <Col xxl={12}>
        <Card className="widget-card patient-details-full-wrap">
          <CardBody>
            <div className="widget-head-outer">
              <div className="widget-heading">
                <small>Previous</small>
                <h6>Disease History</h6>
              </div>
              <div className="icon-container">
                <a href="#">
                  <i className="icon xl icon-options"></i>
                </a>
              </div>
            </div>
            <div className="disease-card-outer">
              {diseaseData?.message ? (
                "No data"
              ) : (
                <>
                  {diseaseData?.data?.map((itm: any) => {
                    return (
                      <>
                        <Card className="disease-card purple">
                          <CardBody className="disease-card-flex-wrap">
                            <div className="icon-wrap">
                              <i className="icon xl icon-doctor-white"></i>
                            </div>
                            <div className="disease-card-details">
                              <p>
                                {itm?.name}                              
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
