import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Input,
  Card,
  CardBody,
} from "reactstrap";

import DashboardWidgetCard from "../../../../Components/CustomElements/Cards/DashboardWidgetCard";
import { DoctorAppoinments } from "../../Doctor/Dashboard/Widgets/WidgetCardMethods";
import {
  doctorappointmentsTableHeaders,
  doctorupcomingappointmentsTableHeaders,
  tableDataResture,
} from "../../Admin/Dashboard/Widgets/WidgetCardMethods";
import RenderPieChart from "../../../../Components/Charts/PieChart";
import RenderBarChart from "../../../../Components/Charts/BarChart";
import { useQuery } from "react-query";
import { getDoctorDashboard } from "../../../../Api/AdminApis";
import StatusCards from "./StatusCards";
import { nFormatter } from "../../../../Utils/helpers/helpers";
import { useState } from "react";
import TableData from "../../../../Components/Containers/TableData";

const DoctorDashboard = () => {
  const [tabfilter, setTabFilter] = useState("week");
  const { data: dashboardData }: any = useQuery(
    [`dashboard${tabfilter}`, tabfilter],
    () => getDoctorDashboard(tabfilter),
    {
      staleTime: 20000,
    }
  );

  const statistics = [
    {
      title: "Today's Appointments",
      className: "gradient-card",
      icon: <i className="icon dashboard-icon icon-appointments-card"></i>,
      count: nFormatter(dashboardData?.today_appointment_count),
    },

    {
      title: "Total Appointments",
      className: "gradient-card",
      icon: <i className="icon dashboard-icon icon-new-patients"></i>,
      count: nFormatter(dashboardData?.total_appointment_count),
    },

    {
      title: "Upcoming Bookings",
      className: "gradient-card",
      icon: <i className="icon dashboard-icon icon-appointments-card"></i>,
      count: nFormatter(dashboardData?.upcoming_booking_count),
    },
  ];


  return (
    <div className="main">
      {/* welcome wrap */}
      <section className="mb-4">
        <Row className="gy-4 welcome-wrap">
          <Col lg={9} md={8} sm={12}>
            <h5>Welcome</h5>
            <small>Sabitha Eye Hospital Admin Dashboard</small>
          </Col>

          <Col lg={3} md={4} sm={12}>
            <div className="search-bar">
              <InputGroup>
                <Input placeholder="Search here..." />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </section>

      {/* cards */}
      <StatusCards statistics={statistics} />

      {/* widgets */}
      <section>
        <Row className="gy-4">
          {/* today's appointments */}
          <DashboardWidgetCard
            subtitle="Today's"
            title="Appointments"
            headerData={doctorappointmentsTableHeaders}
            tableData={tableDataResture(
              dashboardData?.today_appointment_list
            )}
            count={nFormatter(dashboardData?.today_appointment_count)}
            lg={12}
            md={12}
            sm={12}
            url={"/doctor/dashboard/appointment?appoinment=today"}
          />

          {/* upcoming appointments */}
          <DashboardWidgetCard
            subtitle="Upcoming"
            title="Appointments"
            headerData={doctorupcomingappointmentsTableHeaders}
            tableData={DoctorAppoinments(dashboardData?.upcoming_booking)}
            count={nFormatter(dashboardData?.upcoming_booking_count)}
            lg={12}
            md={12}
            sm={12}
            url={"/doctor/dashboard/appointment?appoinment=upcoming"}
          />

          {/* Gender Analytics */}

          {/* Patient Statistics */}
          <Col lg={8} md={12} sm={12}>
            <Card className="widget-card">
              <CardBody className="widget-inner-wrap">
                <div className="widget-head-outer">
                  <div className="widget-heading">
                    <h6>Overall Appointment</h6>
                  </div>
                  <div className="button-container">

                    <div>
                      <Button
                        outline
                        onClick={() => setTabFilter("week")}
                        className={`btn-primary`}
                        active={tabfilter === "week"}
                      >
                        Weekly
                      </Button>
                    </div>
                    <div>
                      <Button
                        outline
                        onClick={() => setTabFilter("month")}
                        className={`btn-primary`}
                        active={tabfilter === "month"}
                      >
                        Monthly
                      </Button>
                    </div>
                    <div>
                      <Button
                        outline
                        onClick={() => setTabFilter("year")}
                        active={tabfilter === "year"}
                      >
                        Yearly
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="bar-chart">
                  <RenderBarChart
                    height={345}
                    width={1000}
                    data={dashboardData?.bar_graph}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={8} sm={12}>
            <Card className="widget-card">
              <CardBody className="widget-inner-wrap">
                <div className="widget-head-outer">
                  <div className="widget-heading">
                    <h6>Gender Analytics </h6>
                  </div>
                </div>

                <div className="pie-chart">
                  <RenderPieChart dashboardData={dashboardData?.pie_chart} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default DoctorDashboard;
