import { ErrorMessage, Formik } from 'formik'
import { useState, useEffect } from 'react'
import { Col, Form, Input, Row } from 'reactstrap'
import CustomButton from '../../../../../../Components/CustomElements/FormElements/CustomButton'
import TextError from '../../../../../../Components/FormikError/TextError'
import { AddExperienceInitialValues, AddExperienceValidationSchema } from './AddExperienceFormikMethods'
import { ExperienceModalInitialValuesHandler, ExperienceSubmitHandler } from './AddExperienceMethods'
import { AddExperienceType } from '../../../../../../Type'

const AddExperience = ({ setIsOpen, doctor_id, refetch, data }: AddExperienceType) => {
  const [editModalInitialValues, setEditModalInitialValues] = useState({ doctor_id: doctor_id, ...AddExperienceInitialValues })
  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    ExperienceModalInitialValuesHandler(data, setEditModalInitialValues, setEditMode, doctor_id)
  }, [data])

  return (
    <Formik
      validationSchema={AddExperienceValidationSchema}
      initialValues={editModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        ExperienceSubmitHandler(values, actions, setIsOpen, refetch, editMode, data?.id)
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values
      }) => {

        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className='gy-4'>
                <Col xxl={12}>
                  <Input type='text' name='institution' onChange={handleChange} value={values?.institution} placeholder='Institution' />
                  <ErrorMessage name="institution" component={TextError} />
                </Col>
                <Col xxl={12}>
                  <Input type='date' className='form-control' name='fromdate' value={values?.fromdate} placeholderText='From Date' onChange={handleChange} />
                  <ErrorMessage name="fromdate" component={TextError} />
                </Col>
                <Col xxl={12}>
                  <Input type='date' className='form-control' name='todate' value={values?.todate} placeholderText='To Date' onChange={handleChange} />
                  <ErrorMessage name="todate" component={TextError} />
                </Col>
                <Col xxl={12}>
                  <CustomButton label='Submit' isLoading={isSubmitting} color='secondary' className='sm submit-btn' />
                </Col>
              </Row>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default AddExperience