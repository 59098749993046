import React from "react";
import { Col, List } from "reactstrap";
import { Link } from "react-router-dom";
import { SidebarMenuItemType, StaffSidebarType } from "../../../Type";
import { StaffSidebarMenuItems } from "../../../Utils/Constants/Sidebar/StaffSidebarData";
import { activateSidebarTab } from "../../../Utils/helpers/ActivateSidebarTabs";

const StaffSidebar = ({isOpen}:StaffSidebarType) => {
  const pathname = window.location.pathname;
  return (
    <Col sm={3} md={3} lg={2} className={`fixed ${isOpen ? 'hamburger-open' : 'hamburger-closed'}`}>
      <div className="admin-sidebar">
        <div className="sidebar-item">
          <div className="site-logo">
          {isOpen ? <img src="/images/logo-eye.svg" alt="Site Logo" /> : <img src="/images/logo-blue.svg" alt="Site Logo" /> } 
          </div>
          {/* Sidebar links */}
          <List type="unstyled">
            {StaffSidebarMenuItems?.map(
              (element: SidebarMenuItemType, i: number) => {
                return (
                  <li
                    key={element?.label}
                    className={activateSidebarTab(element, pathname)}>
                    <Link to={element?.path} title={element?.label}>
                      {element?.icon}
                      <span className="link-label">{element?.label}</span>
                    </Link>
                  </li>
                );
              }
            )}
          </List>
        </div>
      </div>
    </Col>
  );
};
export default StaffSidebar;
