import * as Yup from "yup";
import {
  emailSchema,
  phoneSchema,
} from "../../../../../../Utils/Formik/FormikCommonValidationSchemas";

export const PatientValidationSchema = Yup.object().shape({
  name: Yup.string().required("Patient is required"),
});

export const ForgotValidationSchema = Yup.object().shape({
  name: Yup.string().required("Full name is required"),
  dob: Yup.string().required("Enter the dob"),
  mobile: phoneSchema.required("Phone number is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string().required("Address is required"),
  email: Yup.string().email().required("Email is invalid"),
  departmentId: Yup.string().required("Department is required").nullable(),
  doctorId: Yup.string().required("Designation is required").nullable(),
});

export const AddPatientInitialValues = {
  name: "",
  dob: "",
  mobile: "",
  gender: "",
  address: "",
  email: "",
  appoinment: "",
  departmentId: "",
  doctorId: "",
};
