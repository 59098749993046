import FullCalendar from "../../../../Components/FullCalendar";
import { useQuery } from "react-query";
import { getDoctorAppoinments } from "../../../../Api/DoctorApis";
import moment from "moment";
import { doctor_appinment_Data } from "../../../../Components/FullCalendar/FullCalendarMethods";
import { useState } from "react";
import { submitEventDropClickHandler } from "./Prescription/PrescriptionMethods/AddPrescriptionMethods";

const DoctorAppointmentIndex = () => {

  const handleDateClick = (e: any) => {
    // navigate("/");
  };
  const [viewDates, setViewDates] = useState({ start: "", end: "" });

  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  // Department List
  const {
    data: appoinmentList,
    error: appoinmentError,
    isLoading: appoinmentLoading,
    refetch: appoinmentRefetch,
  }: any = useQuery(
    "doctor_appoinment",
    () =>
      getDoctorAppoinments(
        moment(firstDay).format("YYYY-MM-DD"),
        moment(lastDay).format("YYYY-MM-DD")
      ),
    {
      staleTime: 20000,
    }
  );

  return (
    <div className="table-outer-wrap">
      <div className="table-wrap appointment-table-wrap">
        <FullCalendar
          urlTo={`/doctor/patient-prescription`}
          onDateClick={handleDateClick}
          setViewDates={setViewDates}
          data={doctor_appinment_Data(appoinmentList?.data)}
          appoinmentRefetch={appoinmentRefetch}
          submitEventDropClickHandler={submitEventDropClickHandler}
          refetch={appoinmentRefetch}
        />
      </div>
    </div>
  );
};

export default DoctorAppointmentIndex;
