import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage, Formik } from "formik";
import { Col, Card, CardBody, Button, Form } from "reactstrap";
import TextError from "../../../../Components/FormikError/TextError";
import {
  AddNoteValidationSchema,
  AddNoteinitialValues,
} from "./Prescription/PrescriptionMethods/FormikValidation";
import { submitClickHandler } from "./Prescription/PrescriptionMethods/AddPrescriptionMethods";

export default function PrescriptionNoteadd(props: any) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isNotesEditorDisabled, setIsNotesEditorDisabled] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
    setIsNotesEditorDisabled(isEditMode);
  };

  return (
    <>
      <Col xxl={12}>
        <Card className="widget-card text-editor-card">
          <CardBody>
            <div className="widget-head-outer">
              <div className="widget-heading">
                <small>Add</small>
                <h6>Notes</h6>
              </div>
              <div className="icon-container">
                <a href="#">
                  <i className="icon xl icon-options"></i>
                </a>
              </div>
            </div>
            <Formik
              validationSchema={AddNoteValidationSchema}
              initialValues={AddNoteinitialValues}
              enableReinitialize
              onSubmit={(values, actions) => {
                setIsNotesEditorDisabled(true); // Disable the notes editor when "Ok" is clicked
                submitClickHandler(
                  values,
                  actions,
                  props,
                  isEditMode,
                  props?.noteReftech,
                  props?.notes?.notes?.id
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values,
                setFieldValue,
                setFieldError,
                errors,
              }) => {
                return (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <div className="rict-text-editor-wrap">
                        <Editor
                          apiKey="4uglneyo7vlhlcq286kp5smo685svv51llytjx7u0wrx1kzz"
                          value={values?.note || props?.notes?.notes}
                          textareaName="note"
                          init={{
                            height: 300,
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                          }}
                          onEditorChange={(e: any) => setFieldValue("note", e)}
                          disabled={isNotesEditorDisabled} // Disable the editor when notes editor is disabled
                        />
                        <ErrorMessage name="note" component={TextError} />
                      </div>
                      <div className="button-flex-between-wrap">
                        <Button
                          color="secondary"
                          className="sm"
                          onClick={handleEditClick}
                          disabled={!isNotesEditorDisabled}
                        >
                          Edit
                        </Button>

                        <Button type="submit" color="secondary" className="sm">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
