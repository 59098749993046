import React, { useState } from "react";
import { useQuery } from "react-query";
import Slider from "react-slick";
import { Container, Button, CardHeader, Card, CardBody } from "reactstrap";
import { getSlots } from "../../../../../../Api/AdminApis";
import moment from "moment";
import { shift_time } from "../../../../../../Utils/helpers/helpers";
import CustomSpinner from "../../../../../../Components/CustomElements/Spinner/SpinnerCustom"

interface TimeCalenderProps {
  doctor: string;
}

const TimeCalender: React.FC<TimeCalenderProps> = ({ doctor }) => {
  // Fetch doctor's slots
  const { data: doctorSlot, isLoading: doctorSlotLoading } = useQuery(["DoctorSlots", doctor], () =>
    getSlots(doctor)
  );

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  // Check if doctorSlot is defined, then group it by week_day
  const groupedByDay = doctorSlot
    ? doctorSlot.reduce((result: any, item: any) => {
      const { week_day, ...rest } = item;
      if (!result[week_day]) {
        result[week_day] = [];
      }
      result[week_day]?.push(rest);
      return result;
    }, {})
    : {};

  const groupedArray = Object.entries(groupedByDay).map(([key, value]) => ({
    week_day: parseInt(key, 10),
    data: value,
  }));

  const getCurrentDay = () => {
    return moment().isoWeekday(); // 1 for Monday, 2 for Tuesday, etc.
  };

  const currentDay = getCurrentDay();
  const currentDate = moment().format("MMMM D, YYYY");

  const filteredTimeSlots: any[] = groupedArray
    ?.filter((slot: any) => slot?.week_day >= currentDay)
    .map((slot: any) => slot.data) // Extract data from the grouped array
    .flat();

  // Define time slot boundaries for morning, afternoon, and evening
  const morningStart = moment("06:00:00", "HH:mm:ss");
  const afternoonStart = moment("12:00:00", "HH:mm:ss");
  const eveningStart = moment("16:00:00", "HH:mm:ss");

  // Filter slots based on time of day
  const morningSlots = filteredTimeSlots
    .filter((slot: any) =>
      moment(slot?.time_start, "HH:mm:ss").isBefore(afternoonStart)
    );
  const afternoonSlots = filteredTimeSlots
    .filter((slot: any) =>
      moment(slot?.time_start, "HH:mm:ss").isBetween(afternoonStart, eveningStart)
    );
  const eveningSlots = filteredTimeSlots
    .filter((slot: any) =>
      moment(slot?.time_start, "HH:mm:ss").isAfter(eveningStart)
    );

  return (
    <>
      <Card className="clinic-appointment-wrap  variant ">
        
        <div className="slider-wrap row">
          {/* <Slider>
            {filteredTimeSlots.map((item: any) => (
              <Button
                color="transparent"
                className="availability-head md"
                key={item.id}
              >
                <h6 className="day">{currentDate}</h6>
              </Button>
            ))}
          </Slider> */}
          <Slider> <Button color="transparent" className="availability-head md"><h6 className="day">{currentDate}</h6></Button></Slider>

        </div>
        {doctorSlotLoading ? (<div><CustomSpinner /></div>) : (
          <CardBody>
            <div className="availability-buttons-full-wrap">
              <div className="availability-inner">
                <div className="item">
                
                <div className="flex-wrap">
                  {morningSlots.map((items: any) => (
                    <><Button
                      color="none"
                      tag={'a'}
                      outline
                      className="sm"
                      onClick={() => {
                        setSelectedTime(items?.time_start);
                      }}
                    >
                      {moment(items?.time_start, "HH:mm:ss").format("hh:mm A")}
                    </Button>
                      {/* <Button
                    color="primary-100"
                    outline
                    className="sm active px-3"
                    onClick={() => {
                      setSelectedTime(items?.time_end);
                    }}
                  >
                      {moment(items?.time_end, "HH:mm:ss").format("hh:mm A")}
                    </Button> */}
                    </>
                  ))}
                </div>
                <h6>Morning slots ({morningSlots.length})</h6>
                </div>
              </div>
              <div className="availability-inner">
                <div className="item">
                  
                
                <div className="flex-wrap">
                  {afternoonSlots.map((items: any) => (
                    <><Button
                      color="none"
                      tag={'a'}
                      className="sm"
                      onClick={() => {
                        setSelectedTime(items?.time_start);
                      }}
                    >
                      {moment(items?.time_start, "HH:mm:ss").format("hh:mm A")}
                    </Button>
                      {/* <Button
                    color="primary-100"
                    outline
                    className="sm active px-3"
                    onClick={() => {
                      setSelectedTime(items?.time_end);
                    }}
                  >
                      {moment(items?.time_end, "HH:mm:ss").format("hh:mm A")}
                    </Button> */}
                    </>
                  ))}
                </div>
                <h6>Afternoon slots ({afternoonSlots.length})</h6>
                </div>
              </div>
              <div className="availability-inner">
                <div className="item">
                 
                
                <div className="flex-wrap">
                  {eveningSlots.map((items: any) => (
                    <><Button
                      color="none"
                      tag={'a'}
                      outline
                      className="sm"
                      onClick={() => {
                        setSelectedTime(items?.time_start);
                      }}
                    >
                      {moment(items?.time_start, "HH:mm:ss").format("hh:mm A")}
                    </Button>
                      {/* <Button
                    color="primary-100"
                    outline
                    className="sm active px-3"
                    onClick={() => {
                      setSelectedTime(items?.time_end);
                    }}
                  >
                      {moment(items?.time_end, "HH:mm:ss").format("hh:mm A")}
                    </Button> */}
                    </>
                  ))}
                </div>
                <h6>Evening ({eveningSlots.length})</h6>
                </div>
              </div>
            </div>
            {/* <div className="appointment-btn">
            <Button color="secondary" className="sm">
              Book Appointment
            </Button>
          </div> */}
          </CardBody>)}
      </Card>
    </>
  );
};

export default TimeCalender;
