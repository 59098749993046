import React, { useState } from "react";
import { Badge, Button, Container, Row, Table } from "reactstrap";
import { CustomTableType, TableAppoinmentType } from "../../../Type";
import CustomSpinner from "../../CustomElements/Spinner/SpinnerCustom";
import { Link } from "react-router-dom";
import ModalContainer from "../../Containers/ModalContainer/ModalContainer";
import DeleteAlertModal from "../../Containers/AlertModal/DeleteAlertModal";
import {
  calculate_age,
  capitalizeFirst,
  convertISODateToDateTime,
  getAppointmentStatusStyle,
} from "../../../Utils/helpers/helpers";
import moment from "moment";

const TableData = ({
  data,
  tableFields,
  Arraykeys,
  actions,
  isLoading,
  error,
  url,
  tableTitle,
  tab,
}: TableAppoinmentType) => {
  const [openMoadl, setModal] = useState(false);
  const [delete_id, setDeleteId] = useState();

  return (
    <>
      <Container>
        <Row className="gy-4">
          <div className="table-outer-wrap">
            <div className="table-head-outer">
              <div className="table-heading">
                <h6>{tableTitle || ""}</h6>
              </div>
            </div>

            <div className="table-wrap">
              <Table striped>
                <thead>
                  <tr>
                    {tableFields?.map((itm: any, i: number) => (
                      <th key={i}>{itm}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={tableFields?.length + 1}>
                        <CustomSpinner />
                      </td>
                    </tr>
                  ) : data?.length !== 0 ? (
                    data?.map((itm: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td>
                            {tab === 2
                              ? moment(itm?.date_time).format("hh:mm A")
                              : convertISODateToDateTime(itm?.date_time)}
                          </td>
                          <td>{capitalizeFirst(itm?.name||itm?.patient_name)}</td>
                          <td>{capitalizeFirst(itm?.gender||itm?.patient_gender)}</td>
                          <td>{itm?.dob ? calculate_age(itm?.dob) : "Not available"}</td>
                          <td>{itm?.mobile||itm?.patient_mobile}</td>
                          <td>
                            <div className="action-flex-wrap">
                              <Badge
                                color="primary-100"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                {getAppointmentStatusStyle(itm?.status)}
                              </Badge>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : error ? (
                    <tr>
                      <td className="empty-data" colSpan={8}>Error Occurred</td>
                    </tr>
                  ) : (
                    <td colSpan={8} className="empty-text">
                      <span className="empty-text">No Data</span>
                    </td>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TableData;
