import React from "react";
import { Card, CardBody } from "reactstrap";
import { cardDataType } from "../../../Type";

const DashboardStatusCard = ({ className, title, count, unit, icon }: any) => {
  return (
    <Card className={className}>
      <CardBody>
        <div className="card-details-wrap">
          <p>{title}</p>
          <h4>
            {count}
            {unit || ""}
          </h4>
        </div>
        <div className="icon-container">
          <span>{icon}</span>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardStatusCard;
