import React, { useState } from "react";
import { Card, CardBody, Input, Button } from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ErrorMessage } from "formik";
import TextError from "../../FormikError/TextError";

const PatientProfileCard = ({
  title,
  subtitle,
  children,
  id,
  onclickhandler,
  refetchDiseaseData,
  refetchDocumentData,
}: any) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [name, setDiseaseName] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [nameError, setNameError] = useState("");
  const [fileError, setFileError] = useState("");

  const toggleAddForm = () => {
    setShowAddForm(!showAddForm);
    // Clear errors when the form is toggled
    setNameError("");
    setFileError("");
  };

  const handleDiseaseNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDiseaseName(event.target.value);
  };

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleAddDisease = () => {
    if (title === "Documents") {
      if (selectedFile && fileName.trim() !== "") {
        // Call the onclickhandler for adding a document
        onclickhandler(id, fileName, selectedFile, refetchDocumentData);
        // Clear the file inputs and close the form after adding the document
        setFileName("");
        setSelectedFile(null);
        toggleAddForm();
      } else {
        setFileError("Please provide a file and file name.");
      }
    } else {
      // Handle adding a disease here based on title being 'Disease History'
      if (name.trim() !== "") {
        onclickhandler(id, name, refetchDiseaseData);
        // Clear the name input and close the form after adding the disease
        setDiseaseName("");
        toggleAddForm();
      } else {
        setNameError("Disease name is required.");
      }
    }
  };

  return (
    <Card className="widget-card patient-details-full-wrap">
      <CardBody>
        <div className="widget-head-outer">
          <div className="widget-heading">
            <small>{subtitle}</small>
            <h6>{title}</h6>
          </div>
          <div className="icon-container">
            <Link to="" onClick={toggleAddForm}>
              {showAddForm ? (
                <i className="icon icon-close xl"></i>
              ) : (
                <i className="icon icon-plus-gray xl"></i>
              )}
            </Link>
          </div>
        </div>
        {showAddForm && (
          <div className="add-form">
            {title === "Documents" ? (
              <div>
                <Input
                  type="text"
                  id="fileName"
                  value={fileName}
                  onChange={handleFileNameChange}
                  placeholder="Enter file name"
                />
                <Input
                  type="file"
                  id="file"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileSelect}
                />

                <div className="error-outer">
                  <div className="errors validation-danger">
                    {fileError}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <Input
                  type="text"
                  id="name"
                  value={name}
                  onChange={handleDiseaseNameChange}
                  placeholder="Enter disease name"
                />
                <div className="error-outer">
                  <div className="errors validation-danger">
                    {nameError}
                  </div>
                </div>
              </div>
            )}
            <Button
              color="secondary"
              className="sm mt-2"
              outline
              onClick={handleAddDisease}
            >
              {title === "Documents" ? "Add Document" : "Add Disease"}
            </Button>
          </div>
        )}
        {children}
      </CardBody>
    </Card>
  );
};

export default PatientProfileCard;
