import { Badge, Table } from "reactstrap";
import {
  convertISODateToDateTime,
  getAppointmentStatusStyle,
} from "../../../../../Utils/helpers/helpers";

import CustomSpinner from "../../../../../Components/CustomElements/Spinner/SpinnerCustom";
import { FinishedAppoinmentType } from "../../../../../Type";
import CustomPagination from "../../../../../Components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";

export default function FinishedAppoinment({
  appoinmetError,
  appoinmetData,
  appoinmetRefetch,
  appoinmetLoading,
}: FinishedAppoinmentType) {

  const navigate = useNavigate()

  return (
    <>
      <div className="table-outer-wrap">
        <div className="table-wrap">
          <div className="table-wrap">
            <Table striped>
              <thead>
                <th>Id</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Department</th>
                <th>Doctor</th>
                <th>Date & Time</th>
                <th>Message</th>
              </thead>
              <tbody>
                {appoinmetLoading ? (
                  <tr>
                    <td>
                      <CustomSpinner />
                    </td>
                  </tr>
                ) : appoinmetData?.data?.length !== 0 ? (
                  appoinmetData?.data?.map((itm: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td key={i}>{i + 1}</td>
                        <td>{itm?.patient_name}</td>
                        <td>{itm?.patient_mobile}</td>
                        <td>{itm?.department?.name}</td>
                        <td>{itm?.doctor?.user?.name}</td>
                        <td>{convertISODateToDateTime(itm?.date_time)}</td>
                        <td>{itm?.message}</td>
                        <td>
                          <div className="action-flex-wrap">
                            <Badge
                              color="primary-100"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {getAppointmentStatusStyle(itm?.status)}
                            </Badge>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : appoinmetError ? (
                  <tr>
                    <td colSpan={8}>Error Occurred</td>
                  </tr>
                ) : (
                  <td colSpan={8} className="empty-text">
                    <span className="empty-text">No Data</span>
                  </td>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {appoinmetData?.meta?.total !== 0 && (
          <div className="pagination">
            <CustomPagination
              totalItems={appoinmetData?.meta?.total}
              itemsPerPage={appoinmetData?.meta?.per_page}
              activePage={appoinmetData?.meta?.current_page}
              setCurrentPage={(pageNo: string) => navigate(`/admin/appointment/2/${pageNo}`)}
            />
          </div>
        )}
      </div>
    </>
  );
}
