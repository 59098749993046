import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { Col, Form, Input, Label, Row } from 'reactstrap'
import CustomButton from '../../../../../Components/CustomElements/FormElements/CustomButton'
import TextError from '../../../../../Components/FormikError/TextError'
import { SocialMediaType } from '../../../../../Type'
import { DoctorSubmitHandler } from '../AddDoctor/AddDoctorMethods/AddDoctorModalMethods'
import { AddSocialMediaValidationSchema } from './AddDoctorStepsMethods'

const SocialMedia: React.FC<SocialMediaType> = ({ addedDocId, refetch, setAddedDocId, setActiveTab }) => {
    return (
        <div className="social-media-info-wrap">
            <Formik
                validationSchema={AddSocialMediaValidationSchema}
                initialValues={{ facebook: '', twitter: '', linkedin: '' }}
                enableReinitialize
                onSubmit={(values, actions: any) => {
                    DoctorSubmitHandler(values, actions, refetch, setAddedDocId, setActiveTab, addedDocId)
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    values
                }) => {

                    return (
                        <Form className="form-wrap" onSubmit={handleSubmit}>
                            <div className="form-wrap-scroll">
                                <Row className="gy-3">
                                    <Col lg={12}>
                                        <Label>Facebook</Label>
                                        <Input type="text" name='facebook' value={values?.facebook} onChange={handleChange} placeholder="Facebook Profile Link"></Input>
                                        <ErrorMessage name="facebook" component={TextError} />
                                    </Col>
                                    <Col lg={12}>
                                        <Label>LinkedIn</Label>
                                        <Input type="text" name='linkedin' value={values?.linkedin} onChange={handleChange} placeholder="Linkedin Profile Link"></Input>
                                        <ErrorMessage name="linkedin" component={TextError} />
                                    </Col>
                                    <Col lg={12}>
                                        <Label>Twitter</Label>
                                        <Input type="text" name='twitter' value={values?.twitter} onChange={handleChange} placeholder="Twitter Profile Link"></Input>
                                        <ErrorMessage name="twitter" component={TextError} />
                                    </Col>
                                </Row>
                            </div>
                            <Row className="gy-3">
                                <Col lg={12}>
                                    <div className="submit-btn-wrap">
                                        <CustomButton isLoading={isSubmitting} label='Next' className="sm submit-btn" />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default SocialMedia
