import { viewDatesType } from "../Type";
import { api } from "./Api";

export async function getDoctorAppoinments(
  startDateOfWeek: string,
  endDateOfWeek: string
) {
  const [, data] = await api.get(
    `/appointments/calendar?start=${startDateOfWeek}&end=${endDateOfWeek}
  `,
    true
  );
  return data;
}

export async function getAllAppointments(viewDates: viewDatesType) {
  const [, data] = await api.get(`/appointments?start=${viewDates?.start}&end=${viewDates?.end}`, true)

  const convertedAppointments: any = [];

  data?.data?.map((item: any) => {
    convertedAppointments?.push({
      id: item?.patient_id,
      title: item?.patient_name ? item?.patient_name : "No",
      doctor: item?.doctor?.user?.name,
      start: new Date(item?.date_time),
      appointment_id: item?.id,
      appointment_status: item?.status?.value,
    });
  });

  return convertedAppointments;
}

export async function getPatientDetiles(id: number) {
  const [, data] = await api.get(
    `/patient/${id}
  `,
    true
  );
  return data;
}
