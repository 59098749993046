import {
    Row,
    Col,
    Card,
    CardBody, Table
} from 'reactstrap';
import PatientProfileCardContainer from './PatientProfileCardContainer';
import { useParams } from 'react-router-dom';
import { extractPatientData } from '../Addpatient/AddPatientMethods/AddPatientModalMethods';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { convertISODateToDate, convertISODateToDateTime, validateDatetotime } from '../../../../../Utils/helpers/helpers';
import { PatientProfileAppoinmentDetails, PatientProfileDetails, getDoctorNotes, getPrescription } from '../../../../../Api/AdminApis';
import ConsultationCardContainer from './ConsultationCardContainer';

const PatientProfileDoctor = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [patientData, setPatientData] = useState<any>([]);
    const [appointmentData, SetappointmentData] = useState<any>([]);
    const { id } = useParams()
    const [selectedItem, setSelectedItem] = useState(null);

    const {
        data: patient,
        error: patientError,
        isLoading: patientLoading,
        isFetching: patientIsFetching,
        refetch: patientRefetch
    }: any = useQuery([`PatientProfile`, id], () => PatientProfileDetails(id), {
        onSuccess: (data) => {
            extractPatientData(setPatientData, data);
        }
    })
    //Api for Appoinment and patient Data
    const {
        data: patientlist,
        error: patientlistError,
        isLoading: patientlistLoading,
        isFetching: patientlistIsFetching,
        refetch: patientListRefetch
    }: any = useQuery([`PatientProfileAppoinment`, id], () => PatientProfileAppoinmentDetails(id), {
        onSuccess: (data) => {
            extractPatientData(SetappointmentData, data);
        }
    })

    const appointment_id = patientlist?.data?.[0]?.appointment_id
    return (
        <div className="main">
            {/* Patient Profile */}
            <section>
                <Row>
                    {/* Consultation Timeline */}
                    <Col lg={3} md={3} sm={12}>
                        <Card className='widget-card'>
                            <CardBody>
                                <div className="widget-head-outer">
                                    <div className='widget-heading'>
                                        <small>Consultation</small>
                                        <h6>Timeline</h6>
                                    </div>
                                    <div className="icon-container">
                                        <a href='#'>
                                            <i className="icon xl icon-options"></i>
                                        </a>
                                    </div>
                                </div>

                                <Row className='gy-4'>
                                    <Col lg={12}>
                                        <Card className='timeline-card active' >
                                            {patientlist?.data?.map((item: any, index: any) => (
                                                <a href="#">
                                                    <CardBody className='timeline-flex-wrap'key={index} onClick={() => setSelectedItem(item?.appointment_id)}>
                                                        <div className='timeline-card-details'>
                                                            <small>{item?.doctor_name}</small>
                                                            <small>{convertISODateToDateTime(item?.appointment_date_time)}</small>
                                                        </div>
                                                        <div className='see-more-btn'>
                                                            <i className='icon xl icon-next-arrow'></i>
                                                        </div>
                                                    </CardBody>
                                                </a>
                                            ))}
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} md={5} sm={12}>
                        {patientlist?.data?.[0]?.appointment_id && (<>
                            <ConsultationCardContainer
                                item={selectedItem}
                                appointment_id={patientlist?.data?.[0]?.appointment_id}
                            /></>)}


                    </Col>

                    {/* Patient Profile */}
                    <Col lg={3} md={4} sm={12}>
                        <PatientProfileCardContainer
                            patient={patient} />
                    </Col>


                </Row>
            </section>
        </div>
    )
}

export default PatientProfileDoctor