import { ErrorMessage, Formik } from 'formik'
import React, { useState } from 'react'
import { Col, Form, Input, Label, Row } from 'reactstrap'
import CustomButton from '../../../../../../Components/CustomElements/FormElements/CustomButton'
import TextError from '../../../../../../Components/FormikError/TextError'
import { AddAwardsType } from '../../../../../../Type'
import { AddAwardValidationSchema, addAwardSubmitHandler, handleFileChange } from './AddAwardsMethods'

const AddAwards: React.FC<AddAwardsType> = ({ docId, refetch, setIsModalOpen }) => {
    const [file, setFile] = useState<any>(null);

    return (
        <Formik
            validationSchema={AddAwardValidationSchema}
            initialValues={{ doctor_id: docId, title: '', image_name: '' }}
            enableReinitialize
            onSubmit={(values, actions) => {
                addAwardSubmitHandler(values, actions, file, refetch, setIsModalOpen)
            }}
        >
            {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values,
                setFieldValue,

            }) => {

                return (
                    <div className="form-wrap">
                        <Form onSubmit={handleSubmit}>
                            <Row className='gy-4'>
                                <Col xxl={12}>
                                    <Label>Title</Label>
                                    <Input type='text' name='title' onChange={handleChange} value={values?.title} placeholder='Title' />
                                    <ErrorMessage name="title" component={TextError} />
                                </Col>
                                <Col xxl={12}>
                                    <Label>Image</Label>
                                    <Input
                                        type="file"
                                        name="image_name"
                                        onChange={(e: any) => {
                                            handleFileChange(e, setFile)
                                            setFieldValue('image_name', 'name')
                                        }}
                                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                    />
                                    <ErrorMessage name="image_name" component={TextError} />
                                </Col>
                                <Col xxl={12}>
                                    <CustomButton label='Submit' isLoading={isSubmitting} color='secondary' className='sm submit-btn' />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )
            }}
        </Formik>
    )
}

export default AddAwards
