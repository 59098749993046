import { Button, Col, Row } from "reactstrap";

const DeleteAlertModal = (props: any) => {
  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <Col lg={12}>
            <div className="modal-warning-text-wrap">
              <div className="warning-img-wrap">
                <img src="/images/delete-warning.svg" alt="warning-icon" />
              </div>
              <h5 className="modal-warning-title">Are you sure ?</h5>
              <small className="sub-text">
                Do you really want to{" "}
                {props?.delete_id?.type === "Restore"
                  ? "Restore"
                  : props?.delete_id?.type === "Publish"
                  ? "Publish"
                  : props?.delete_id?.type === "UnPublish"
                  ? "UnPublish"
                  : props?.status === "2"
                  ? "Active the meta"
                  : props?.status === "1"
                  ? "Inactive the meta"
                  : "Delete"}
                .
              </small>
            </div>
          </Col>

          <Col lg={12}>
            <div className="submit-btn-wrap centered">
              <Button
                color={"secondary"}
                outline
                className="sm"
                onClick={() => props?.setModal(false)}
              >
                Cancel
              </Button>

              {props?.delete_id?.type === "Restore" ? (
                <Button
                  color={"danger"}
                  className="sm"
                  onClick={() => {
                    props?.onRestoreClick(props?.delete_id);
                    props?.setModal(false);
                  }}
                >
                  Restore
                </Button>
              ) : props?.delete_id?.type === "Publish" ? (
                <Button
                  color={"success"}
                  className="sm"
                  onClick={() => {
                    props?.onSubmitClick(props?.delete_id);
                    props?.setModal(false);
                  }}
                >
                  Publish
                </Button>
              ) : props?.delete_id?.type === "UnPublish" ? (
                <Button
                  color={"success"}
                  className="sm"
                  onClick={() => {
                    props?.onSubmitClick(props?.delete_id);
                    props?.setModal(false);
                  }}
                >
                  Unpublish
                </Button>
              ) : props?.status === "2" ? (
                <Button
                  color={"success"}
                  className="sm"
                  onClick={() => {
                    props?.metaStatusClick(
                      props?.delete_id,
                      props?.status,
                      props?.Refetch,
                      props?.setModal
                    );
                    props?.setModal(false);
                  }}
                >
                  Active meta
                </Button>
              ) : props?.status === "1" ? (
                <Button
                  color={"success"}
                  className="sm"
                  onClick={() => {
                    props?.metaStatusClick(
                      props?.delete_id,
                      props?.status,
                      props?.Refetch,
                      props?.setModal
                    );
                    props?.setModal(false);
                  }}
                >
                  Inactive meta
                </Button>
              ) : (
                <Button
                  color={"danger"}
                  className="sm"
                  onClick={() => {
                    props?.onDeleteClick(props?.delete_id);
                    props?.setModal(false);
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DeleteAlertModal;
