import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import { getPatientDocuments } from "../../../../Api/AdminApis";

export default function PrescriptionDocument({
  patientID,
  appoinemnetID,
  documentList,
}: any) {
  return (
    <>
      <Col xxl={12}>
        <Card className="widget-card patient-details-full-wrap">
          <CardBody>
            <div className="widget-head-outer">
              <div className="widget-heading">
                <small>Previous</small>
                <h6>Documents</h6>
              </div>
              <div className="icon-container">
                <a href="#">
                  <i className="icon xl icon-options"></i>
                </a>
              </div>
            </div>
            {documentList?.message ? (
              "No Documents found"
            ) : (
              <>
              <div className="disease-card-outer">
                {documentList?.data?.map((item: any) => {
                  return (
                    <>
                      
                        <Card className="disease-card no-bg">
                          <CardBody className="disease-card-flex-wrap">
                            <div className="icon-wrap">
                              <a href={item?.file_path} download target="blank">
                                <i className="icon xl icon-documents"></i>
                              </a>
                            </div>
                            <div className="disease-card-details">
                              <p className='doc-name'> 
                                {item?.file_name}
                                <br />
                                <span className="date-time">
                                  {item?.created_at}
                                </span>
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                    
                    </>
                  );
                })}
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
