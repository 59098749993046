import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { modalPropType } from '../../../Type'

const ModalContainer = ({ isOpen, setIsopen, title = '', children, className }: modalPropType) => {

    return (
        <div>
            <Modal
                isOpen={isOpen}
                scrollable={true}
                centered={true}
                toggle={() => setIsopen(false)}
                className={`custom-modal ${className}`}
            >
                <ModalHeader toggle={() => setIsopen(false)}>{title}</ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
            </Modal>

        </div>
    )
}

export default ModalContainer