import { Row } from "reactstrap";
import AwardList from "./Awards";
import BannerList from "./Banner";
import ServiceList from "./Services";
import Testimonials from "./Testimonials";

const WebsiteSettings = () => {
  return (
    <div className="mywebsite-settings-wrap-outer">
      <div className="settings-wrap-inner">
        <Row className="gy-4">
          <BannerList />
          <ServiceList />
          <AwardList />
          <Testimonials />
        </Row>
      </div>
    </div>
  );
};

export default WebsiteSettings;
