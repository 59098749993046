import { useState } from "react";
import { Collapse, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ModalContainer from "../../Containers/ModalContainer/ModalContainer";
import AlertModal from "../../Containers/AlertModal/AlertModal";
import { CurrentTab, capitalizeWord } from "../../../Utils/helpers/helpers";

const Header = ({ ROLE, toggleMenu }: any) => {
  // Notifications
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  // Messages
  const [isOpen2, setIsOpen2] = useState(false);
  const toggle2 = () => setIsOpen2(!isOpen2);

  // Profile
  const [isOpen3, setIsOpen3] = useState(false);
  const toggle3 = () => setIsOpen3(!isOpen3);
  const [isOpenLogout, setIsOpenLogout] = useState(false);

  const pathname: any = window.location.pathname;

  return (
    <>
      <header className="header-wrap">
        <Row className="gx-0">
          <div className="header-wrap-inner">
            <Col lg={12} md={12} sm={12}>
              <div className="header-full-wrap">
                <div className="header-left-wrap">
                  <span onClick={toggleMenu}>
                    <i className="icon svg-icon icon-menu"></i>
                  </span>
                  <h4>{CurrentTab(pathname)}</h4>
                </div>

                <div className="header-right-wrap">
                  {/* notifications */}
                  {/* <div className="notifications-wrap">
                    <Link
                      to="#"
                      onClick={toggle}
                      className="header-icon-container"
                    >
                      <i className="icon svg-icon icon-notification"></i>
                      <span>5</span>
                    </Link>
                    <Collapse
                      isOpen={isOpen}
                      className="notifications-collapse"
                    >
                      <Card className="widget-card notification-card">
                        <CardBody className="notification-list-full-wrap">
                          <div className="notification-inner-wrap">
                            <div className="widget-head-outer">
                              <div className="widget-heading">
                                <small>Recent</small>
                                <h6>Notifications</h6>
                              </div>
                              <div className="icon-container">
                                <Link to="#">
                                  <i className="icon xl icon-options"></i>
                                </Link>
                              </div>
                            </div>
                            <div className="notifications-list-wrap">
                              <Link to="#" className="flex-item">
                                <div className="thumbnail-wrap">
                                  <img
                                    src="/images/team4.webp"
                                    alt="thumbnail"
                                    className="default-thumb"
                                  />
                                </div>
                                <div className="details-wrap">
                                  <h6 className="heading">
                                    Dr. Sredha requested leave for 3 days
                                  </h6>
                                  <small className="date">
                                    26/June/2016,{" "}
                                    <span className="time">4:34 PM</span>
                                  </small>
                                </div>
                              </Link>
                              <hr />
                              <Link to="#" className="flex-item">
                                <div className="thumbnail-wrap">
                                  <img
                                    src="/images/team4.webp"
                                    alt="thumbnail"
                                    className="default-thumb"
                                  />
                                </div>
                                <div className="details-wrap">
                                  <h6 className="heading">
                                    Dr. Sredha requested leave for 3 days
                                  </h6>
                                  <small className="date">
                                    26/June/2016,{" "}
                                    <span className="time">4:34 PM</span>
                                  </small>
                                </div>
                              </Link>
                              <hr />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div> */}

                  {/* Messages */}
                  {/* <div className="notifications-wrap">
                    <Link
                      to="#"
                      onClick={toggle2}
                      className="header-icon-container"
                    >
                      <i className="icon svg-icon icon-messages"></i>
                      <span>5</span>
                    </Link>
                    <Collapse
                      isOpen={isOpen2}
                      className="notifications-collapse"
                    >
                      <Card className="widget-card notification-card">
                        <CardBody className="notification-list-full-wrap messages-list-wrap">
                          <div className="notification-inner-wrap">
                            <div className="widget-head-outer">
                              <div className="widget-heading">
                                <h6>Chat</h6>
                              </div>
                              <div className="icon-container">
                                <Link to="#">
                                  <i className="icon xl icon-plus-gray"></i>
                                </Link>
                              </div>
                            </div>
                            <div className="notifications-list-wrap">
                              <Link to="#" className="flex-item-outer">
                                <div className="flex-item">
                                  <div className="thumbnail-wrap online">
                                    <img
                                      src="/images/team4.webp"
                                      alt="thumbnail"
                                      className="rounded-image"
                                    />
                                  </div>
                                  <div className="details-wrap">
                                    <h6 className="heading">Dr. Sredha</h6>
                                    <small className="date">
                                      Ok, thank you have a good day
                                    </small>
                                  </div>
                                </div>
                                <span className="count">2</span>
                              </Link>
                              <hr />
                              <Link to="#" className="flex-item-outer">
                                <div className="flex-item">
                                  <div className="thumbnail-wrap online">
                                    <img
                                      src="/images/team4.webp"
                                      alt="thumbnail"
                                      className="rounded-image"
                                    />
                                  </div>
                                  <div className="details-wrap">
                                    <h6 className="heading">Sidharth</h6>
                                    <small className="date">Hai</small>
                                  </div>
                                </div>
                                <span className="count">1</span>
                              </Link>
                              <hr />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div> */}

                  {/* Profile */}
                  <div className="notifications-wrap">
                    <Link
                      to="#"
                      onClick={toggle3}
                      className="admin-profile-outer"
                    >
                      <img src="/images/avatar.png" alt="avatar-img" />
                      <small className="admin-name">
                        Hello {capitalizeWord(ROLE)}
                      </small>
                    </Link>
                    <Collapse isOpen={isOpen3} className="profile-collapse">
                      <Card className="widget-card notification-card">
                        <CardBody className="notification-list-full-wrap user-settings-list-wrap">
                          <div className="notification-inner-wrap">
                            <ul className="menu-list-wrap">
                              <li>
                                <Link
                                  to={`/${ROLE}/settings`}
                                  onClick={toggle3}
                                >
                                  <i className="icon icon-settings-2 xl"></i>
                                  Settings
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="#"
                                  onClick={() => setIsOpenLogout(true)}
                                >
                                  <i className="icon icon-logout xl"></i>Log out
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </header>

      <ModalContainer
        isOpen={isOpenLogout}
        setIsopen={setIsOpenLogout}
        title=""
      >
        <AlertModal setIsOpenLogout={setIsOpenLogout} />
      </ModalContainer>
    </>
  );
};

export default Header;
