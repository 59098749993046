import { useEffect, useState } from 'react'
import { ErrorMessage, Formik } from 'formik'
import { Col, Form, Input, Row } from 'reactstrap'
import { AddSkillValidationSchema } from './AddSkillValidationSchema'
import TextError from '../../../../../../Components/FormikError/TextError'
import { SkillSubmitHandler, editModalInitialValuesHandler } from './AddSkillMethods'
import CustomButton from '../../../../../../Components/CustomElements/FormElements/CustomButton'
import { AddSkillType } from '../../../../../../Type'

const AddSkill = ({ setIsOpen, doctor_id, refetch, data }: AddSkillType) => {
    const [editModalInitialValues, setEditModalInitialValues] = useState({ skill: '', doctor_id: doctor_id })
    const [editMode, setEditMode] = useState<boolean>(doctor_id !== undefined)

    useEffect(() => {
        editModalInitialValuesHandler(data, setEditModalInitialValues, setEditMode, doctor_id)
    }, [data])

    return (
        <Formik
            validationSchema={AddSkillValidationSchema}
            initialValues={editModalInitialValues}
            enableReinitialize
            onSubmit={(values, actions) => {
                SkillSubmitHandler(values, actions, setIsOpen, refetch, editMode, data?.id)
            }}
        >
            {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values
            }) => {

                return (
                    <div className="form-wrap">
                        <Form onSubmit={handleSubmit}>
                            <Row className='gy-4'>
                                <Col xxl={12}>
                                    <Input type='text' name='skill' onChange={handleChange} value={values?.skill} placeholder='Skill' />
                                    <ErrorMessage name="skill" component={TextError} />
                                </Col>
                                <Col xxl={12}>
                                    <CustomButton label='Submit' isLoading={isSubmitting} color='secondary' className='sm submit-btn' />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )
            }}
        </Formik>
    )
}

export default AddSkill