import { Formik, ErrorMessage } from "formik";
import { Col, Form, Input, Row } from "reactstrap";
import {
  AddPermissionInitialValues,
  PermissionValidationSchema,
} from "./AddPerimissionMethods/FormikValidation";
import { AddPermissionSubmitHandler } from "./AddPerimissionMethods/AddPermissionModalMethods";
import CustomButton from "../../../Components/CustomElements/FormElements/CustomButton";
import TextError from "../../../Components/FormikError/TextError";

const AddPermission = (setIsOpen: any) => {
  return (
    <Formik
      validationSchema={PermissionValidationSchema}
      initialValues={AddPermissionInitialValues}
      onSubmit={(values, actions) => {
        AddPermissionSubmitHandler(
          values,
          actions,
          setIsOpen?.setIsOpen,
          setIsOpen?.fetchData
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldError,
      }) => {
        return (
          <>
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col lg={12}>
                    <Input
                      type="text"
                      onChange={handleChange}
                      value={values?.name}
                      name="name"
                      placeholder="Permission name"
                    />
                    <ErrorMessage name="name" component={TextError} />
                  </Col>

                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="secondary"
                      className="sm submit-btn"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddPermission;
