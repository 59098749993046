import { Form } from "react-router-dom";
import { Card, CardBody, Col, Input, Row } from "reactstrap";

export default function BasicProfile() {
  return (
    <>
      {" "}
      <Col md={6}>
        <Card className="widget-card settings-card">
          <CardBody className="widget-inner-wrap">
            <div className="widget-head-outer">
              <div className="widget-heading">
                <h6>Profile</h6>
              </div>
            </div>
            <div className="content-wrap">
              <Row className="gy-4">
                <Col md={5}>
                  <div className="profile-image-wrap">
                    <div className="image-wrap">
                      <img
                        src="/images/dummyavatar.webp"
                        alt="profile-picture"
                      />
                    </div>
                    <a href="" className="update-btn">
                      Change
                    </a>
                  </div>
                </Col>
                <Col md={7}>
                  <div className="form-wrap">
                    {/* <Form> */}
                      <Row className="gy-3">
                        <Col xl={12}>
                          <Input type="text" placeholder="First Name"></Input>
                        </Col>
                        <Col xl={12}>
                          <Input type="text" placeholder="Last Name"></Input>
                        </Col>
                        <Col xl={12}>
                          <Input type="tel" placeholder="Mobile"></Input>
                        </Col>
                        <Col xl={12}>
                          <Input type="email" placeholder="Email"></Input>
                        </Col>
                      </Row>
                    {/* </Form> */}
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
