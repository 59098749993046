import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import {
  AddDocumentValidationSchema,
  AddDocumentinitialValues,
} from "./Prescription/PrescriptionMethods/FormikValidation";
import { submitDocumentClickHandler } from "./Prescription/PrescriptionMethods/AddPrescriptionMethods";
import TextError from "../../../../Components/FormikError/TextError";

export default function PrescriptionDocumentadd(props: any) {
  return (
    <>
      {" "}
      <Col xxl={12}>
        <Card className="widget-card text-editor-card">
          <CardBody>
            <div className="widget-head-outer">
              <div className="widget-heading">
                <small>Upload</small>
                <h6>Documents</h6>
              </div>
              <div className="icon-container">
                <a href="#">
                  <i className="icon xl icon-options"></i>
                </a>
              </div>
            </div>

            <Formik
              initialValues={AddDocumentinitialValues}
              validationSchema={AddDocumentValidationSchema}
              onSubmit={(values, actions) => {
                // Handle form submission
                submitDocumentClickHandler(values, actions,props?.parts);
              }}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => (
                <div className="prescription-form-wrap add-presc-doc-wrap">
                  <Form onSubmit={handleSubmit}>
                    <div className="form-wrap">
                        <div className="input-wrap">
                        <Input
                        type="text"
                        placeholder="Report name"
                        name="file_name"
                        onChange={handleChange}
                      />
                      <ErrorMessage name="file_name" component={TextError} />
                      </div>
                      <div className="input-wrap">
                        <InputGroup>
                          <Input
                            type="file"
                            placeholder="Upload file"
                            name="document"
                            onChange={(e: any) =>
                              setFieldValue("document", e.target.files[0])
                            }
                          />

                          <div className="upload-btn">
                            <Button color="transparent">
                              <i className="icon icon-upload xl"></i>
                            </Button>
                          </div>
                        </InputGroup>
                        <ErrorMessage name="document" component={TextError} />
                      </div>
                    </div>
                    <div className="button-flex-end-wrap">
                      <Button color="secondary" className="sm" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
