import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { AddDoctorProps } from "../../../../../Type";
import AwardsAndAchievements from "../AddDoctorSteps/AwardsAndAchievements";
import GeneralInfo from "../AddDoctorSteps/GeneralInfo";
import SocialMedia from "../AddDoctorSteps/SocialMedia";
import TimeSlot from "../AddDoctorSteps/TimeSlot";
import { getDoctorProfile } from "../DoctorProfile/DoctorProfileMethods";
import AddEducationTab from "./AddEducationTab";
import AddExperienceTab from "./AddExperienceTab";
import AddSkillTab from "./AddSkillTab";

const AddDoctor = (
    {
        setIsOpen,
        departmentList,
        departmentLoading,
        designationList,
        designationLoading,
        designationError,
        modalEditId,
        refetch,
    }: AddDoctorProps) => {

    const [activeTab, setActiveTab] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7>(1)
    const [addedDocId, setAddedDocId] = useState<string | undefined>(undefined)

    const {
        data: doctorProfile,
        refetch: addedDoctorRefetch
    }: any = useQuery(`doctorProfile${addedDocId}`, () => getDoctorProfile(addedDocId), {
        keepPreviousData: true,
        enabled: !!addedDocId
    })

    useEffect(() => {
        setAddedDocId(modalEditId)
    }, [modalEditId])

    return (
        <>
            {/* New Add Doctor Popup */}
            <div className="add-doctor-wrap">
                <Row className="gy-3">
                    <Col md={4} className="tabs-col-left">
                        <div className="top-widget-tabs vertical">
                            <Nav pills>
                                <NavItem>
                                    <NavLink active={activeTab === 1} onClick={() => setActiveTab(1)}>
                                        General Info
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={addedDocId === undefined} active={activeTab === 2} onClick={() => setActiveTab(2)}>
                                        Education
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={addedDocId === undefined} active={activeTab === 3} onClick={() => setActiveTab(3)}>
                                        Skills
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={addedDocId === undefined} active={activeTab === 4} onClick={() => setActiveTab(4)}>
                                        Experience
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={addedDocId === undefined} active={activeTab === 5} onClick={() => setActiveTab(5)}>
                                        Awards & Achievements
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={addedDocId === undefined} active={activeTab === 6} onClick={() => setActiveTab(6)}>
                                        Social Media
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink disabled={addedDocId === undefined} active={activeTab === 7} onClick={() => setActiveTab(7)}>
                                        Time Slot
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </Col>
                    <Col md={8}>
                        <TabContent activeTab={activeTab}>
                            {/* General Info tab content */}
                            <TabPane tabId={1}>
                                <GeneralInfo
                                    designationList={designationList}
                                    designationLoading={designationLoading}
                                    departmentList={departmentList}
                                    refetch={()=>{
                                        addedDoctorRefetch()
                                        refetch()
                                    }}
                                    setAddedDocId={setAddedDocId}
                                    setActiveTab={setActiveTab}
                                    addedDocId={addedDocId}
                                    modalEditData={doctorProfile}
                                    departmentLoading={departmentLoading}
                                />
                            </TabPane>
                            {/* Education  */}
                            <TabPane tabId={2}>
                                <AddEducationTab
                                    doctor_id={addedDocId}
                                    refetch={addedDoctorRefetch}
                                    data={doctorProfile}
                                    setActiveTab={setActiveTab}
                                />
                            </TabPane>
                            {/* Skill  */}
                            <TabPane tabId={3}>
                                <AddSkillTab
                                    data={doctorProfile}
                                    doctor_id={addedDocId}
                                    refetch={addedDoctorRefetch}
                                    setActiveTab={setActiveTab}
                                />
                            </TabPane>
                            {/* Experinece  */}
                            <TabPane tabId={4}>
                                <AddExperienceTab
                                    doctor_id={addedDocId}
                                    refetch={addedDoctorRefetch}
                                    setIsOpen={() => setActiveTab((prev: any) => prev + 1)}
                                    data={doctorProfile}
                                    setActiveTab={setActiveTab}
                                />
                            </TabPane>
                            {/* Awards Tab content */}
                            <TabPane tabId={5}>
                                <AwardsAndAchievements
                                    data={doctorProfile?.awards}
                                    refetch={addedDoctorRefetch}
                                    addedDocId={addedDocId}
                                    setActiveTab={setActiveTab}
                                />
                            </TabPane>
                            {/* Social Media Tab content */}
                            <TabPane tabId={6}>
                                <SocialMedia
                                    addedDocId={addedDocId}
                                    refetch={refetch}
                                    setAddedDocId={setAddedDocId}
                                    setActiveTab={setActiveTab}
                                />
                            </TabPane>
                            {/* Awards Tab content */}
                            <TabPane tabId={7}>
                                <TimeSlot
                                    addedDocId={addedDocId}
                                    refetch={addedDoctorRefetch}
                                    setIsOpen={setIsOpen}
                                />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        </>

    );
};

export default AddDoctor;
