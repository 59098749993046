import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'reactstrap'
import AddAwards from '../DoctorProfile/Awards/AddAwards';
import ModalContainer from '../../../../../Components/Containers/ModalContainer/ModalContainer';
import { AwardsAndAchievementsType } from '../../../../../Type';

const AwardsAndAchievements: React.FC<AwardsAndAchievementsType> = ({ data, refetch, addedDocId, setActiveTab }) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <>
        <div className="awards-grid">
            <Form className="form-wrap">
                <div className="form-wrap-scroll">
                    <Row className="gy-3">
                        {data?.map((img: any, i: number) => {
                            return (
                                <Col lg={6} key={i}>
                                    <div className='thumb'>
                                        <img src={img?.image_url} alt="Certificate" />
                                    </div>
                                </Col>
                            )
                        })}

                        <Col lg={6}>
                            <div className='thumb add-awards-btn'>
                                <span onClick={() => setIsOpen(true)}><img src="/images/empty-img.webp" alt="Certificate" /></span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col lg={12}>
                        <div className="submit-btn-wrap">
                            <Button color="secondary" className="sm submit-btn" onClick={() => setActiveTab((prev: number) => prev + 1)}>Next</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>

        <ModalContainer isOpen={isOpen} setIsopen={setIsOpen}>
        <AddAwards docId={addedDocId} refetch={refetch} setIsModalOpen={setIsOpen} />
        </ModalContainer>
        </>
    )
}

export default AwardsAndAchievements
