import { Formik, ErrorMessage } from "formik";
import { useState, useEffect } from "react";
import { Input, Form, Label, Table } from "reactstrap";
import TextError from "../../../../../../Components/FormikError/TextError";
import { AddEducationType } from "../../../../../../Type";
import { AddEducationValidationSchema } from "../../DoctorProfile/Education/AddEducationValidationSchema";
import { educationModalInitialValuesHandler, EducationSubmitHandler } from "../../DoctorProfile/Education/EducationMethods";

const AddEducationForm = ({ setIsOpen, doctor_id, refetch, data }: AddEducationType) => {

    const [editModalInitialValues, setEditModalInitialValues] = useState({ doctor_id: doctor_id, qualification: '', institution: '' })
    const [editMode, setEditMode] = useState<boolean>(false)

    useEffect(() => {
        educationModalInitialValuesHandler(data, setEditModalInitialValues, setEditMode, doctor_id)
    }, [data])

    return (
        <Formik
            validationSchema={AddEducationValidationSchema}
            initialValues={editModalInitialValues}
            enableReinitialize
            onSubmit={(values, actions) => {
                EducationSubmitHandler(values, actions, setIsOpen, refetch, editMode, data?.id)
            }}
        >
            {({
                handleSubmit,
                handleChange,
                isSubmitting,
                values,
                errors
            }) => {

                return (
                    <div className="education-wrap time-slot-wrap">
                        <Form className="form-wrap" onSubmit={handleSubmit}>
                            <div className="form-scroll-wrap">
                                <div className="day-time-wrap">
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="input-wrap">
                                                        <Label>Course Type</Label>
                                                        <Input type='text' name='qualification' onChange={handleChange} value={values?.qualification} placeholder='Course Type' />
                                                        <ErrorMessage name="qualification" component={TextError} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="input-wrap">
                                                        <Label>Institution Name</Label>
                                                        <Input type='text' name='institution' onChange={handleChange} value={values?.institution} placeholder='Institution Name' />
                                                        <ErrorMessage name="institution" component={TextError} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="action-flex-status-btn centered">
                                                        <span onClick={() => setIsOpen()}>
                                                            <i className="icon xl icon-close-red"></i>
                                                        </span>
                                                        <span onClick={() => handleSubmit()}>
                                                            <i className="icon xl icon-check-green" ></i>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Form>
                    </div>
                )
            }}
        </Formik>
    )
}

export default AddEducationForm