import { FormikHelpers } from 'formik';
import { api } from '../../../../../../Api/Api';
import { capitalizeWord, setValidationErrors } from '../../../../../../Utils/helpers/helpers';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BaseUrl } from '../../../../../../Api/BaseUrl';

type AddDoctorValues = {
    name: string,
    experience_years: string,
    mobile: string,
    email: string,
    gender: string,
    joined_at: string,
    department_id: string,
    designation_id: string,
    description: string,
    image_name: string,
    dob: string
};

export const doctorTableFields = ['ID', 'Name', 'Mobile', 'Email', 'Gender', 'Designation', 'Joining Date']
export const doctorTableKeys = ['id', 'name', 'mobileNo', 'email', 'gender', 'specialty', 'joiningDate']

export const DoctorSubmitHandler = (
    values: any,
    actions: FormikHelpers<AddDoctorValues>,
    refetch: Function,
    setAddedDocId: Function,
    setActiveTab: Function,
    addedDocId: string | undefined,
    file?: any,
) => {

    let editMode = addedDocId !== undefined

    const formData = new FormData();
    const keys = Object.keys(values);
    for (const key of keys) {
        if (key !== 'image_name') {
            formData.append(key, values?.[key])
        }
    }

    if (file) {
        formData.append('image_name', file)
    }
    else {
        formData?.delete('imagename')
    }

    // Edit 
    if (editMode) {
        formData.append("_method", "put");
        axios({
            method: "post",
            url: `${BaseUrl}/doctors/${addedDocId}`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            },
        })
            .then(function (response) {
                if (response?.status === 200) {
                    actions?.setSubmitting(false)
                    setAddedDocId(response?.data?.doctor_id || addedDocId)
                    toast.success('Doctor Details Updated ')
                    setActiveTab((prev: any) => prev + 1)
                    refetch()
                }
            })
            .catch(function (response) {
                actions?.setSubmitting(false)

                if (response?.response?.data?.errors) {
                    setValidationErrors(response?.response?.data?.errors, actions)
                }
                else {
                    toast.error('Unexpected Error Occurred')
                }
            });
    }
    // Add 
    else {
        axios({
            method: "post",
            url: `${BaseUrl}/doctors`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            },
        })
            .then(function (response) {
                if (response?.status === 200) {
                    actions?.setSubmitting(false)
                    setAddedDocId(response?.data?.doctor_id)
                    setActiveTab(2)
                    toast.success('Doctor Details Added ')
                    refetch()
                }
            })
            .catch(function (response) {
                actions?.setSubmitting(false)
                if (response?.response?.data?.errors) {
                    window.scroll(0, 0)
                    setValidationErrors(response?.response?.data?.errors, actions)
                }
                else {
                    toast.error('Unexpected Error Occurred')
                }
            });
    }
}


// extract doctors data for table
export const extractDoctorsData = (data: any) => {
    const res = data?.data?.map((item: any) => {
        return {
            "id": item?.id,
            "name": item?.user?.name,
            "email": item?.user?.email,
            "mobileNo": item?.user?.mobile,
            "gender": capitalizeWord(item?.user?.gender),
            "specialty": item?.designation?.name,
            "joiningDate": item?.joined_at,
            "image": item?.image_url,
        };
    })
    return res;
}

// doctors table edit onclick trigger 
export const onEditClickHandler = (selectedId: any, setModalEditData: Function, setIsOpen: Function, doctorData: any) => {
    setModalEditData([])
    setModalEditData(doctorData?.data?.filter((itm: any) => itm?.id === selectedId)?.[0])
    setIsOpen(true)
}

// add doctor open / close 
export const modalHandler = (setModalEditData: Function, setIsOpen: Function) => {
    setModalEditData([])
    setIsOpen(true)
}

// Doctor delete 
export const deleteClickHandler = (selectedId: string | number, refetch: Function) => {
    api.delete(`/doctors/${selectedId}`, {}, true)
        .then(async function ([success, response]: any) {
            refetch()
            toast.success('Deleted')
        })
        .catch((err) => {
            toast.error('Unexpected Error Occurred')
        });
}

// opens add education modal 
export const handleAddEdu = (setEditData: Function, setOpenAddEdu: Function) => {
    setEditData([])
    setOpenAddEdu(true)
}

// edit 
export const handleEdit = (ed: any, setEditData: Function, setOpenAddEdu: Function) => {
    setEditData(ed)
    setOpenAddEdu(true)
}
