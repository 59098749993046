
export const handleAdd = (setIsOpen: Function, setSelectedAction: Function, setEditData: Function) => {
    setEditData([])
    setSelectedAction('ADD')
    setIsOpen(true)
}

export const handleEdit = (setIsOpen: Function, setSelectedAction: Function, setEditData: Function, data: any) => {
    setEditData(data)
    setSelectedAction('EDIT')
    setIsOpen(true)
}

export const deleteConfirm = (id: any, setDeleteModal: Function, setDeleteId: Function) => {
    setDeleteModal(true);
    setDeleteId(id);
};
