import React from 'react';

interface DiseasehistoryTileProps {
    id: any;
    name: any;
    refetchDiseaseData: any;
}

interface FormValues {
    updatedName: string;
}

const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.updatedName.trim()) {
        errors.updatedName = "Name cannot be empty.";
    }
    return errors;
};

export type { DiseasehistoryTileProps, FormValues }; 
export { validate }; 
