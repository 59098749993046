import { FormikHelpers } from "formik";

import { toast } from "react-toastify";
import { api } from "../../../../../../../Api/Api";
import { setValidationErrors } from "../../../../../../../Utils/helpers/helpers";
import { AddAwardInitialValues } from "./FormikValidation";
import axios from "axios";
import { BaseUrl } from "../../../../../../../Api/BaseUrl";

type AddInitialValues = {
  image: string;
  description: string;
  title: string;
};

export const AwardSubmitHandler = async (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  refetch: Function,
  editMode: boolean,
  id: string | number,
  Image: string
) => {
  // Edit

  if (editMode) {
    try {
      const formData = new FormData();
      if (Image) {
        formData.append("image", values?.image);
      }
      formData.append("title", values?.title);
      formData.append("description", values?.description);
      formData.append("_method", "put");

      const res = await axios.post(
        `${BaseUrl}/hospitalawards/${id}/update`,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
          },
        }
      );

      if (res) {
        toast.success("Edited");
        setIsOpen(false);
        refetch();
      }
    } catch (error) {
      toast.error("Unexpected Error Occurred");
    }
  } else {
    // add
    try {
      const formData = new FormData();
      formData.append("image", values?.image);
      formData.append("title", values?.title);
      formData.append("description", values?.description);
      const res = await axios.post(`${BaseUrl}/hospitalawards`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
        },
      });
      if (res) {
        toast.success("Document uploaded");
        setIsOpen(false);
        refetch();
      }
    } catch (error) {
      toast.error("Unexpected Error Occurred");
    }
  }
};

// extract Departmentdata for table
export const extractStaffData = (data: any) => {
  const res = data?.data?.map((item: any) => {
    return {
      id: item?.id,
      description: item?.description,
      image: item?.image_url,
      title: item?.title,
    };
  });
  return res;
};

// initializing add Department form - Edit / add
export const addModalInitialValuesHandler = (
  modalEditData: any,
  setAddModalInitialValues: Function,
  setEditMode: Function
) => {
  if (modalEditData?.length !== 0) {
    setEditMode(true);
    setAddModalInitialValues({
      id: modalEditData?.[0]?.id,
      description: modalEditData?.[0]?.description,
      image: modalEditData?.[0]?.image_url,
      title: modalEditData?.[0]?.title,
    });
  } else {
    setEditMode(false);
    setAddModalInitialValues(AddAwardInitialValues);
  }
};

// Department table edit onclick trigger
export const onEditClickHandler = (
  selectedId: any,
  setModalEditData: Function,
  setIsOpen: Function,
  awardData: any
) => {
  setModalEditData([]);
  setModalEditData(
    awardData?.data?.filter((itm: any) => itm?.id === selectedId)
  );

  setIsOpen(true);
};




// add Department open / close
export const modalHandler = (
  setModalEditData: Function,
  setIsOpen: Function
) => {
  setModalEditData([]);
  setIsOpen(true);
};

// Department delete
export const deleteClickHandler = (
  selectedId: string | number,
  refetch: Function
) => {
  api
    .delete(`/hospitalawards/${selectedId}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Deleted");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};

export const StatusClickHandler = (
  selectedId: string | number,
  refetch: Function,
  newStatus: number
) => {
  api
    .put(`/hospitalawards/${selectedId}?status=${newStatus}`, {}, true)
    .then(async function ([success, response]: any) {
      refetch();
      toast.success("Updated");
    })
    .catch((err) => {
      toast.error("Unexpected Error Occurred");
    });
};
export const setCurrentPageaward = (navigate: Function, pageNo: any) => {
  navigate(`/admin/staff/${pageNo}`);
};
