import { useState, useEffect } from 'react'
import { ErrorMessage, Formik } from 'formik'
import { Col, Form, Input, Row } from 'reactstrap'
import { EducationSubmitHandler, educationModalInitialValuesHandler } from './EducationMethods'
import { AddEducationValidationSchema } from './AddEducationValidationSchema'
import TextError from '../../../../../../Components/FormikError/TextError'
import CustomButton from '../../../../../../Components/CustomElements/FormElements/CustomButton'
import { AddEducationType } from '../../../../../../Type'

const AddEducation = ({ setIsOpen, doctor_id, refetch, data }: AddEducationType) => {

  const [editModalInitialValues, setEditModalInitialValues] = useState({ doctor_id: doctor_id, qualification: '', institution: '' })
  const [editMode, setEditMode] = useState<boolean>(false)
 

  useEffect(() => {
    educationModalInitialValuesHandler(data, setEditModalInitialValues, setEditMode, doctor_id)
  }, [data])

  return (
    <Formik
      validationSchema={AddEducationValidationSchema}
      initialValues={editModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        EducationSubmitHandler(values, actions, setIsOpen, refetch, editMode, data?.id)
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        errors
      }) => {

        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className='gy-4'>
                <Col xxl={12}>
                  <Input type='text' name='qualification' onChange={handleChange} value={values?.qualification} placeholder='Qualification' />
                  <ErrorMessage name="qualification" component={TextError} />
                </Col>
                <Col xxl={12}>
                  <Input type='text' name='institution' onChange={handleChange} value={values?.institution} placeholder='Institution' />
                  <ErrorMessage name="institution" component={TextError} />
                </Col>
                <Col xxl={12}>
                  <CustomButton label='Submit' isLoading={isSubmitting} color='secondary' className='sm submit-btn' />
                </Col>
              </Row>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default AddEducation