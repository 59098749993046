
export const handleDashboardDataInitializer = (data: any) => {
  return [
    {
      title: 'Total Patients',
      className: 'gradient-card',
      icon: <i className='icon dashboard-icon icon-staff'></i>,
      count: data?.total_patients || 0,
      unit: ''
    },
    {
      title: 'New Patients',
      className: 'gradient-card',
      icon: <i className='icon dashboard-icon icon-new-patients'></i>,
      count: data?.total_latest_patients || 0
    },
    {
      title: 'Appointments',
      className: 'gradient-card',
      icon: <i className='icon dashboard-icon icon-appointments-card'></i>,
      count: data?.total_confirmed_appointments || 0
    },
    {
      title: 'Doctors',
      className: 'gradient-card',
      icon: <i className='icon dashboard-icon icon-doctor'></i>,
      count: data?.total_doctors || 0
    },
  ]
}