import { useMemo, useState } from "react";
import { Container, Button, InputGroup, Input } from "reactstrap";
import CardsWithTable from "../../../../Components/Containers/CardsWithTable";
import ModalContainer from "../../../../Components/Containers/ModalContainer/ModalContainer";
import { useQuery } from "react-query";
import { getMeta, getStaff } from "../../../../Api/AdminApis";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteClickHandler,
  extractStaffData,
  modalHandler,
  onEditClickHandler,
  setCurrentPageStaff,
} from "./AddMeta/AddMetaMethods/AddMetaModalMethods";
import AddMeta from "./AddMeta";

const MetaIndex = () => {
  const { currentPage = "1" } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // Add doctor modal
  const [modalEditData, setModalEditData] = useState<any>([]); // data for edit modal

  const {
    data: meta,
    error: metaError,
    isLoading: metaLoading,
    refetch: metaListRefetch,
  }: any = useQuery(
    [`meta${currentPage}`, currentPage],
    () => getMeta(currentPage),
    { keepPreviousData: true }
  );

  const staffData = useMemo(() => extractStaffData(meta), [meta]);

  return (
    <section className="team">
      <div className="team-home-outer-wrap">
        <Container>
          <div className="search-bar-full-wrap">
            <div className="search-bar">
              <InputGroup>
                <Input placeholder="Search here..." />
                <span className="icon-container">
                  <i className="icon svg-icon icon-search"></i>
                </span>
              </InputGroup>
            </div>
            <div className="right-side-wrap">
              <div className="filter-wrap">
                <div className="button-wrap">
                  <Button
                    onClick={() => modalHandler(setModalEditData, setIsOpen)}
                    color="secondary"
                    className="sm"
                    outline
                  >
                    <i className="icon icon-plus xl"></i>Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* cards section */}
        <div className="team-list-outer-wrap">
          <CardsWithTable
            data={staffData}
            tableTitle="Meta Details"
            tableFields={["Id", "Title of website", "Description"]}
            Arraykeys={["id", "title", "description"]}
            viewType="table"
            toURL={""}
            actions={["edit", "delete"]}
            isLoading={metaLoading}
            error={metaError}
            onEditClick={(selectedId: any) =>
              onEditClickHandler(selectedId, setModalEditData, setIsOpen, meta)
            }
            onDeleteClick={(selectedId: string | number) =>
              deleteClickHandler(selectedId, metaListRefetch)
            }
            paginationData={meta?.meta}
            setCurrentPage={(pageNo: string) =>
              setCurrentPageStaff(navigate, pageNo)
            }
            meta={true}
            Refetch={metaListRefetch}
          />
        </div>
      </div>

      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="Add Meta">
        <AddMeta
          setIsOpen={setIsOpen}
          modalEditData={modalEditData}
          refetch={metaListRefetch}
        />
      </ModalContainer>
    </section>
  );
};

export default MetaIndex;
