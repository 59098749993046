import { FormikHelpers } from "formik";

import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";

type AddPermissionValues = {
  name: string;
};

export const AddPermissionSubmitHandler = (
  values: AddPermissionValues,
  actions: FormikHelpers<AddPermissionValues>,
  setIsOpen: any,
  fetchData: any
) => {
  api
    .post(
      "/permission",
      {
        ...values,
      },
      true
    )
    .then(async function ([success, response, status]: any) {
      if (response) {
        toast.success(response?.message);
        actions.setSubmitting(false);
        setIsOpen(false);
      } else {
        toast.error(response?.message);
      }
      fetchData();
      return response;
    })
    .catch((err) => {
      if (err?.response?.status === 422) {
        actions.setFieldError("name", err?.response?.data?.message);
        actions.setSubmitting(false);
      }
    });
};

export const AddPermissionEditSubmitHandler = (
  values: AddPermissionValues,
  actions: FormikHelpers<AddPermissionValues>,
  setIsOpen: any,
  fetchData: any
) => {
  api
    .put(
      "/permission",
      {
        ...values,
      },
      true
    )
    .then(async function ([success, response, status]: any) {
      if (response) {
        toast.success(response?.message);
        actions.setSubmitting(false);
        setIsOpen(false);
        fetchData();
      } else {
        toast.error(response?.message);
      }

      return response;
    })
    .catch((err) => {
      if (err?.response?.status === 422) {
        actions.setFieldError("name", err?.response?.data?.message);
        actions.setSubmitting(false);
      }
    });
};

export const AddPermissionDeleteSubmitHandler = (
  values: AddPermissionValues,
  actions: FormikHelpers<AddPermissionValues>,
  setIsOpen: any,
  fetchData: any,
  edit_id: any
) => {
  api
    .delete(
      "/permission",
      {
        ...values,
      },
      true
    )
    .then(async function ([success, response, status]: any) {
      if (response) {
        toast.success(response?.message);
        actions.setSubmitting(false);
        setIsOpen(false);
        fetchData();
      } else {
        toast.error(response?.message);
      }

      return response;
    })
    .catch((err) => {
      if (err?.response?.status === 422) {
        actions.setFieldError("name", err?.response?.data?.message);
        actions.setSubmitting(false);
      }
    });
};
