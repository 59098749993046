import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { ChartStyleType } from "../../Type";

const RenderBarChart: React.FC<ChartStyleType> = (props) => {
  let final_array: any = [];
  props?.data?.map((item: any) => {
    final_array?.push({
      name: item?.day || item?.month || item?.year,
      nameone: item?.day?.slice(0, 3) || item?.month?.slice(0, 3) || item?.year, 
      pv: item?.count,
    });
  });
  const CustomTooltip = ({ active, payload, label }: any) => {
    console.log(payload, "payload");
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{payload[0]?.payload?.name}</p>
          <p className="intro"> {payload[0]?.payload?.pv}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <>
      <BarChart
        width={props?.width}
        height={props?.height}
        data={final_array}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="nameone" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="pv" fill="#CB8EF0" barSize={50} />
      </BarChart>
    </>
  );
};

export default RenderBarChart;
