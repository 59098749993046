import { ToastContainer } from "react-toastify";
import RoutesHandler from "./Pages/Routes/index";
import "react-toastify/dist/ReactToastify.css";
import MetaList from "./Meta/Meta";

const App = () => {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <RoutesHandler /> */}
      {/* After implemention of Meta  Data */}
      <MetaList />
    </>
  );
};

export default App;
