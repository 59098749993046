import { Formik, ErrorMessage } from "formik";
import { Col, Form, Input, Label, Row } from "reactstrap";
import TextError from "../../../../../Components/FormikError/TextError";
import CustomButton from "../../../../../Components/CustomElements/FormElements/CustomButton";
import { useEffect, useState, useRef } from "react";
import { AppointmentProps } from "../../../../../Type";
import Select from "react-select";
import {
  AppointmentSubmitHandler,
  addModalInitialValuesHandler,
} from "./AddBookingMethods/AddBookingMethods";
import {
  AddappointmentInitialValues,
  AppointmentValidationSchema,
} from "./AddBookingMethods/FormikValidations";
import { getDoctorListBooking, getPatientSearchList } from "../../../../../Api/AdminApis";
import { useQuery } from "react-query";
import { gender_data } from "../../../../../Utils/Constants/selection_options";
interface SelectedPatientData {
  email: string;
  dob: string;
  gender: string;
  address: string;
  doctor_name: string;
  department_name: string;
  // Add other properties as needed
}

const Confirmappointment = ({
  setIsOpen,
  appointmentLoading,
  appointmentError,
  // doctorList,
  // doctorLoading,
  departmentList,
  departmentLoading,
  modalEditData,
  refetch,
}: AppointmentProps) => {
  const [addModalInitialValues, setAddModalInitialValues] = useState(
    AddappointmentInitialValues
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedPatientData, setSelectedPatientData] =
    useState<SelectedPatientData | null>(null);
  const [selectedPatientName, setSelectedPatientName] = useState("");

  useEffect(() => {
    addModalInitialValuesHandler(
      modalEditData,
      setAddModalInitialValues,
      setEditMode
    );
  }, [modalEditData]);

  const [phone_serch, setPhoneSerch] = useState("");
  const {
    data: patientdata,
    error: patientError,
    isLoading: patientDataxLoading,
  }: any = useQuery(
    ["doctorfullData", phone_serch],
    () => getPatientSearchList(phone_serch),
    {}
  );
  
 // Doctor List
 const [depart, SetDepart]=useState("")
  const { data: doctorList, isLoading: doctorLoading, refetch: refetchDoctorList }: any = useQuery(
    ["doctorListDocBooking", depart],
    () => getDoctorListBooking(depart),
    { staleTime: 15000 }
    
  );


  const patientNameInputRef = useRef<HTMLInputElement>(null);
  const [showAddPatientInput, setShowAddPatientInput] = useState(false);
  return (
    <Formik
      validationSchema={AppointmentValidationSchema}
      initialValues={addModalInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        AppointmentSubmitHandler(
          values,
          actions,
          setIsOpen,
          refetch,
          editMode,
          modalEditData?.[0]?.id,
          modalEditData
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {

        setPhoneSerch(values?.patient_mobile);
        
        const handleAddNewPatient = () => {
          setShowAddPatientInput(true);
          if (patientNameInputRef.current) {
            const newPatientName = patientNameInputRef.current.value;
            setSelectedPatientName(newPatientName);
            setFieldValue('patient_name', newPatientName);
          }
        };


        return (
          <>
            <div className="form-wrap">
              <Form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col md={6}>
                    <Label>Patient mobile</Label>
                    <Input
                      type="text"
                      onChange={(e: any) => {
                        setFieldValue("patient_mobile", e.target.value);
                        setPhoneSerch(e.target.value);
                      }}
                      name="patient_mobile"
                      value={values?.patient_mobile}
                      placeholder="Phone Number"
                    />
                    <ErrorMessage name="patient_mobile" component={TextError} />
                  </Col>

                  <Col lg={6}>
                    <Label>Patient name</Label>
                    {patientdata && patientdata.length > 0 ? (
                      showAddPatientInput ? (
                        <Input
                          innerRef={patientNameInputRef}
                          type="text"
                          onChange={handleChange}
                          value={values.patient_name}
                          name="patient_name"
                          placeholder="Full Name"
                        />
                      ) : (
                        <Select
                          name="patient_name"
                          options={[
                            ...patientdata.map((patient: any) => ({
                              value: patient?.label,
                              label: patient?.label,
                              data: patient,
                            })),
                            {
                              value: "add_new_patient",
                              label: (
                                <>
                                  <span>Add New Patient</span>
                                  <button
                                    type="button"
                                    onClick={handleAddNewPatient}
                                    style={{
                                      marginLeft: '5px',
                                      padding: '2px 5px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    +
                                  </button>
                                </>
                              ),
                            },
                          ]}
                          value={
                            selectedPatientName
                              ? {
                                value: selectedPatientName,
                                label: selectedPatientName,
                              }
                              : null
                          }
                          onChange={(selectedOption) => {
                            if (selectedOption?.value !== "add_new_patient") {
                              const selectedPatientData =
                                (selectedOption as {
                                  value: string;
                                  label: string;
                                  data: any;
                                })?.data || null;
                              setSelectedPatientData(selectedPatientData);
                              setSelectedPatientName(selectedOption?.value || "");
                              setFieldValue("patient_name", selectedOption?.value || "");
                              setFieldValue("patient_dob", selectedPatientData?.dob || "");
                              setFieldValue("address", selectedPatientData?.address || "");
                            }
                          }}
                        />
                      )
                    ) : (
                      <Input
                        innerRef={patientNameInputRef}
                        type="text"
                        onChange={handleChange}
                        value={values.patient_name}
                        name="patient_name"
                        placeholder="Full Name"
                      />
                    )}
                    <ErrorMessage name="patient_name" component={TextError} />
                  </Col>
                  <Col lg={6}>
                    <Label>Patient date of birth</Label>
                    <Input
                      type="date"
                      onChange={handleChange}
                      value={
                        values?.patient_dob
                          ? values?.patient_dob
                          : selectedPatientData?.dob
                      }
                      name="patient_dob"
                      placeholder="Date of Birth"
                    />
                    <ErrorMessage name="patient_dob" component={TextError} />
                  </Col>

                  <Col lg={6}>
                    <Label>Patient Email</Label>
                    <Input
                      type="email"
                      onChange={handleChange}
                      value={
                        selectedPatientData
                          ? selectedPatientData?.email
                          : values.patient_email
                      }
                      name="patient_email"
                      placeholder="Email"
                    />
                    <ErrorMessage name="patient_email" component={TextError} />
                  </Col>
                  <Col md={6}>
                    <Label>Patient gender</Label>
                    {selectedPatientData ? (
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={selectedPatientData?.gender}
                        name="patient_gender"
                        placeholder="Gender"
                      />
                    ) : (
                      <Select
                        placeholder="Select Gender"
                        name="patient_gender"
                        options={gender_data}
                        value={
                          gender_data?.filter(
                            (itm: any) => itm?.value === values?.patient_gender
                          ) || ""
                        }
                        onChange={(e: any) => {
                          setFieldValue("patient_gender", e?.value);
                        }}
                        isLoading={false}
                        loadingMessage={() => "Fetching Gender"}
                        noOptionsMessage={() => "Gender appears here"}
                        isSearchable={true}
                        isClearable
                      />
                    )}
                    <ErrorMessage name="patient_gender" component={TextError} />
                  </Col>

                  <Col md={6}>
                    <Label>Patient address</Label>
                    <Input
                      type="text"
                      onChange={handleChange}
                      name="address"
                      value={values?.address}
                      placeholder="Patient address"
                    />
                    <ErrorMessage name="address" component={TextError} />
                  </Col>

                  <Col md={6}>
                    <Label>Doctor</Label>
                    {selectedPatientData ? (
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={selectedPatientData.doctor_name}
                        name="doctor"
                        placeholder="Doctor"
                      />
                    ) : (
                      <Select
                        placeholder="Select Doctor"
                        name="doctor"
                        options={doctorList}
                        value={doctorList?.filter(
                          (itm: any) => itm?.value === values?.doctor
                        )}
                        onChange={(e: any) => setFieldValue("doctor", e?.value)}
                        isLoading={doctorLoading}
                        loadingMessage={() => "Loading"}
                        noOptionsMessage={() => "Doctors appear here"}
                        isSearchable
                      />
                    )}
                    <ErrorMessage name="doctor" component={TextError} />
                  </Col>

                  <Col md={6}>
                    <Label>Department</Label>
                    {selectedPatientData ? (
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={selectedPatientData.department_name}
                        name="department_name"
                        placeholder="Department"
                      ></Input>
                    ) : (
                      <Select
                        placeholder="Select Department"
                        name="department_name"
                        options={departmentList}
                        value={departmentList?.filter(
                          (itm: any) => itm?.value === values?.department_name
                        )}
                        onChange={(e: any) =>{
                          setFieldValue("department_name", e?.value);
                          SetDepart(e?.value);
                          refetchDoctorList();
                        }}
                        isLoading={departmentLoading}
                        loadingMessage={() => "Loading"}
                        noOptionsMessage={() => "Departments appears here"}
                        isSearchable
                      />
                    )}
                    <ErrorMessage
                      name="department_name"
                      component={TextError}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Booking date</Label>
                    <Input
                      type="date"
                      onChange={handleChange}
                      name="booking_date"
                      value={values?.booking_date}
                      placeholder="Booking Date"
                    />
                    <ErrorMessage name="booking_date" component={TextError} />
                  </Col>
                  <Col md={6}>
                    <Label>Booking time</Label>
                    <Input
                      type="time"
                      onChange={handleChange}
                      name="booking_time"
                      value={values?.booking_time}
                      placeholder="Booking Time"
                    />
                    <ErrorMessage name="booking_time" component={TextError} />
                  </Col>
                  <Col md={6}>
                    <div className="check-flex-outer">
                      <div className="check-flex-wrap">
                        <Input
                          type="checkbox"
                          onChange={handleChange}
                          name="payment_confirm"
                          value={values?.payment_confirm}
                        />
                        <Label>Payment completed</Label>
                      </div>
                    </div>
                    <ErrorMessage
                      name="payment_confirm"
                      component={TextError}
                    />
                  </Col>
                  <Col lg={12}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Confirm"
                      color="secondary"
                      className="sm submit-btn"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default Confirmappointment;