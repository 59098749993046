import * as Yup from "yup";
import { api } from "../../../Api/Api";
import { setValidationErrors } from "../../../Utils/helpers/helpers";
import { toast } from "react-toastify";

// toggles password visibility 
export const togglePasswordVisibility = (key: string, setState: Function) => {
    setState((prev: any) => {
        return { ...prev, [key]: !(prev?.[key]) }
    })
}

// formik validation schema 
export const ResetPasswordValidationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters long"),
    confirmpassword: Yup.string().oneOf([Yup.ref("password"), ''], "Passwords must match").required('Confirm password is required'),
});

export const handlePasswordReset = (values: any, actions: any, navigate: Function) => {
    api.post("/public/password/confirm", values, false)
        .then(async function ([success, response]: any) {
            actions.setSubmitting(false);
            toast.success('Password changed')
            navigate('/')
        })
        .catch((err) => {
            actions?.setSubmitting(false)
            if (err?.response?.data?.message) {
                toast?.error(err?.response?.data?.message)
                navigate('/')
            }
            else {
                toast.error('Unexpected Error Occurred')
            }
        });
}

export const tokenValidator = async (token: string | undefined) => {
    if (!token) return
    const [, data] = await api.get(`/public/Validtokens/${token}`, false);
    return data
}