import { useState } from 'react'
import { Button, Card, CardBody, CardText, CardTitle, Col, List } from 'reactstrap'
import DeleteAlertModal from '../../../../../../Components/Containers/AlertModal/DeleteAlertModal'
import ModalContainer from '../../../../../../Components/Containers/ModalContainer/ModalContainer'
import CustomSpinner from '../../../../../../Components/CustomElements/Spinner/SpinnerCustom'
import { DetailsTileType } from '../../../../../../Type'
import AddEducation from '../Education/AddEducation'
import { deleteClickHandler } from '../Education/EducationMethods'
import AddExperience from '../Experience/AddExperience'
import AddSkill from '../Skill/AddSkill'
import { deleteConfirm, handleAdd, handleEdit } from './DetailsTimeMethods'

const DetailsTile = ({ data, title = '', type, isLoading, doctor_id, refetch }: DetailsTileType) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedAction, setSelectedAction] = useState<'ADD' | 'EDIT' | ''>('')
    const [openDeleteMoadl, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [editData, setEditData] = useState([]);

    const onDeleteClick = (id: string) => deleteClickHandler(type, id, refetch)

    const ACTIONS_ARRAY: any = {
        SKILL: {
            ADD: <AddSkill setIsOpen={setIsOpen} doctor_id={doctor_id} data={editData} refetch={refetch} />,
            EDIT: <AddSkill setIsOpen={setIsOpen} doctor_id={doctor_id} data={editData} refetch={refetch} />
        },
        EDUCATION: {
            ADD: <AddEducation setIsOpen={setIsOpen} doctor_id={doctor_id} data={editData} refetch={refetch} />,
            EDIT: <AddEducation setIsOpen={setIsOpen} doctor_id={doctor_id} data={editData} refetch={refetch} />
        },
        EXPERIENCE: {
            ADD: <AddExperience setIsOpen={setIsOpen} doctor_id={doctor_id} data={editData} refetch={refetch} />,
            EDIT: <AddExperience setIsOpen={setIsOpen} doctor_id={doctor_id} data={editData} refetch={refetch} />
        }
    }

    return (
        <Col lg={12} className='colored-card-col one'>
            <Card className='colored-card'>
                <CardBody className='colored-card-wrap'>
                    <div className="card-head">
                        <CardTitle tag="h4">
                            {title}
                        </CardTitle>
                        <div className="add-btn">
                            <Button color='secondary' onClick={() => handleAdd(setIsOpen, setSelectedAction, setEditData)} outline className="md"><i className="icon icon-plus xl"></i>Add</Button>
                        </div>
                    </div>
                    <CardText>
                        <List className='doc-list admin-doc-list'>
                            {isLoading ? <CustomSpinner /> : null}
                            {data?.map((itm: any, i: number) => {
                                return (
                                    <li key={i}>
                                        <div className="doc-flex-wrap">
                                            <span className='left-wrap'>
                                                <h6>{itm?.qualification || itm?.skill}</h6>{/* Education & Skill */}
                                                {itm?.institution && <p>{itm?.institution}</p>} {/* Education */}
                                                {itm?.fromdate && <p>{itm?.fromdate?.substring(0, 4)}- {itm?.todate?.substring(0, 4)}</p>} {/* Experience  */}
                                            </span>
                                            <span>
                                                <div className="action-flex-wrap">
                                                    <span onClick={() => handleEdit(setIsOpen, setSelectedAction, setEditData, itm)}><i className='icon icon-edit xl'></i></span>
                                                    {/* <span><i className='icon icon-unview xl'></i></span> */}
                                                    {/* <span onClick={() => deleteClickHandler(type, itm?.id, refetch)}><i className='icon icon-delete xl'></i></span> */}
                                                    <span onClick={() => deleteConfirm(itm?.id, setDeleteModal, setDeleteId)}><i className='icon icon-delete xl'></i></span>
                                                </div>
                                            </span>
                                        </div>
                                    </li>
                                )
                            })}
                        </List>
                    </CardText>
                </CardBody>
            </Card>

            <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title={`Add ${title}`}>
                {ACTIONS_ARRAY[type]?.[selectedAction]}
            </ModalContainer>

            <ModalContainer isOpen={openDeleteMoadl} setIsopen={setDeleteModal} title="">
                <DeleteAlertModal
                    setModal={setDeleteModal}
                    onDeleteClick={onDeleteClick}
                    delete_id={deleteId}
                />
            </ModalContainer>
        </Col>
    )
}

export default DetailsTile