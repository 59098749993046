import * as Yup from "yup";

export const BannerValidationSchema = Yup.object().shape({
  image: Yup.string().required("Feild is required"),
  heading: Yup.string()
    .required("Feild is required")
    .max(100, "The heading must not exceed 100 characters"),
  sub_heading: Yup.string()
    .required("Feild is required")
    .max(100, "The sub-heading must not exceed 100 characters."),
  content: Yup.string()
    .required("Feild is required")
    .max(250, "The content must not exceed 255 characters."),
  button: Yup.string()
    .required("Feild is required")
    .max(100, "The button must not exceed 100 characters."),
});

export const AddBannerInitialValues = {
  image: "",
  heading: "",
  sub_heading: "",
  content: "",
  button: "",
};
