import { convertISODateToDateTime } from "../../../../../Utils/helpers/helpers";

export const doctorsData = [
  {
    img: "/images/doctor1.png",
    name: "Archana",
    department: "Ophthalmology",
  },
  {
    img: "/images/doctor1.png",
    name: "anjali",
    department: "Ophthalmology",
  },
  {
    img: "/images/doctor1.png",
    name: "Archana",
    department: "Ophthalmology",
  },
  {
    img: "/images/doctor1.png",
    name: "anjali",
    department: "Ophthalmology",
  },
  {
    img: "/images/doctor1.png",
    name: "Archana",
    department: "Ophthalmology",
  },
];

export const Appoinments = [
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
  {
    name: "Shawn Hampton",
    date: "10/Aug/2023",
    department: "Ophthalmology",
  },
];

export const appointmentsTableHeaders: string[] = [
  "Time",
  "Name",
  "Date of birth",
  "Mobile",
  "Department",
  "Doctor",
];

export const bookingsHeaderData: string[] = [
  "Date & Time",
  "Name",
  "Mobile",
  "Department",
  "Doctor"
];

export const appointmentsTableData = [
  {
    Time: "08:00 am",
    Name: "Austin",
    Age: 24,
    Mobile: "9846735265",
    Department: "Ophthalmology",
    Doctor: "Dr. Sabitha",
  },
  {
    Time: "08:00 am",
    Name: "Malavika",
    Age: 30,
    Mobile: "9447246365",
    Department: "Optomology",
    Doctor: "Dr. Sreya",
  },
  {
    Time: "08:15 am",
    Name: "Sharon",
    Age: 20,
    Mobile: "8086067845",
    Department: "General",
    Doctor: "Dr. Alex",
  },
  {
    Time: "08:30 am",
    Name: "Vidhya",
    Age: 48,
    Mobile: "7542365785",
    Department: "Ophthalmology",
    Doctor: "Dr. Sabitha",
  },
  {
    Time: "09:00 am",
    Name: "Stephen",
    Age: 55,
    Mobile: "8086453821",
    Department: "Optometry",
    Doctor: "Dr. Nisha",
  },
];

export const tableDataResture = (data: any) => {

  let convertedArray: any = [];

  data?.map((item: any) => {
    convertedArray?.push({
      Time: convertISODateToDateTime(item?.date_time),
      Id:item?.patient_id,
      Name: item?.patient_name,
      Age: item?.patient?.dob,
      Mobile: item?.patient_mobile,
      
    });
  });
  return convertedArray;
};

export const doctorappointmentsTableHeaders: string[] = [
  "Time",
  "ID",
  "Name",
  "Date Of Birth",
  "Mobile",
];

export const doctorupcomingappointmentsTableHeaders: string[] = [
  "Time",
  "Name",
  "Mobile",
  "Gender"
];
