export const handleDoctorSearch = (
  e: any,
  setSearch: Function,
  navigate: Function,
  viewType: string | undefined
) => {
  if (e?.target?.value?.length >= 1) {
    navigate(`/admin/doctors/${viewType}/1`);
    setSearch(e?.target?.value);
  } else {
    setSearch("");
  }
};

export const setCurrentPageAdminDoctor = (
  pageNo: any,
  navigate: Function,
  viewType: string
) => {
  navigate(`/admin/doctors/${viewType}/${pageNo}`);
};
