import { Link } from "react-router-dom";

const AdminProfile = () => {
  return (
    <>
      <h1>AdminProfile</h1>
    </>
  );
};

export default AdminProfile;
